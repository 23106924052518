import { Box } from "@chakra-ui/layout"
import { useRadio } from "@chakra-ui/radio"


const RadioOptions = (props: any) => {
    const { getInputProps, getCheckboxProps } = useRadio(props)
  
    const input = getInputProps()
    const checkbox = getCheckboxProps()
  
    return (
      <Box as='label' w='50%'>
        <input {...input} />
        <Box
          {...checkbox}
          textAlign='center'
          cursor='pointer'
          borderWidth='1px'
          borderRightRadius={props.children === 'Cell' ? 'md' : 'none'}
          borderLeftRadius={props.children === 'Admin' ? 'md' : 'none'}
          boxShadow='md'
          _checked={{
            bg: 'teal.600',
            color: 'white',
            borderColor: 'teal.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          py={1}
        >
          {props.children}
        </Box>
      </Box>
    )
  }

export default RadioOptions