import { Button, ButtonGroup, FormControl, FormErrorMessage, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import TextInput from "../TextInput"

interface IAddPartDesign {
    part: string
    handleAddDesign: (part: string, numPeople: string, cycleTime: number) => void
}

const AddPartDesign = ({ part, handleAddDesign }: IAddPartDesign) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<HTMLInputElement>(null)
    const [numPeople, setNumPeople] = useState<number>(0)
    const [cycleTime, setCycleTime] = useState<number>(0)

    const handleAdd = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleAddDesign(part, String(numPeople), cycleTime)
        setNumPeople(0)
        setCycleTime(0)
        onClose()
    }

    const handleClose = () => {
        setNumPeople(0)
        setCycleTime(0)
        onClose()
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
            // closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button
                        size='xs'
                        mt={1}
                        variant='outline'
                        borderColor='gray.400'
                        bg='white'
                    >
                        {`+ add design`}
                    </Button>
                    {/* <Box
                        cursor='pointer'
                        // onClick={() => console.log('add code')}
                        fontSize='xs'
                        textTransform='lowercase'
                        mt={1}
                    >
                        {`+ add design`}
                    </Box> */}
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    {/* Form */}
                    {/* <Form firstFieldRef={firstFieldRef} onCancel={onClose} /> */}
                    <form onSubmit={handleAdd} >
                        <Stack spacing={4}>
                            <FormControl isRequired isInvalid={!!numPeople && numPeople <= 0}>
                                <TextInput
                                    type='number'
                                    label={`Number of People`}
                                    id={`num-people`}
                                    ref={firstFieldRef}
                                    // placeholder="press jammed"
                                    value={numPeople || ''}
                                    onChange={(e: any) => setNumPeople(Number(e.currentTarget.value))}
                                // defaultValue=""
                                />
                                <FormErrorMessage textTransform='initial'>
                                    {'No. people must be greater than 0'}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl isRequired isInvalid={!!cycleTime && cycleTime <= 0}>
                                <TextInput
                                    type='number'
                                    label={`Cycle Time`}
                                    id={`cycle-time`}
                                    // placeholder="press jammed"
                                    value={cycleTime || ''}
                                    onChange={(e: any) => setCycleTime(Number(e.currentTarget.value))}
                                // defaultValue=""
                                />
                                <FormErrorMessage textTransform='initial'>
                                    {'Cycle time must be greater than 0'}
                                </FormErrorMessage>
                            </FormControl>
                            <ButtonGroup d="flex" justifyContent="flex-end">
                                <Button
                                    variant="outline"
                                    size='sm'
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    size='sm'
                                    colorScheme="blue"
                                    // onClick={handleAdd}
                                    disabled={cycleTime <= 0 || numPeople <= 0}
                                >
                                    Add
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </form>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default AddPartDesign