import { Box, Divider, Flex, Text, theme } from "@chakra-ui/react"
import { ResponsiveContainer, Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, LabelList } from "recharts"
// import { DefaultTooltipContent } from 'recharts/lib/component/DefaultTooltipContent' 
import { DayThroughputData, HourThroughputData } from "../../hooks/useCalcs"

interface IThroughputBar {
    title: string,
    h: string,
    w: string,
    data: Array<HourThroughputData | DayThroughputData>
}

const ThroughputBar = ({ title, h, w, data }: IThroughputBar) => {
    // console.log(data)

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const OE = payload[0].payload.plan && (payload[0].payload.actual / payload[0].payload.plan * 100).toFixed(1)
            const day = payload[0].payload.day
            return (
                <Box bg='white' p={4} borderRadius='md' border='1px' borderColor='gray.300' zIndex={50}>
                    <Text fontWeight='bold' mb={1}>{day ? day : label}</Text>
                    {/* <Text fontWeight='bold' mb={1}>{`${label} - ${(payload[0].payload.actual / payload[0].payload.plan * 100).toFixed(1)}%`}</Text> */}
                    <Divider my={1} />
                    <Text>{`OE : ${OE}%`}</Text>
                    <Text ml={2}>{`Plan : ${payload[0].payload.plan}`}</Text>
                    <Text color='green.600' ml={2}>{`Actual : ${payload[0].payload.actual}`}</Text>
                    <Divider my={1} />
                    <Text>{`Gap : ${payload[0].payload.gap}`}</Text>
                    <Text color='orange.600' ml={2}>{`Availability Loss : ${payload[0].payload.availPartsMissed}`}</Text>
                    <Text color='gray.500' ml={2}>{`Performance Loss : ${payload[0].payload.pacingPartsMissed}`}</Text>
                    <Text color='red.600' ml={2}>{`Quality Loss : ${payload[0].payload.rejects}`}</Text>
                    <Divider my={1} />
                    <Text>{`Available Time : ${payload[0].payload.availTime}`}</Text>
                    <Text ml={2} color='teal.600'>{`Change Time : ${payload[0].payload.changeTime}`}</Text>
                    <Text ml={2} color='orange.600'>{`Down Time : ${payload[0].payload.downTime}`}</Text>
                    <Text ml={2} >{`Run Time : ${payload[0].payload.runTime}`}</Text>
                    <Divider my={1} />
                    <Text>{`Stopped Time : ${payload[0].payload.stoppedTime}`}</Text>
                    <Text>{`Total Time : ${payload[0].payload.totalTime}`}</Text>
                </Box>
            )
        }

        return null;
    }

    // const CustomTooltip = (props: any) => {
    //     // payload[0] doesn't exist when tooltip isn't visible
    //     if (props.payload[0] != null) {
    //         // mutating props directly is against react's conventions
    //         // so we create a new payload with the name and value fields set to what we want
    //         const newPayload = [
    //             {
    //                 name: props.payload[0].payload.name,
    //                 // all your data which created the tooltip is located in the .payload property
    //                 Plan: props.payload[0].payload.Plan,
    //                 Gap: props.payload[0].payload.Gap,
    //                 Actual: props.payload[0].payload.Actual,
    //                 Rejects: props.payload[0].payload.Rejects,
    //                 DownTime: props.payload[0].payload.DownTime,
    //                 ChangeTime: props.payload[0].payload.ChangeTime,
    //                 StoppedTime: props.payload[0].payload.StoppedTime,
    //                 // you can also add "unit" here if you need it
    //             },
    //             ...props.payload,
    //         ];

    //         // we render the default, but with our overridden payload
    //         return <DefaultTooltipContent {...props} payload={newPayload} />;
    //     }

    //     // we just render the default
    //     return <DefaultTooltipContent {...props} />;
    // };

    return (
        <>
            <Flex flexDir='column' align='center' justifySelf='center' flexWrap='wrap'>
                <Text mb={2} fontSize='3.5vh'>
                    {title}
                </Text>
                <Box w={w} h={h}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data}
                            margin={{
                                top: 25,
                                right: 0,
                                left: 0,
                                bottom: 0
                            }}
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='xLabel' />
                            <YAxis allowDecimals={false} />
                            <Tooltip content={<CustomTooltip />} />
                            <Bar
                                stackId='a'
                                dataKey='actual'
                                fill={theme.colors.green[500]}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='actual'
                                    formatter={(value: number) => value !== 0 ? value : ''}
                                    fill='#F0FFF4'
                                    // content={props => {
                                    //     // console.log(props)
                                    //     const { x, y, width, height, value } = props
                                    //     // return <Text x={x} y={y}>{value}</Text>
                                    //     return value ?
                                    //         <g>
                                    //             <text x={Number(x) + (Number(width) / 2)} y={Number(y) - (Number(height) / 2)} z={500} textAnchor="middle" dominantBaseline="middle">{value}</text>
                                    //         </g>
                                    //         : <text />
                                    // }}
                                />
                            </Bar>
                            {/* <Bar
                                stackId='a'
                                dataKey='Gap'
                                fill={theme.colors.gray[300]}
                            >
                                <LabelList
                                    dataKey='Plan'
                                    position='top'
                                />
                            </Bar> */}
                            <Bar
                                stackId='a'
                                dataKey='dispQual'
                                fill={theme.colors.red[500]}
                            />
                            <Bar
                                stackId='a'
                                dataKey='dispPacing'
                                fill={theme.colors.gray[300]}
                            />
                            <Bar
                                stackId='a'
                                dataKey='dispAvail'
                                fill={theme.colors.orange[500]}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey='plan'
                                    position='top'
                                    formatter={(value: number) => value !== 0 ? value : ''}
                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Flex>
        </>
    )
}

export default ThroughputBar