import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { useState } from "react"
import { getLastTimeUnit, getStartOfTimeUnit, getEndOfTimeUnit } from "../../hooks/useCalcs"
import useShifts, { RelativeTimeQuery, ShiftsQuery, UnitQuery } from "../../hooks/useShifts"
import InsightsCharts from "../../components/admin/InsightsCharts"
import InsightsQuery from "../../components/admin/InsightsQuery"


const Insights = () => {
    const emptyQuery: ShiftsQuery = {
        cellId: '',
        cellName: '',
        relativeTime: '',
        unit: '',
        startTime: 0,
        endTime: 0,
    }
    const [query, setQuery] = useState<ShiftsQuery>(emptyQuery)

    const { shifts, loading } = useShifts(query)

    const hasQueried = (): boolean => {
        return query.cellId !== '' && query.startTime !== 0 && query.endTime !== 0
    }

    const setCellId = (cellId: string, cellName: string) => {
        setQuery(prevState => ({
            ...prevState,
            cellId: cellId,
            cellName: cellName,
            startTime: 0,
            endTime: 0,
        }))
    }

    const setTimeframe = (timeframe: string) => {
        const [relativeTime, unit] = timeframe.split(' ')
        setQuery(prevState => ({
            ...prevState,
            relativeTime: relativeTime as RelativeTimeQuery || '',
            unit: unit as UnitQuery || '',
            startTime: 0,
            endTime: 0,
        }))
    }

    const getInsights = () => {
        if (query.unit === '' || query.relativeTime === '') return
        const unit = query.unit
        const relativeTime = query.relativeTime

        const now = new Date().getTime()
        const date = relativeTime === 'last' ? getLastTimeUnit(now, unit) : now

        setQuery(prevState => ({
            ...prevState,
            startTime: getStartOfTimeUnit(date, unit, 0),
            endTime: getEndOfTimeUnit(date, unit, 0)
        }))
    }

    return (
        <Flex flex='1' overflowY='auto' h='100vh' flexDir='column' align='center' bg='gray.100'>
            <Box w='90%'>
                <Heading size='lg' my={8} mx={12} textAlign='center' textTransform='uppercase' letterSpacing='wider'>
                    Insights
                </Heading>
                <InsightsQuery 
                    query={query} 
                    setCellId={setCellId} 
                    setTimeframe={setTimeframe} 
                    getInsights={getInsights} 
                />
                {/* 
                    Have Outer part of Insights Charts here
                    Pass shifts as param (filter if necessary)
                    Pass filter function (for throughput onClick)
                 */}
                {hasQueried() && !loading &&
                    <>
                        {shifts.length !== 0 ?
                            // Shifts exist for the query
                            <InsightsCharts shifts={shifts} query={query} />
                            :
                            // No shifts exist for the query
                            <Text mt={8} textAlign='center'>
                                {`No shifts for ${query.cellName} ${query.relativeTime} ${query.unit}`}
                            </Text>
                        }
                    </>
                }
            </Box>
        </Flex>
    )
}

export default Insights
