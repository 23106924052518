import { Flex, Text } from "@chakra-ui/react"
import { useMediaQuery } from "@chakra-ui/react"

interface IHeaderData {
    label: string,
    data: string
}

const HeaderData = ({ label, data }: IHeaderData) => {
    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])
    let tagSize: string = 'xs'
    let textSize: string = 'md'
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        tagSize ='xs'
        textSize = 'md'
    }
    if (smH) {
        tagSize ='md'
        textSize = 'xl'
    }
    if (mdH) {
        tagSize ='lg'
        textSize = '2xl'
    }
    if (lgH) {
        tagSize ='2xl'
        textSize = '4xl'
    }
    if (xlH) {
        tagSize ='3xl'
        textSize = '5xl'
    }

    return (
        <Flex
                border='1px'
                borderColor='gray.200'
                boxShadow='base'
                rounded='md'
                h='min'
                // mx={1}
            >
                <Flex
                    px={2}
                    borderRight='1px'
                    borderColor='gray.200'
                    roundedLeft='md'
                    bg='gray.100'
                >
                    <Text
                        fontWeight='semibold'
                        alignSelf='center'
                        color='gray.600'
                        fontSize={tagSize}
                    >
                        {label}
                    </Text>
                </Flex>
                <Flex flex={1} px={2} align='center' roundedRight='md'>
                    <Text
                        fontSize={textSize}
                        p={1}
                    >
                        {
                            // MTVOE02H
                        }
                        {data}
                    </Text>
                </Flex>
            </Flex>
    )
}

export default HeaderData