import { Stack, Box } from "@chakra-ui/react"
import { ReactElement } from "react"
import HeadingCellDoc from "./HeadingCellDoc"

interface IContainerCellDoc {
    title: string,
    desc: string,
    children: ReactElement
}

const ContainerCellDoc = ({ title, desc, children }: IContainerCellDoc) => {
    return (
        <Stack spacing={6} w='full'>
            <HeadingCellDoc title={title} desc={desc} />
            <Box bg='white' borderRadius='lg' boxShadow='base' p={6} >
                {children}
            </Box>
        </Stack>
    )
}

export default ContainerCellDoc