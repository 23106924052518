import { GridItem, Flex } from "@chakra-ui/react"
import { useRef } from "react"
import { useSelector } from "react-redux"
import useConfigCell from "../../hooks/useConfigCell"
import useDownBorder from "../../hooks/useDownBorder"
import { RootState } from "../../redux/store"
import DispData from "./DispData"
import DRInfo from "./DRInfo"
import DRListModal from "./DRListModal"

const Quality = () => {
    const shiftRejects = useSelector((state: RootState) => state.timer.rejectedParts)
    const batchRejects = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.rejects)
    const hourRejects = useSelector((state: RootState) => state.timer.hourRecords.slice(-1)[0]?.rejects)
    const displayType = useSelector((state: RootState) => state.timer.displayType)
    const rejectRecords = useSelector((state: RootState) => state.timer.rejectRecords)
    const lastReject = rejectRecords.slice(-1)[0]

    // console.log(rejectRecords)

    const borderColor = useRef<string>('gray.200')
    const border = useRef<string>('1px')
    const bg = useRef<string>('white')
    useDownBorder({ borderColor, border, bg })

    const partsDisplay = {
        shift: shiftRejects,
        batch: batchRejects,
        hour: hourRejects
    }

    // const { loading, cellDoc, error } = useCellDoc('cell')
    // const { cellDoc } = useCellDoc('cell')
    const { configCell } = useConfigCell('cell')

    return (
        <>
            <GridItem overflow='hidden' display='flex' justifyContent='center' rounded='3xl' boxShadow='base' bg={bg.current} border={border.current} borderColor={borderColor.current}>
                <Flex
                    h='full'
                    flexDir='column'
                    align='center'
                    justifyContent='space-evenly'
                >
                    {/* <Flex flexDir='column' alignItems='center'>
                        <Heading size='3xl'>
                            {rejectedParts}
                        </Heading>
                        <Text fontSize='xl' textColor='gray.600'>
                            rejects
                        </Text>
                    </Flex> */}
                    <DispData 
                        data={String(partsDisplay[displayType])} 
                        label={'rejects'} 
                        button={
                            <DRListModal 
                                type='reject'
                                records={rejectRecords}
                                codes={configCell.reject_codes.map(r => r.code) || []}
                            />
                        }
                    />
                    {lastReject ? 
                        <DRInfo 
                            type='reject'
                            record={lastReject} 
                            index={rejectRecords.length - 1} 
                            selectCodes={configCell.reject_codes.map(r => r.code) || []}
                        />
                        // <RInfo 
                        //     record={lastReject} 
                        //     index={rejectRecords.length - 1} 
                        //     rejectCodes={cellDoc.reject_codes} 
                        // />
                        :
                        <DRInfo 
                            type='reject'
                            record='dummy' 
                            index={0} 
                            selectCodes={[]}
                        />
                        // <></>
                    }
                    {/* <Flex flexDir='column'>
                        <Flex>
                            <Text>
                                R1 -
                            </Text>
                            <Flex flex='1' ml='2'>
                                <Select
                                    placeholder='Select code'
                                    size='xs'
                                // onChange={e => dispatch(setDownCode({
                                //     index: index,
                                //     code: e.currentTarget.value
                                // }))}
                                >
                                    <option >test</option>
                                    
                                </Select>
                            </Flex>
                        </Flex>
                        <DTNote />
                    </Flex> */}
                </Flex>
            </GridItem>
        </>
    )
}

export default Quality