import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { firestore } from "../utils/firebase"

interface Data {
    error: Error | null;
    loading: boolean;
    cellDoc: CellDoc;
}

export interface CellDoc {
    name: string,
    part_map: PartMap,
    reject_codes: Array<string>,
    down_codes: Array<string>,
    shift_names: Array<string>,
    current_shift: string,  // documentId of current shift
    last_shift_notes: string,
    setup_check_map: ChecklistMap,
    cleanup_check_map: ChecklistMap,
}

export interface WorkOrder {  // TODO: for cell or overall? - other data?
    workOrderNum: string
    goalParts: number
    cells: Array<string>
    parts: Array<string>
}

export interface Part {  // TODO: Not used yet
    setupTime: number
    setupCheck: ChecklistMap
    cleanupTime: number
    cleanupCheck: ChecklistMap
    designData: PartData
    // form? first pass yeild?
}
export interface PartMap {
    [partName: string]: PartData
}

export interface PartData {
    default: number  // #ppl
    [numPeople: string]: number  // #ppl -> cycle time
    // setupChecklist: ChecklistMap
    // cleanupChecklist: ChecklistMap
    // expSetupSeconds: number
    // expCleanupSeconds: number
}

export interface ChecklistMap {
    [index: string]: string  // idx -> task
}

export const convertChecklistMapToList = (checkMap: ChecklistMap): Array<string> => {
    const finalList: Array<string> = []
    Object.keys(checkMap).map(key => 
        finalList[Number(key)] = checkMap[key]
    )
    return finalList
}

export const convertChecklistToMap = (checklist: Array<string>): ChecklistMap => {
    const newChecklistMap: ChecklistMap = {}
    checklist.forEach((value, i) => {
        newChecklistMap[i] = value
    })
    return newChecklistMap
}

const initData: Data = {
    error: null,
    loading: true,
    cellDoc: {
        name: '',
        part_map: {},
        reject_codes: [],
        down_codes: [],
        shift_names: [],
        current_shift: '',
        last_shift_notes: '',
        setup_check_map: {},
        cleanup_check_map: {}
    }
}

const useCellDoc = (type: string, cellUID?: string) => {
    const [data, setData] = useState<Data>(initData)
    const currentUser = useSelector((state: RootState) => state.user.currentUser)
    
    let adminId: string
    let cellId: string
    if (currentUser) {
        if (type === 'admin') {
            adminId = `${currentUser?.id}`
            if (cellUID) {
                cellId = cellUID
            }
        } else if (type === 'cell') {
            adminId = `${currentUser?.adminUID}`
            cellId = `${currentUser?.id}`
        }
    }

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = firestore
                .collection(`users/${adminId}/cells`)
                .doc(cellId)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        const { name,
                            model_map,
                            reject_codes,
                            down_codes,
                            shift_names,
                            current_shift,
                            last_shift_notes,
                            setup_check_map,
                            cleanup_check_map,
                        }: any = doc.data()
                        
                        setData({
                            error: null,
                            loading: false,
                            cellDoc: {
                                name: name || '',
                                part_map: model_map || {},
                                reject_codes: reject_codes ? reject_codes.sort() : [],
                                down_codes: down_codes ? down_codes.sort() : [],
                                shift_names: shift_names || [],
                                current_shift: current_shift || '',
                                last_shift_notes: last_shift_notes || '',
                                setup_check_map: setup_check_map || {},
                                cleanup_check_map: cleanup_check_map || {},
                            }
                        })
                    }
                    // var source = doc.metadata.fromCache ? "local cache" : "server";
                    // console.log("Data came from " + source);
                },
                    (error) => {
                        setData({
                            error: error,
                            loading: false,
                            cellDoc: {
                                name: '',
                                part_map: {},
                                reject_codes: [],
                                down_codes: [],
                                shift_names: [],
                                current_shift: '',
                                last_shift_notes: '',
                                setup_check_map: {},
                                cleanup_check_map: {}
                            }
                        })
                    })

            return unsubscribe
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data
}

export default useCellDoc