import { useMediaQuery } from "@chakra-ui/react"
import { Table, Thead, Tr, Th, Tbody, Td, Tfoot } from "@chakra-ui/table"
import { OLEData } from "../../hooks/useCalcs"

interface IOleDataTable {
    data: Array<OLEData>
}

const QPATable = ({ data }: IOleDataTable) => {
    const firstEntry = data[0]
    const otherEntries = data.slice(1)

    const textColors: { [key: string]: string } = {
        'Good': 'green.600',
        'Rejects': 'red.600',
        'Down Time': 'orange.600',
        'Change Time': 'teal.600',
    }

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let textSize: string = 'sm'
    let thSize: string = 'xs'
    let spacing: string = 'xs'
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        textSize = 'sm'
        thSize = 'xs'
        spacing = 'xs'
    }
    if (smH) {  // 480px
        textSize = 'md'
        thSize = 'sm'
        spacing = 'sm'
    }
    if (mdH) {  // 768px
        textSize = 'lg'
        thSize = 'md'
        spacing = 'md'
    }
    if (lgH) {  // 992px
        textSize = 'xl'
        thSize = 'lg'
        spacing = 'md'
    }
    if (xlH) {  // 1280px
        textSize = '2xl'
        thSize = 'xl'
        spacing = 'lg'
    }

    return (
        <>
            <Table variant='simple' size={spacing}>
                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                <Thead>
                    <Tr>
                        <Th fontSize={thSize}>{firstEntry.name}</Th>
                        {firstEntry.min &&
                            <>
                            <Th isNumeric fontSize={thSize}>
                                {firstEntry.min}
                            </Th>
                            {/* <Td textColor='gray.500' px={1} py={0}>|</Td> */}
                            </>
                        }
                        <Th isNumeric fontSize={thSize}>
                            {firstEntry.parts}
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {otherEntries.map(entry => (
                        <Tr key={entry.name} textColor={textColors[entry.name]}>
                            <Td fontSize={textSize}>{entry.name}</Td>
                            {entry.min &&
                                <>
                                <Td isNumeric fontSize={textSize}>{entry.min}</Td>
                                {/* <Td textColor='gray.500' px={1}>|</Td> */}
                                </>
                            }
                            <Td isNumeric fontSize={textSize}>{entry.parts}</Td>
                        </Tr>
                    ))}

                    {/* <Tr>
                        <Td>{performData[2].name}</Td>
                        <Td isNumeric>{performData[2].value}</Td>
                    </Tr> */}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th></Th>
                        {firstEntry.min &&
                            <>
                            <Th isNumeric fontSize={thSize}>min</Th>
                            {/* <Td textColor='gray.500' px={1} py={0}>|</Td> */}
                            </>
                        }
                        <Th isNumeric fontSize={thSize}>parts</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </>
    )
}

export default QPATable