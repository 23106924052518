import { Flex, Text, Box } from "@chakra-ui/react"

interface IChecklist {
    checklist: Array<string>,
    // updateChecklist: (data: React.SetStateAction<ChecklistData>) => void
}

const Checklist = ({ checklist }: IChecklist) => {

    return (
        <>
            {checklist.map((taskName, idx) => {
                return (
                    <Flex key={idx} mb={6} px={4} align='center'>
                        <Box
                            as='input'
                            type='checkbox'
                            sx={{
                                transform: 'scale(2)',
                                border: '1px',
                                borderColor: 'gray.300',
                                borderRadius: 'base',
                                _disabled: {
                                    borderColor: 'gray.300',
                                    bg: 'gray.200',
                                }
                            }}
                        />
                        <Text ml={6}>
                            {taskName}
                        </Text>
                    </Flex>
                )
            })}
        </>
    )
}

export default Checklist