import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal"
import { Button, Flex, Heading, VStack, Select, FormControl, FormLabel, Input, useMediaQuery, ButtonGroup, NumberInput, NumberInputField } from "@chakra-ui/react"
import _ from "lodash"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { convertChecklistMapToList } from "../../hooks/useCellDoc"
import useConfigCell from "../../hooks/useConfigCell"
import useCurrentTime from "../../hooks/useCurrentTime"
import { RootState } from "../../redux/store"
import { endSetup, setShiftNotes } from "../../redux/timerSlice"
import { formatDateTime } from "../../utils"
import Checklist from "./Checklist"
import LastNotesModal from "./LastNotesModal"
import NoteModal from "./NoteModal"

interface ISetupModal {
    isOpen: boolean,
    onClose: () => void,
}

const SetupModal = ({ isOpen, onClose }: ISetupModal) => {
    const dispatch = useDispatch()

    const shiftNotes = useSelector((state: RootState) => state.timer.shiftNotes)
    
    // const setupChecklist = useSelector((state: RootState) => state.timer.setupChecklist)
    // const [newSetupChecklist, setNewSetupChecklist] = useState<ChecklistData>(setupChecklist)
    
    const [workOrder, setWorkOrder] = useState<string>('')
    const [goalParts, setGoalParts] = useState<string>('')
    const [nextPart, setNextPart] = useState<string>('')
    const [numPeople, setNumPeople] = useState<number>(0)

    // const { loading, cellDoc, error } = useCellDoc('cell')
    const { configCell } = useConfigCell('cell')
    // const { cellDoc } = useCellDoc('cell')

    const currentTime = formatDateTime(useCurrentTime())

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let selectSize: string = 'xs'
    let timeSize: string = '3xl'
    let headingSize: string = 'xl'
    let hwProps: { h?: string, w?: string} = {}
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        selectSize = 'xs'
        timeSize = '3xl'
        headingSize = 'xl'
        hwProps = {}
    }
    if (smH) {  // 480px
        selectSize = 'sm'
        timeSize = '3xl'
        headingSize = '2xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (mdH) {  // 768px
        selectSize = 'md'
        timeSize = '4xl'
        headingSize = '3xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (lgH) {  // 992px
        selectSize = 'lg'
        timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (xlH) {  // 1280px
        selectSize = 'lg'
        timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }

    const clear = () => {
        setWorkOrder('')
        setGoalParts('')
        setNextPart('')
        setNumPeople(0)
    }

    const changeShiftNotes = (note: string) => {
        dispatch(setShiftNotes({
            note: note
        }))
    }

    // // limit goalParts >= 1
    // if (goalParts !== undefined && Number(goalParts) <= 0) {
    //     setGoalParts(undefined) // goalParts empty
    // }

    return (
        <>
            {
                // MODAL
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size='full'
            >
                <ModalOverlay />
                <ModalContent overflowY='auto'>
                    {/* <ModalHeader mx='auto'>
                        Changeover
                    </ModalHeader> */}
                    <ModalBody>
                        {/* <Flex flexDir='column' h='full' justifyContent='space-evenly'> */}
                        <Flex justifyContent='space-between'>
                            <ButtonGroup spacing={2} alignSelf='center'>
                                <LastNotesModal />
                                <NoteModal size='lg' title={'Shift Notes'} value={shiftNotes} update={changeShiftNotes}/>
                            </ButtonGroup>
                            <Heading size={timeSize} justifySelf='center'>
                                {currentTime}
                            </Heading>
                            {/* <Heading size={timeSize} alignSelf='center'>
                                {formatSeconds(changeTime)}
                            </Heading> */}
                        </Flex>
                        <VStack w={4 / 5} minH='80vh' mx='auto' justifyContent='space-around'>
                            <Heading size={headingSize} alignSelf='center'>
                                Setup
                            </Heading>
                            {/* <Heading size='3xl' alignSelf='center'>
                                {formatSeconds(changeTime)}
                            </Heading> */}
                            <Flex w='full' justify='space-between'>
                                <FormControl id='work-order-number' isRequired w='49%'>
                                    <FormLabel>Work Order #</FormLabel>
                                    <Input
                                        autoFocus
                                        size={selectSize}
                                        placeholder='Work order #'
                                        value={workOrder}
                                        onChange={e => setWorkOrder(e.currentTarget.value)}
                                    />
                                </FormControl>
                                <FormControl id='goal-num-parts' isRequired w='49%'>
                                    <FormLabel>Goal No. Parts</FormLabel>
                                        <NumberInput
                                            min={0}
                                            size={selectSize}
                                        >
                                            <NumberInputField
                                                placeholder={'Goal No. Parts'}
                                                value={goalParts || ''}
                                                onKeyDown={e => 
                                                    (e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') 
                                                    && e.preventDefault()
                                                }
                                                onChange={e => setGoalParts(e.currentTarget.value)}
                                            />
                                        </NumberInput>
                                    {/* <Input
                                        autoFocus
                                        onKeyDown={e => {
                                            if (['a', 'b', 'c', 'd', 'r', 'e', '+', '-', '.'].includes(e.key)) {
                                                e.preventDefault()
                                            }
                                        }}
                                        size={selectSize}
                                        placeholder='Goal Parts'
                                        onChange={e => e.currentTarget.validity.valid ? setGoalParts(Number(e.currentTarget.value)) : null}
                                        type="number"
                                        pattern="[0-9]"
                                        value={goalParts !== undefined ? String(goalParts) : ""}
                                    /> */}
                                </FormControl>
                            </Flex>
                            <FormControl id='next-part' isRequired>
                                <FormLabel>Next Part</FormLabel>
                                <Select
                                    isRequired
                                    // onKeyDown={e => {
                                    //     if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    placeholder='Select Part'
                                    size={selectSize}
                                    onChange={e => {
                                        setNextPart(e.currentTarget.value)
                                        setNumPeople(configCell.parts.find(p => p.partNum === e.currentTarget.value)?.designData['default'] || 0)
                                    }}
                                >
                                    {configCell.parts.sort().map((part, index) => {
                                        return <option value={part.partNum} key={index}>{`${part.alias} - ${part.partNum}`}</option>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl id='no-people' isRequired>
                                <FormLabel>No. People</FormLabel>
                                <Select
                                    isRequired
                                    // onKeyDown={e => {
                                    //     if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    size={selectSize}
                                    value={numPeople}
                                    placeholder='Select No. People'
                                    onChange={e => setNumPeople(Number(e.currentTarget.value))}
                                    isDisabled={!nextPart}
                                >
                                    {nextPart && Object.keys(configCell.parts.find(p => p.partNum === nextPart)?.designData || {}).filter((key) => key !== 'default').map((ppl, index) => {
                                        return <option value={ppl} key={index}>{ppl}</option>
                                    })}
                                </Select>
                            </FormControl>
                            {!_.isEmpty(configCell.setup_check_map) &&
                                <Flex flexDir='column' minW='15vw'>
                                    <Checklist checklist={convertChecklistMapToList(configCell.setup_check_map)} />
                                </Flex>
                            }
                            {/* {!_.isEmpty(newSetupChecklist) &&
                                <Flex flexDir='column' minW='15vw'>
                                    <Checklist checklist={newSetupChecklist} updateChecklist={setNewSetupChecklist} />
                                </Flex>
                            } */}
                        </VStack>
                        {/* </Flex> */}
                        <Flex w='full' justify='center'>
                            <Button
                                colorScheme='teal'
                                onClick={() => {
                                    dispatch(endSetup({
                                        workOrder,
                                        goalParts,
                                        nextPart,
                                        numPeople,
                                        cycleTime: configCell.parts.find(p => p.partNum === nextPart)?.designData[numPeople] || 0,
                                        // newSetupChecklist,
                                    }))
                                    clear()
                                    onClose()
                                }}
                                alignSelf='center'
                                {...hwProps}
                                // h='10vh'
                                // w={1/4}
                                size='lg'
                                isDisabled={!workOrder || !nextPart || !numPeople || !goalParts || !Number.isInteger(Number(goalParts)) || Number(goalParts) < 0}
                            >
                                Start Production
                            </Button>
                        </Flex>
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button
                            colorScheme='teal'
                            onClick={() => {
                                dispatch(endSetup({
                                    workOrder,
                                    goalParts,
                                    nextPart,
                                    numPeople,
                                    cycleTime: cellDoc.Part_map[nextPart][numPeople],
                                    newSetupChecklist,
                                }))
                                clear()
                                onClose()
                            }}
                            mx='auto'
                            {...hwProps}
                            // h='10vh'
                            // w={1/4}
                            size='lg'
                            isDisabled={!workOrder || !nextPart || !numPeople || !goalParts || !Number.isInteger(Number(goalParts)) || Number(goalParts) < 0}
                        >
                            Start Production
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

export default SetupModal