import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { firestore } from "../utils/firebase"

interface Data {
    error: Error | null;
    loading: boolean;
    cellListDoc: CellListDoc;
}

export interface CellIdName {
    id: string
    name: string
}
  
export interface CellListDoc {
    all_cells: Array<CellIdName>
    active_users: Array<string>
}

const initData: Data = {
    error: null,
    loading: true,
    cellListDoc: {
        all_cells: [],
        active_users: []
    }
}

const useCellListDoc = () => {
    const [ data, setData ] = useState<Data>(initData)
    const { currentUser } = useSelector((state: RootState) => state.user)
    // OR pass currentUserId as prop

    // const fetch = async () => {
    //     await firestore
    //     .collection(`users/${currentUser?.id}/cells`)
    //     .doc('cell_list')
    //     .onSnapshot(doc => {
    //         const { all_cells, active_users }: any = doc.data()
    //         setData({
    //             error: null,
    //             loading: false,
    //             cellListDoc: {
    //                 all_cells: all_cells,
    //                 active_users: active_users
    //             }
    //         })
    //     },
    //     (error) => {
    //         setData({
    //             error: error,
    //             loading: false,
    //             cellListDoc: {
    //                 all_cells: [],
    //                 active_users: []
    //             }
    //         })
    //     })
    // }

    useEffect(() => {
        // if (currentUser) {
            const unsubscribe = firestore
                .collection(`users/${currentUser?.id}/cells`)
                .doc('cell_list')
                .onSnapshot(doc => {
                    const { all_cells, active_users }: any = doc.data()
                    setData({
                        error: null,
                        loading: false,
                        cellListDoc: {
                            all_cells: all_cells,
                            active_users: active_users
                        }
                    })

                    // var source = doc.metadata.fromCache ? "local cache" : "server";
                    // console.log("Data came from " + source);
                },
                (error) => {
                    setData({
                        error: error,
                        loading: false,
                        cellListDoc: {
                            all_cells: [],
                            active_users: []
                        }
                    })
                })

            return unsubscribe
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data
}

export default useCellListDoc