import { Stack, Heading, Text } from "@chakra-ui/react"

interface IHeadingCellDoc {
    title: string,
    desc: string
}

const HeadingCellDoc = ({ title, desc }: IHeadingCellDoc) => {
    return(
        <Stack>
            <Heading size='md'>{title}</Heading>
            <Text mt={1} textColor='gray.600'>{desc}</Text>
        </Stack>
    )
}

export default HeadingCellDoc