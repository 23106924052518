import { Redirect, Route, Switch } from "react-router"
import AdminRegister from "../../pages/admin/AdminRegister"
import ManageCells from "../../pages/admin/ManageCells"
import Subscription from "../../pages/admin/Subscription"
import Summary from "../../pages/admin/Summary"
import NotFound from "../../pages/NotFound"
import TimerMain from "../../pages/timer/TimerMain"
import ForgotPass from "../../pages/website/ForgotPass"
import Register from "../../pages/website/Register"
import Signin from "../../pages/website/Signin"
import StartShift from "../../pages/timer/StartShift"
import AdminPrivateRoute from "./AdminPrivateRoute"
import PrivateRoute from "./PrivateRoute"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import TimerMainRoute from "./TimerMainRoute"
import ConfigCell from "../../pages/admin/ConfigCell"
import Insights from "../../pages/admin/Insights"

const Routing = ({ currentUser, token }: any) => {
  const shiftStartTime = useSelector((state: RootState) => state.timer.shiftStartTime)
  // const [ isSub, setIsSub ] = useState<boolean>(false)
  // const [ isLoading, setIsLoading ] = useState<boolean>(false)
  // let location = useLocation()

  // const getSub = async () => {
  //   setIsLoading(true)
  //   let res = await getIsSub(currentUser?.id ? currentUser.id : '')
  //   setIsSub(res)
  //   setIsLoading(false)
  // }

  // useEffect(() => {
  //   if (!!!token?.claims.stripeRole && location.pathname === '/subscripton') {
  //     getSub()
  //     console.log(isSub)
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.pathname])

  // if (isLoading) {
  //   return <Loading />
  // }

  return (
    <Switch>
      {/* ADMIN */}
      <PrivateRoute
        exact
        path='/subscription'
        component={Subscription}
        // access={!!token?.claims.admin && !isSub}
        access={!!token?.claims.admin}
        message={'You must be an unsubscribed admin to visit this page'}
        displayButton={true}
      />
      {/* <AdminPrivateRoute
          exact 
          path='/subscription' 
          component={Subscription} 
          access={!!token?.claims.admin} 
          isSub={true}
          toAccess={'admin'}
        />
        <AdminPrivateRoute
          exact 
          path='/manage-cells' 
          component={ManageCells} 
          access={!!token?.claims.admin} 
          isSub={!!currentUser?.subscriptions.length}
          toAccess={'admin'}
        />
        <AdminPrivateRoute 
            exact 
            path='/admin-register' 
            component={AdminRegister} 
            access={!!token?.claims.admin} 
            isSub={!!currentUser?.subscriptions.length}
            toAccess={'admin'}
        /> */}
      <AdminPrivateRoute
        exact
        path='/admin-register'
        component={AdminRegister}
        access={!!token?.claims.admin}
        // isSub={!!token?.claims.stripeRole || isSub}
        isSub={!!token?.claims.stripeRole}
        // isSub={!!activeSubDoc.id}
        message={'You must be an admin to visit this page'}
        displayButton={true}
      />
      <AdminPrivateRoute
        exact
        path='/insights'
        component={Insights}
        access={!!token?.claims.admin}
        // isSub={!!token?.claims.stripeRole || isSub}
        isSub={!!token?.claims.stripeRole}
        // isSub={!!activeSubDoc.id}
        message={'You must be an admin to visit this page'}
        displayButton={true}
      />
      {/* <PrivateRoute 
            exact 
            path='/admin-register' 
            component={AdminRegister} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
      <AdminPrivateRoute
        // exact 
        path='/manage-cells'
        component={ManageCells}
        access={!!token?.claims.admin}
        // isSub={!!token?.claims.stripeRole || isSub}
        isSub={!!token?.claims.stripeRole}
        // isSub={!!activeSubDoc.id}
        message={'You must be an admin to visit this page'}
        displayButton={true}
      />
      <AdminPrivateRoute
        // exact 
        path='/settings'
        component={ConfigCell}
        access={!!token?.claims.admin}
        // isSub={!!token?.claims.stripeRole || isSub}
        isSub={!!token?.claims.stripeRole}
        // isSub={!!activeSubDoc.id}
        message={'You must be an admin to visit this page'}
        displayButton={true}
      />
      {/* <PrivateRoute 
            exact 
            path='/manage-cells' 
            component={ManageCells} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
      {/* <Route exact path='/summary' render={() => <Summary />} /> */}
      <AdminPrivateRoute
        exact
        path='/summary'
        component={Summary}
        access={!!token?.claims.admin}
        // isSub={!!token?.claims.stripeRole || isSub}
        isSub={!!token?.claims.stripeRole}
        // isSub={!!activeSubDoc.id}
        message={'You must be an admin to visit this page'}
        displayButton={true}
      />
      {/* <PrivateRoute 
            exact 
            path='/summary' 
            component={Summary} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
      {/* CELL */}
      <PrivateRoute
        exact
        path='/timer'
        component={StartShift}
        // adminId={currentUser.adminUID}
        // cellId={currentUser.id}
        // render={() => <TaktTimer userId={currentUser.id}/>}
        access={!!token?.claims.cell}
        // access={!!token?.claims.cell && !!token?.claims.status}
        message={'You must be an active cell to use the timer'}
        displayButton={false}
      />
      <TimerMainRoute
        exact
        path='/timer-main'
        component={TimerMain}
        // adminId={currentUser.adminUID}
        // cellId={currentUser.id}
        // render={() => <TaktTimer userId={currentUser.id}/>}
        access={!!token?.claims.cell}
        // access={!!token?.claims.cell && !!token?.claims.status}
        isStarted={!!shiftStartTime}
        message={'You must be an active cell and start a shift to use the timer'}
        displayButton={false}
      />
      {/* <Route
        exact
        path='/test'
        render={() => <TestUI />}
      /> */}
      {/* NO USER */}
      <Route
        exact
        path='/'
        render={() => !currentUser ? (
          // <Home />
          <Signin />
        ) : (
          token?.claims.cell ? (
            <Redirect to='/timer' />
          ) : (
            token?.claims.admin ? (
              <Redirect to='/manage-cells' />
            ) : (
              <Redirect to='/summary' /> // Manager
            )
          )
        )
        }
      />
      <Route
        exact
        path='/register'
        render={() => currentUser ? (
          <Redirect to='/' />
        ) : (
          <Register />
        )
        }
      />
      {/* <Route
        exact
        path='/plans'
        render={() => currentUser ? (
          <Redirect to='/' />
        ) : (
          <Plans />
        )
        }
      /> */}
      <Route
        exact
        path='/signin'
        render={() => currentUser ? (
          <Redirect to='/' />
        ) : (
          <Signin />
        )
        }
      />
      <Route
        exact
        path='/forgot-password'
        render={() => currentUser ? (
          <Redirect to='/' />
        ) : (
          <ForgotPass />
        )
        }
      />
      <Route path="/404" component={NotFound} />
      <Redirect to="/404" />
    </Switch>
  )
}

export default Routing