import { Flex, Button, ButtonGroup, FormControl, FormErrorMessage, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import TextInput from "../TextInput"

interface IAddPart {
    handleAddPart: (part: string, alias: string, numPeople: string, cycleTime: number) => void
}

const AddPart = ({ handleAddPart }: IAddPart) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<HTMLInputElement>(null)
    const [numPeople, setNumPeople] = useState<number>(0)
    const [cycleTime, setCycleTime] = useState<number>(0)
    const [partName, setPartName] = useState<string>('')
    const [alias, setAlias] = useState<string>('')

    const handleAdd = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleAddPart(partName, alias, String(numPeople), cycleTime)
        clearForm()
        onClose()
    }

    const handleClose = () => {
        clearForm()
        onClose()
    }

    const clearForm = () => {
        setPartName('')
        setAlias('')
        setNumPeople(0)
        setCycleTime(0)
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
            // closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button
                        cursor='pointer'
                        size='sm'
                        variant='outline'
                        borderColor='gray.400'
                    >
                        {`+ add part`}
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    {/* Form */}
                    {/* <Form firstFieldRef={firstFieldRef} onCancel={onClose} /> */}
                    <form onSubmit={handleAdd}>
                        <Stack spacing={4}>
                            <FormControl isRequired isInvalid={!!partName} mr={4}>
                                <TextInput
                                    label={`Part Number`}
                                    id={`Part-num`}
                                    ref={firstFieldRef}
                                    // placeholder="press jammed"
                                    value={partName}
                                    onChange={(e: any) => setPartName(e.currentTarget.value)}
                                // defaultValue=""
                                />
                                {/* <FormErrorMessage>
                                {''}
                            </FormErrorMessage> */}
                            </FormControl>
                            <FormControl isRequired isInvalid={!!alias}>
                                <TextInput
                                    label={`Alias`}
                                    id={`alias`}
                                    value={alias}
                                    onChange={(e: any) => setAlias(e.currentTarget.value)}
                                />
                                {/* <FormErrorMessage>
                                {''}
                            </FormErrorMessage> */}
                            </FormControl>
                            <Flex>
                                <FormControl isRequired isInvalid={!!numPeople && numPeople <= 0} mr={4}>
                                    <TextInput
                                        type='number'
                                        label={`Num People`}
                                        id={`num-people`}
                                        // placeholder="press jammed"
                                        value={numPeople || ''}
                                        onChange={(e: any) => setNumPeople(Number(e.currentTarget.value))}
                                    // defaultValue=""
                                    />
                                    <FormErrorMessage>
                                        {'Number of People must be greater than 0'}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isRequired isInvalid={!!cycleTime && cycleTime <= 0}>
                                    <TextInput
                                        type='number'
                                        label={`Cycle Time`}
                                        id={`cycle-time`}
                                        // placeholder="press jammed"
                                        value={cycleTime || ''}
                                        onChange={(e: any) => setCycleTime(Number(e.currentTarget.value))}
                                    // defaultValue=""
                                    />
                                    <FormErrorMessage>
                                        {'Cycle Time must be greater than 0'}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <ButtonGroup d="flex" justifyContent="flex-end">
                                <Button
                                    variant="outline"
                                    size='sm'
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    size='sm'
                                    colorScheme="blue"
                                    // onClick={handleAdd}
                                    disabled={!!!partName || !!!alias || cycleTime <= 0 || numPeople <= 0}
                                >
                                    Add
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </form>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default AddPart