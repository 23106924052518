import { useParams } from "react-router"
import Loading from "../../components/Loading"
import useCellDoc, { convertChecklistMapToList, convertChecklistToMap, PartMap } from "../../hooks/useCellDoc"
import { Flex, Heading, Stack, Text, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import SaveChange from "../../components/admin/SaveChange"
import { useSelector } from "react-redux"
import { firestore, functions } from "../../utils/firebase"
import { RootState } from "../../redux/store"
import ContainerCellDoc from "../../components/admin/ContainerCellDoc"
import DeleteCell from "../../components/admin/DeleteCell"
import ChangeCellPass from "../../components/admin/ChangeCellPass"
import { useHistory } from "react-router-dom"
import _ from "lodash"

const CellDoc = () => {
    const adminUID = useSelector((state: RootState) => state.user.currentUser?.id);
    const { cellId } = useParams<{ cellId: string }>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    let history = useHistory()

    const { error: cdError, loading: cdLoading, cellDoc } = useCellDoc('admin', cellId)
    // const { cellDoc } = useCellDoc(cellId)
    // useEffect(() => console.log(cellDoc, loading, error), [cellDoc, error, loading])

    // const [newDocData, setNewDocData] = useState<CellDoc>(cellDoc)

    const toast = useToast()

    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [newShifts, setNewShifts] = useState<Array<string>>([])
    const [newDowns, setNewDowns] = useState<Array<string>>([])
    const [newRejects, setNewRejects] = useState<Array<string>>([])
    const [newSetupList, setNewSetupList] = useState<Array<string>>([])
    const [newCleanupList, setNewCleanupList] = useState<Array<string>>([])
    const [newPartMap, setNewPartMap] = useState<PartMap>({})
    
    // SAVE DOC
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const saveDoc = async () => {
        const cellDocRef = firestore
                           .collection(`users/${adminUID}/cells`)
                           .doc(cellId)
        setIsSaving(true)
        try {
            await cellDocRef.set({
                shift_names: newShifts,
                down_codes: newDowns,
                reject_codes: newRejects,
                model_map: newPartMap,
                setup_check_map: convertChecklistToMap(newSetupList),
                cleanup_check_map: convertChecklistToMap(newCleanupList),
            }, { merge: true })
            setIsSaving(false)
            setIsChanged(false)
            toast({
                title: "Success! Cell settings have been saved",
                status: "success",
                duration: 5000,
                isClosable: true
            })
        } catch (error) {
            toast({
                title: "Oops, something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            setIsSaving(false)
        }
    }

    // DELETE CELL
    const deleteCell = async () => {
        setIsLoading(true)
        try {
            const deleteCell = functions.httpsCallable('deleteCell');
            await deleteCell({
                cellId: cellId, 
                cellName: cellDoc.name,
                adminUID: adminUID
            })
            setIsLoading(false)
            history.push('/manage-cells')
            // toast?
        } catch (err) {
            toast({
                title: "Oops, something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            setIsLoading(false)
            // setIsLoading(false)
            // console.log(err)
        }
    }

    const initFields = () => {
        setIsChanged(false)
        setNewShifts(cellDoc.shift_names)
        setNewDowns(cellDoc.down_codes)
        setNewRejects(cellDoc.reject_codes)
        setNewPartMap(_.cloneDeep(cellDoc.part_map))
        setNewSetupList(convertChecklistMapToList(cellDoc.setup_check_map))
        setNewCleanupList(convertChecklistMapToList(cellDoc.cleanup_check_map))
    }

    useEffect(() => {
        initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellDoc])

    if (isLoading || cdLoading) {
        return <Loading />
    }
    
    // TODO: create error handler
    if (cdError) {
        // insert warning
    }

    return (
        <>
            <Flex flex='1' overflow='auto' h='100vh' flexDir='column'>
                {isChanged ? 
                    <SaveChange 
                        discard={initFields} 
                        save={saveDoc} 
                        isSaving={isSaving} 
                    /> 
                    : 
                    <></>
                }
                <Flex flex='1' overflow='auto' h='100vh' flexDir='column' bg='gray.50'>
                    <Stack
                        // divider={<StackDivider borderColor="gray.200" />}
                        spacing={12}
                        py={8}
                        mx={8}
                        maxW='xl'
                        align='center'
                        alignSelf='center'
                    >   
                        <Heading 
                            size='lg'  
                            textTransform='uppercase'
                            letterSpacing='wider'
                        >
                            Cell Settings - {cellDoc.name}
                        </Heading>
                        <ContainerCellDoc 
                            title='Current Shift'
                            desc={'The id of the current shift'}
                            children={
                                cellDoc.current_shift ?
                                <Text>{cellDoc.current_shift}</Text>
                                :
                                <Text>{'There is no shift in progress'}</Text>
                            }
                        />
                        {/* <PartAccord 
                            partMap={newPartMap}
                            setNewPartMap={setNewPartMap} 
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Shift Name'
                            title='Shift Names'
                            desc='The names this cell can select for current shift'
                            single='Shift Name'
                            codes={newShifts}
                            setNewCodes={setNewShifts}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Down Code'
                            title='Down Codes'
                            desc='The codes this cell can select for down time'
                            single='Down Code'
                            codes={newDowns}
                            setNewCodes={setNewDowns}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Reject Code'
                            title='Reject Codes'
                            desc='The codes this cell can select for rejects'
                            single='Reject Code'
                            codes={newRejects}
                            setNewCodes={setNewRejects}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Setup Checklist'
                            title='Setup Checklist'
                            desc='Checklist of tasks during setup (in order shown)'
                            single='Setup Task'
                            codes={newSetupList}
                            setNewCodes={setNewSetupList}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Cleanup Checklist'
                            title='Cleanup Checklist'
                            desc='Checklist of tasks during cleanup (in order shown)'
                            single='Cleanup Task'
                            codes={newCleanupList}
                            setNewCodes={setNewCleanupList}
                            setIsChanged={setIsChanged}
                        /> */}

                        <ContainerCellDoc 
                            title='Change password'
                            desc='Update the password of the cell'
                            children={
                                <ChangeCellPass cellId={cellId} cellName={cellDoc.name}/>
                            }
                        />

                        <ContainerCellDoc 
                            title='Danger zone'
                            desc='Irreversible and destructive actions'
                            children={
                                <DeleteCell deleteCell={deleteCell} />
                            }
                        />
                        {/* <Stack spacing={6}>
                            <HeadingCellDoc title='Danger zone' desc='Irreversible and destructive actions' />
                            <Box bg='white' borderRadius='lg' boxShadow='base' p={6} >
                                <Text fontWeight='bold'>
                                    Delete cell account and data
                                </Text>
                                <Text size='sm' mt={1} mb={3} >
                                    Once you delete this cell, there is no going back. Please be certain.
                                </Text>
                                <DeleteCellAlert />
                            </Box>
                        </Stack> */}
                    </Stack>
                    {/* <div>
                        Part_map: {Object.keys(cellDoc.Part_map).map((Part, key) => {
                            return (
                                <div key={key}>
                                    <div>
                                        Part_num: {Part}
                                    </div>
                                </div>
                            )
                        })}
                    </div> */}
                </Flex>
            </Flex>
        </>
    )
}

export default CellDoc