import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cellData, getSessionsData, sessionData } from "../../redux/sessionsSlice"
import { RootState } from "../../redux/store";
import { StoppedData, DownData } from "../../redux/timerSlice"
import { formatDate, formatDateTime, formatSeconds } from "../../utils"

interface ISessionsList {
    cell: cellData
}
const SessionsList = ({cell}: ISessionsList) => {
    const dispatch = useDispatch()
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    const cellSessions = useSelector((state: RootState) => state.sessions.cellSessionsData)
    const sessionsData = cellSessions[cell.id]
    //const [isLoading, setIsLoading] = useState<boolean>(true)
    //const [clicked, setClicked] = useState<boolean>(false)

    useEffect(() => {
        if (sessionsData === undefined) {
            //dispatch(sessionsDataListener(currentUser, cell))
            if (currentUser) {
                dispatch(getSessionsData({currentUser, cell}))
            }
            // const fetchSessions = async () => {
            //     try {
            //         dispatch(getSessionsData({ currentUser, cell }))
            //         console.log(`Success, got sessions from ${cell.id}`)
            //     } catch (err) {
            //         console.log(err)
            //     }
            // }
            // fetchSessions()
        }
    // eslint-disable-next-line
    }, [])

    return (
        <div>
            {(sessionsData !== undefined) ? sessionsData.map((session: sessionData, index: number) => (
                <div key={index} className='py-2'>
                    <h1 className='text-2xl font-bold'>Session - {index + 1} </h1>
                    <div>
                        Id: {session.id}
                    </div>
                    <div>
                        Date: {formatDate(new Date(session.startTime))}
                    </div>
                    <div>
                        Started At: {formatDateTime(new Date(session.startTime))}
                    </div>
                    <div>
                        Cycle Time: {session.cycleTime} sec
                    </div>
                    <div>
                        Planned: {session.planned}
                    </div>
                    <div>
                        Actual: {session.actual}
                    </div>
                    <div>
                        Rejected: {session.rejected}
                    </div>
                    <div>
                        Down Records: {session.downRecords.map((down: DownData, index: number) => (
                            <div key={index} className='pl-10'>
                                <div>
                                    Started At: {formatDateTime(new Date(down.startTime))}
                                </div>
                                <div>
                                    Duration: {formatSeconds(down.duration)}
                                </div>
                                <div>
                                    Code: {down.code}
                                </div>
                                <div>
                                    ---------------------
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <div>
                        Change Records: {session.changeRecords.map((changeTime: changeData, index: number) => (
                            <div key={index} className='pl-10'>
                                <div>
                                    Started At: {formatDateTime(new Date(changeTime.startTime))}
                                </div>
                                <div>
                                    Duration: {formatSeconds(changeTime.duration)}
                                </div>
                                <div>
                                    ---------------------
                                </div>
                            </div>
                        ))}
                    </div> */}
                    <div>
                        Break Records: {session.stoppedRecords.map((breakTime: StoppedData, index: number) => (
                            <div key={index} className='pl-10'>
                                <div>
                                    Started At: {formatDateTime(new Date(breakTime.startTime))}
                                </div>
                                <div>
                                    Duration: {formatSeconds(breakTime.duration)}
                                </div>
                                <div>
                                    ---------------------
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))
            : 
            <div />
            }
        </div>
    )
}

export default SessionsList