import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentUser, setUserLoaded } from "../redux/userSlice";
import { auth, firestore } from "../utils/firebase";


const useAuth = () => {
    const dispatch = useDispatch();
    // const { currentUser } = useSelector((state: RootState) => state.user)
    const [ token, setToken ] = useState<any>(null)
    // const [ isLoading, setIsLoading ] = useState<boolean>(true)
    // const [ refresh, setRefresh ] = useState<boolean>(false)


    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            // dispatch(setUserLoaded(true))
            if (user) {
                // create getRole func in utils, pass userAuth, return tokenResult
                user.getIdTokenResult(true)
                .then(async (idTokenResult) => {
                    setToken(idTokenResult)
                    // console.log(idTokenResult.claims.user_id)
                    // console.log(idTokenResult)
                    // Confirm the user is an Admin.
                    if (!!idTokenResult.claims.admin) {  // Admin Log in
                        // Show admin UI.
                        // console.log("I'm an admin")
                        // const userRef = await createUserDocument(user);
                        
                        // userRef?.onSnapshot(snapshot => {
                        // TODO: convert below to useAdminDoc listener with await
                        // firestore.doc(`users/${user.uid}`).onSnapshot(snapshot => {
                        //     const data = snapshot.data()
                            
                        //     dispatch(
                        //         setCurrentUser({
                        //         id: snapshot.id,
                        //         // role: data ? data.role : null,
                        //         ...data,
                        //         // subscriptions: []
                        //         })
                        //     )
                        //     dispatch(setUserLoaded(true))
                        // })

                        const adminDoc = await firestore.doc(`users/${user.uid}`).get()
                        // console.log(adminDoc)
                        dispatch(
                            setCurrentUser({
                            id: adminDoc.id,
                            // role: data ? data.role : null,
                            ...adminDoc.data(),
                            // subscriptions: []
                            })
                        )
                        dispatch(setUserLoaded(true))

                        // console.log(user)
                        // dispatch(
                        //     setCurrentUser({
                        //         id: user.uid,
                        //         ...user,
                        //         subscriptions: []
                        //     })
                        // )
                        
                        // await dispatch(getActiveSubsThunk(user.uid))
                        // await dispatch(getCellListThunk(user.uid))
                        // dispatch(setUserLoaded(true))
                    } else {
                        let role: string = "";
                        if (!!idTokenResult.claims.manager) {
                            // console.log("I'm a manager")
                            role = "manager"
                        } else if (!!idTokenResult.claims.viewer) {
                            // console.log("I'm a cell")
                            role = "viewer"
                        } else if (!!idTokenResult.claims.cell) {
                            // console.log("I'm a cell")
                            role = "cell"
                        } else {
                            console.log("I have NO role")
                        }
                        dispatch(
                            setCurrentUser({
                                id: user.uid,
                                role: role,
                                email: idTokenResult.claims.email,
                                displayName: idTokenResult.claims.email.replace("@email.com", ""),
                                adminUID: idTokenResult.claims.adminUID,
                                status: idTokenResult.claims.status
                            })
                        )
                        dispatch(setUserLoaded(true))
                    }
                })
            } else {
                dispatch(setCurrentUser(null))
                dispatch(setUserLoaded(true))
            }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    }, [dispatch]);

    // if (!!token.claims.admin) {
    //     const { loading } = useAdminDoc(token.claims.user_id)
    // }

    return {
        token,
        // isLoading,
    }
}

export default useAuth