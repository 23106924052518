import {
    Flex,
    Text,
    Icon,
    Link,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

export default function AdminNavItem({ icon, name, navSize, to, click }: any) {
    return (
        <Flex
            mt={30}
            flexDir="column"
            w="100%"
            alignItems={navSize === "small" ? "center" : "flex-start"}
        >
            {click ? 
                <Link
                // backgroundColor={active && "#AEC8CA"}
                p={3}
                borderRadius={8}
                _hover={{ textDecor: 'none', backgroundColor: "cyan.100" }}
                w={navSize === "large" ? "100%" : ''}
                onClick={click}
            >
                <Flex>
                    <Icon 
                        as={icon} 
                        fontSize="xl" 
                        // color={active ? "#82AAAD" : "gray.500"} 
                    />
                    <Text ml={5} display={navSize === "small" ? "none" : "flex"}>
                        {name}
                    </Text>
                </Flex>
            </Link>
            :
            <Link
                as={RouterLink}
                // backgroundColor={active && "#AEC8CA"}
                p={3}
                borderRadius={8}
                _hover={{ textDecor: 'none', backgroundColor: "cyan.100" }}
                _focus={{ textDecor: 'none', backgroundColor: "cyan.100" }}
                w={navSize === "large" ? "100%" : ''}
                to={to}
            >
                <Flex>
                    <Icon 
                        as={icon} 
                        fontSize="xl" 
                        // color={active ? "#82AAAD" : "gray.500"} 
                    />
                    <Text ml={5} display={navSize === "small" ? "none" : "flex"}>
                        {name}
                    </Text>
                </Flex>
            </Link>
            }              
        </Flex>
    )
}