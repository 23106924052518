import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/modal"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { decActual } from "../../redux/timerSlice"

interface IDecActualAlert {
    lgH: boolean
}

const DecActualAlert = ({ lgH }: IDecActualAlert) => {
    const shiftActual = useSelector((state: RootState) => state.timer.actualParts)
    const batchActual = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.actual)
    const hourActual = useSelector((state: RootState) => state.timer.hourRecords.slice(-1)[0]?.actual)
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef<any>()
    const dispatch = useDispatch()

    return (
        <>
            <Button
                ml={2}
                mb={1}
                size={lgH ? 'sm' : 'xs'}
                border='1px'
                borderColor='gray.200'
                boxShadow='sm'
                onClick={onOpen}
                isDisabled={
                    shiftActual <= 0 ||
                    batchActual <= 0 ||
                    hourActual <= 0 ||
                    !!shiftEndTime
                }
            >
                -
            </Button>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Decrement Actual
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure you want to remove a part from actual?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose} size='lg'>
                                Cancel
                            </Button>
                            <Button 
                                colorScheme='red' 
                                onClick={() => {onClose(); dispatch(decActual())}} 
                                ml={3}
                                size='lg'
                            >
                                Decrement
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default DecActualAlert