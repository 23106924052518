import { Redirect, Route } from 'react-router-dom';
import Unauthorized from '../../pages/Unauthorized';

const TimerMainRoute = ({component: Component, access, isStarted, message, displayButton, ...rest}: any) => {
    if (access && isStarted) {
        return <Route {...rest} render={props => <Component {...props} />} />
    } else if (access && !isStarted) {
        return <Route {...rest} render={props => <Redirect to='/' />} />
    }

    return (
        <Route {...rest} render={() => <Unauthorized message={message} displayButton={displayButton} />} />
    );
}

export default TimerMainRoute;