import { useMediaQuery } from "@chakra-ui/react"
import { Table, Thead, Tr, Th, Tbody, Td, Tfoot } from "@chakra-ui/table"

interface IOleData {
    name: string,
    value: string | number
}

interface IOleDataTable {
    data: Array<IOleData>
    unit: string
}

const OlePplTable = ({ data, unit }: IOleDataTable) => {
    const firstEntry = data[0]
    const otherEntries = data.slice(1)

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])
    
    let OESize: string = 'md'
    let textSize: string = 'sm'
    let thSize: string = 'xs'
    let spacing: string = 'xs'
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        OESize = 'md'
        textSize = 'sm'
        thSize = 'xs'
        spacing = 'xs'
    }
    if (smH) {  // 480px
        OESize = 'lg'
        textSize = 'md'
        thSize = 'sm'
        spacing = 'sm'
    }
    if (mdH) {  // 768px
        OESize = 'xl'
        textSize = 'lg'
        thSize = 'md'
        spacing = 'md'
    }
    if (lgH) {  // 992px
        OESize = '2xl'
        textSize = 'xl'
        thSize = 'lg'
        spacing = 'md'
    }
    if (xlH) {  // 1280px
        OESize = '3xl'
        textSize = '2xl'
        thSize = 'xl'
        spacing = 'lg'
    }

    return (
        <>
            <Table variant='simple' size={spacing}>
                {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th isNumeric fontSize={thSize}>{unit}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {otherEntries.map(entry => (
                        <Tr key={entry.name}>
                            <Td fontSize={firstEntry.name === 'OE' ? OESize : textSize}>{entry.name}</Td>
                            <Td isNumeric fontSize={firstEntry.name === 'OE' ? OESize : textSize}>{entry.value}</Td>
                        </Tr>
                    ))}

                    {/* <Tr>
                        <Td>{performData[2].name}</Td>
                        <Td isNumeric>{performData[2].value}</Td>
                    </Tr> */}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Th fontSize={thSize}>{firstEntry.name}</Th>
                        <Th isNumeric fontSize={thSize}>{firstEntry.value}</Th>
                    </Tr>
                </Tfoot>
            </Table>
        </>
    )
}

export default OlePplTable