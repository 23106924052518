import { Box, Stack, Table, Tbody, Tr, Th, Td, chakra, Thead, Text, useToast, Input, Flex, Select } from "@chakra-ui/react"
import HeadingCellDoc from "./HeadingCellDoc"
import AddPart from "./AddPart"
import { PartData } from "../../hooks/useConfigCell"
import _ from "lodash"
import { useMemo } from "react"
import { TriangleDownIcon, TriangleUpIcon, ChevronRightIcon, ChevronDownIcon, DeleteIcon } from "@chakra-ui/icons"
import { useExpanded, useFlexLayout, useGlobalFilter, useSortBy, useTable } from "react-table"
import React from "react"
import CellMultiSelect from "./CellMultiSelect"
import RemovePartForm from "./RemovePart"
import AddPartDesign from "./AddPartDesign"

// interface heading {
//     title: string
//     desc: string
// }

// interface headingMap {
//     [type: string]: heading
// }

interface IPartTable {
    parts: Array<PartData>
    setNewParts: React.Dispatch<React.SetStateAction<PartData[]>>
    setIsChanged: React.Dispatch<React.SetStateAction<boolean>>
}

const PartTable = ({ parts, setNewParts, setIsChanged }: IPartTable) => {

    // const headingMap: headingMap = {
    //     'Down Code': {
    //         title: 'Down Codes',
    //         desc: 'The codes this cell can select for down time' 
    //     },
    //     'Reject Code': {
    //         title: 'Reject Codes',
    //         desc: 'The codes this cell can select for rejects'
    //     },
    //     'Shift Name': {
    //         title: 'Shift Names',
    //         desc: 'The names this cell can select for current shift'
    //     }
    // }

    const toast = useToast()

    // const data: Array<CodeData> = useMemo(() => [], [])
    const data: Array<PartData> = useMemo(() => parts, [parts])
    // console.log('data', data)

    const columns: any = useMemo(
        () => [
            {
                id: 'expander', // Make sure it has an ID
                // Header: () => null,
                maxWidth: 10,
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded ?
                            <ChevronDownIcon aria-label='expanded' />
                            :
                            <ChevronRightIcon aria-label='collapsed' />
                        }
                    </span>
                ),
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    // row.canExpand ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ?
                            <ChevronDownIcon aria-label='expanded' />
                            :
                            <ChevronRightIcon aria-label='collapsed' />
                        }
                    </span>
                // ) : null,
            },
            {
                id: 'part num',
                Header: 'Part #',
                accessor: 'partNum',
            },
            {
                id: 'alias',
                Header: 'Alias',
                accessor: 'alias',
            },
            // {
            //     id: 'delete', // Make sure it has an ID
            //     Header: () => null,
            //     maxWidth: 20,
            //     Cell: ({ row }: any) =>
            //         <DeleteIcon
            //             size='sm'
            //             onClick={() => handleDeleteCode(row.index)}
            //             cursor='pointer'
            //         />
            // }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        state: { globalFilter },
        // @ts-ignore
        setGlobalFilter,
        // @ts-ignore
    } = useTable({ columns, data, autoResetExpanded: false, defaultPageSize: 10 },
        useFlexLayout,
        useGlobalFilter,
        useSortBy,
        useExpanded,
    )

    const updateSelectedCells = (idx: number, selectedCells: Array<string>) => {
        setIsChanged(true)
        const newParts = _.cloneDeep(parts)
        newParts[idx].cells = selectedCells
        setNewParts(newParts)
    }

    // const handleDeleteCode = (index: number) => {
    //     setIsChanged(true)
    //     const newCodes = _.cloneDeep(codes)
    //     newCodes.splice(index, 1)
    //     setNewCodes(newCodes)
    // }

    const handleRemovePart = (part: string) => {
        setIsChanged(true)
        const newParts: Array<PartData> = _.cloneDeep(parts)
        const index: number = newParts.findIndex(p => p.partNum.toLowerCase() === part.toLowerCase())
        newParts.splice(index, 1)
        setNewParts(newParts)
        // setNewParts(parts.filter(partData => partData.partNum !== part))
    }

    const handleAddPart = (part: string, alias: string, numPeople: string, cycleTime: number) => {
        if (parts.filter(p => p.partNum.toLowerCase() === part.toLowerCase()).length > 0) {
            toast({
                title: `Part number ${part} already exists.`,
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        }

        setIsChanged(true)
        const newParts: Array<PartData> = _.cloneDeep(parts)
        newParts.push({
            partNum: part,
            alias: alias,
            cells: [],
            designData: {
                default: Number(numPeople),
                [numPeople]: cycleTime
            },
            // setupTime: 0,
            // cleanupTime: 0,
            // setupCheck: {},
            // cleanupCheck: {}
        })
        // console.log(newParts)
        setNewParts(newParts)
    }

    const handleAddDesign = (part: string, numPeople: string, cycleTime: number) => {
        setIsChanged(true)
        const newParts: Array<PartData> = _.cloneDeep(parts)
        const index: number = newParts.findIndex(p => p.partNum === part)
        newParts[index].designData = {
            ...newParts[index].designData,
            [numPeople]: cycleTime
        }
        setNewParts(newParts)
    }

    const handleDeleteDesign = (part: string, numPeople: string) => {
        setIsChanged(true)
        const newParts: Array<PartData> = _.cloneDeep(parts)
        const index: number = newParts.findIndex(p => p.partNum === part)
        delete newParts[index].designData[numPeople]
        setNewParts(newParts)
    }

    const handleChangeDefault = (part: string, value: number) => {
        setIsChanged(true)
        const newParts: Array<PartData> = _.cloneDeep(parts)
        const index: number = newParts.findIndex(p => p.partNum === part)
        newParts[index].designData.default = value
        setNewParts(newParts)
    }

    const mapPartData = (partData: PartData): Array<JSX.Element> => {
        return Object.keys(partData.designData).sort().filter((key) => key !== 'default').map((key, index) => (
            <Tr key={index} >
                <Td>{key}</Td>
                <Td>{partData.designData[key]}</Td>
                <Td w='12'>
                    <DeleteIcon
                        boxSize={3.5}
                        cursor='pointer'
                        onClick={() => handleDeleteDesign(partData.partNum, key)}
                    />
                </Td>
            </Tr>
        ))
    }

    return (
        // Same as ContainerCellDoc, but with Box p={2} instead of p={6}
        <Stack spacing={6} minW='xl'>
            <HeadingCellDoc title={'Parts'} desc={'The parts this cell can select for batches'} />
            <Box
                boxShadow='base'
                borderRadius="lg"
                p={8}
                bg='white'
            >
                <Flex px={2} justify='space-between' align='center'>
                    <AddPart handleAddPart={handleAddPart} />
                    {data.length > 0 && <RemovePartForm parts={parts} handleRemovePart={handleRemovePart} />}
                </Flex>
                {data.length > 0 &&
                    <>
                        <Box px={2} >
                            <Input
                                placeholder={`Search Part # or Alias...`}
                                my={6}
                                bg='white'
                                value={globalFilter || ''}
                                onChange={e => setGlobalFilter(e.currentTarget.value)}
                                size='sm'
                            />
                            {/* <Divider /> */}
                        </Box>
                        <Table {...getTableProps()} border='1px' borderColor='gray.100'>
                            {/* {data.length > 0 && */}
                            <Thead bg='gray.100'>
                                {headerGroups.map((headerGroup: any, idx: number) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                        {headerGroup.headers.map((column: any) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                key={column.id}
                                                flex={column.accessor === 'code' ? 1 : ''}
                                                px={4}
                                            >
                                                {column.render('Header')}
                                                <chakra.span pl='4' key={column.id + 'arrow'}>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <TriangleDownIcon aria-label='sorted descending' />
                                                        ) : (
                                                            <TriangleUpIcon aria-label='sorted ascending' />
                                                        )
                                                    ) : null}
                                                </chakra.span>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                                {rows.map((row: any) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={data[row.index].partNum} >
                                            <Tr {...row.getRowProps()} key={data[row.index].partNum + 'data'}>
                                                {row.cells.map((cell: any) => (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        px={4}
                                                    >
                                                        {cell.render('Cell')}
                                                    </Td>
                                                ))}
                                            </Tr>
                                            {row.isExpanded &&
                                                <Tr key={data[row.index].partNum + 'cells'}>
                                                    <Td pl={8}>
                                                        <Text mb={2}  textColor='gray.800'>
                                                            {`Assign cells - ${data[row.index].partNum}`}
                                                        </Text>
                                                        <CellMultiSelect
                                                            idx={row.index}
                                                            selectedIds={data[row.index].cells}
                                                            updateSelectedCells={updateSelectedCells}
                                                        />
                                                        <Flex my={2} mt={8}>
                                                            <Flex flex={1}>
                                                                <Text fontSize='md' my='auto'>
                                                                    Default:
                                                                </Text>
                                                                <Select
                                                                    my='auto'
                                                                    bg='white'
                                                                    size='xs'
                                                                    w='auto'
                                                                    ml={2}
                                                                    value={data[row.index].designData.default}
                                                                    // onChange={(e: any) => {setIsChanged(true); PartMap[Part].default = Number(e.currentTarget.value); console.log(PartMap)}}
                                                                    onChange={(e: any) => handleChangeDefault(data[row.index].partNum, Number(e.currentTarget.value))}
                                                                >
                                                                    {Object.keys(data[row.index].designData).sort()
                                                                        .filter((key) => key !== 'default')
                                                                        .map((numPeople, index) => {
                                                                            return <option value={numPeople} key={index}>
                                                                                {numPeople}
                                                                            </option>
                                                                        })
                                                                    }
                                                                </Select>
                                                            </Flex>
                                                            <AddPartDesign part={data[row.index].partNum} handleAddDesign={handleAddDesign} />
                                                        </Flex>
                                                        <Table size='sm' mb={2}>
                                                            <Thead bgColor='gray.100'>
                                                                <Tr>
                                                                    <Th>
                                                                        Number of People
                                                                    </Th>
                                                                    <Th>
                                                                        Cycle Time
                                                                    </Th>
                                                                    <Th />
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody bg='white'>
                                                                {mapPartData(data[row.index])}
                                                            </Tbody>
                                                        </Table>
                                                    </Td>
                                                </Tr>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </Tbody>
                        </Table>
                    </>
                }
            </Box>
        </Stack>
    )
}

export default PartTable
