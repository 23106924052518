import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/modal"
import { IconButton, Button, useMediaQuery, VStack, StackDivider, Icon } from "@chakra-ui/react"
import { MdOutlineList } from "react-icons/md"
import { DownData, RejectData } from "../../redux/timerSlice"
import DRInfo from "./DRInfo"


interface IDRListModal {
    type: 'down' | 'reject',
    records: Array<RejectData | DownData>,
    codes: Array<string>,
}

const DRListModal = ({ type, records, codes }: IDRListModal) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let buttonSize: string = 'xs'

    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        buttonSize = 'xs'
    }
    if (smH) {  // 480px
        buttonSize = 'sm'
    }
    if (mdH) {  // 768px
        buttonSize = 'md'
    }
    if (lgH) {  // 992px
        buttonSize = 'lg'
    }
    if (xlH) {  // 1280px
        buttonSize = 'lg'
    }

    return (
        <>
            <IconButton
                ml={2}
                size={buttonSize}
                icon={<Icon as={MdOutlineList} w='70%' h='70%' />
                // <HamburgerIcon />
                }
                border='1px'
                borderColor='gray.200'
                boxShadow='sm'
                onClick={onOpen}
                aria-label={`open ${type} list`}
                // limit size blowout on PWA
                maxW={buttonSize === 'sm' ? 8 : (buttonSize === 'md' ? 10 : 12)}
            />

            <Modal
                onClose={onClose}
                size='lg'
                isOpen={isOpen}
                // scrollBehavior={'inside'}
            // closeOnOverlayClick={false}
            // closeOnEsc={false}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader textTransform='capitalize'>{`${type} List`}</ModalHeader>
                    <ModalCloseButton onClick={onClose} />
                    <ModalBody>
                        <VStack 
                            spacing={4} 
                            divider={<StackDivider borderColor='gray.200' />}
                            w={4/5}
                            mx='auto'
                        >
                            {records.map((record, index) =>
                                <DRInfo
                                    type={type}
                                    record={record}
                                    index={index}
                                    selectCodes={codes}
                                    key={index}
                                />
                            )}
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='teal' onClick={onClose}>Done</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DRListModal