import { Button } from "@chakra-ui/button"
import { GridItem, Grid, Flex } from "@chakra-ui/layout"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { incRejected } from "../../redux/timerSlice"
import BreakModal from "./BreakModal"
import ChangeModal from "./ChangeModal"
import Quality from "./Quality"

const LeftCol = () => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    // const isRunning = useSelector((state: RootState) => state.timer.isRunning)
    const dispatch = useDispatch()

    return (
        <>
            <GridItem colSpan={3}>
                <Grid h='full' overflow='hidden' templateRows='13% 13% auto 17%' gap={2}>
                {/* <Grid h='full' overflow='hidden' templateRows='13% auto 17%' gap={2}> */}
                    <GridItem >
                        <BreakModal />
                    </GridItem>

                    <GridItem >
                        <ChangeModal />
                    </GridItem>

                    <Quality />

                    <GridItem >
                        <Flex h='full'>
                            {/* <Button
                                colorScheme='red'
                                bg='red.400'
                                _hover={{ bg: 'red.500' }}
                                w='full'
                                h='full'
                                mr={1}
                                fontSize={['md', 'lg', 'xl', '2xl']}
                            >
                                Rework
                            </Button> */}
                            <Button
                                colorScheme='red'
                                bg='red.500'
                                _hover={{ bg: 'red.700' }}
                                w='full'
                                h='full'
                                fontSize={['md', 'lg', 'xl', '2xl']}
                                onClick={() => dispatch(incRejected())}
                                disabled={isStopped || isChange || isDown}
                            >
                                Reject
                            </Button>
                        </Flex>
                    </GridItem>
                </Grid>
            </GridItem>
        </>
    )
}

export default LeftCol