import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/modal"
import { Button, Flex, Heading, VStack, Select, FormControl, FormLabel, Input, Box, useMediaQuery, NumberInput, NumberInputField } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useConfigCell from "../../hooks/useConfigCell"
import { RootState } from "../../redux/store"
import { changeBatch, runBatch } from "../../redux/timerSlice"
import { formatSeconds } from "../../utils"
import BreakModal from "./BreakModal"
import PeoplePopup from "./PeoplePopup"


const ChangeModal = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const cancelRef = useRef<any>()

    const changeTime = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.totalChange)
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const isEditing = useSelector((state: RootState) => state.timer.isEditingRedux)
    const endTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    const currentPeople = useSelector((state: RootState) => state.timer.peopleRecords.slice(-1)[0].peopleList)
    // const isEditing = useSelector((state: RootState) => state.timer.isEditingRedux)
    const [workOrder, setWorkOrder] = useState<string>('')
    const [goalParts, setGoalParts] = useState<string>('')
    const [nextPart, setNextPart] = useState<string>('')
    const [numPeople, setNumPeople] = useState<number>(0)

    // const { loading, cellDoc, error } = useCellDoc('cell')
    // const { cellDoc } = useCellDoc('cell')
    const { configCell } = useConfigCell('cell')

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let selectSize: string = 'xs'
    // let timeSize: string = '3xl'
    let headingSize: string = 'xl'
    let hwProps: { h?: string, w?: string} = {}
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        selectSize = 'xs'
        // timeSize = '3xl'
        headingSize = 'xl'
        hwProps = {}
    }
    if (smH) {  // 480px
        selectSize = 'sm'
        // timeSize = '3xl'
        headingSize = '2xl'
        hwProps = {}
    }
    if (mdH) {  // 768px
        selectSize = 'md'
        // timeSize = '4xl'
        headingSize = '3xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (lgH) {  // 992px
        selectSize = 'lg'
        // timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (xlH) {  // 1280px
        selectSize = 'lg'
        // timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }

    // Key bindings for 'c'
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isEditing && !isDown && !isStopped && !isChange && event.key === 'c') {
                onOpenAlert()
            }
        }
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChange, isStopped, isDown, isEditing])

    const clear = () => {
        setWorkOrder('')
        setGoalParts('')
        setNextPart('')
        setNumPeople(0)
    }

    return (
        <>
            <Button
                colorScheme='teal'
                w='full'
                h='full'
                fontSize={['md', 'lg', 'xl', '2xl']}
                onClick={onOpenAlert}
                isDisabled={!!endTime || isDown || isStopped}
            >
                Change
            </Button>

            {
                // ALERT
            }
            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
                size='lg'
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            End Batch
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert} size='lg'>
                                Cancel
                            </Button>
                            <Button 
                                colorScheme='red' 
                                onClick={() => { 
                                    onCloseAlert()
                                    onOpen() 
                                    dispatch(changeBatch()) 
                                }} 
                                ml={3}
                                size='lg'
                            >
                                End Batch
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            
            {
                // MODAL
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size='full'
            >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader mx='auto'>
                        Changeover
                    </ModalHeader> */}
                    <ModalBody>
                        {/* <Flex flexDir='column' h='full' justifyContent='space-evenly'> */}
                        <Flex justifyContent='space-between'>
                            <Box justifySelf='flex-start' w={1 / 4}>
                                <BreakModal />
                            </Box>
                            <Box justifySelf='center' alignSelf='center'>
                                <PeoplePopup />
                            </Box>
                            <Heading justifySelf='flex-end' w={1 / 4} textAlign='end' fontSize='6xl' alignSelf='center'>
                                {changeTime === -1 ?
                                    formatSeconds(0)
                                    :
                                    formatSeconds(changeTime)
                                }
                            </Heading>
                        </Flex>
                        <VStack w={4 / 5} h='60vh' mx='auto' justifyContent='space-around'>
                            <Heading size={headingSize} alignSelf='center'>
                                Changeover
                            </Heading>
                            {/* <FormControl id='work-order-number' isRequired>
                                <FormLabel>Work Order #</FormLabel>
                                <Input
                                    autoFocus
                                    size={selectSize}
                                    placeholder='Work order #'
                                    value={workOrder}
                                    onChange={e => setWorkOrder(e.currentTarget.value)}
                                />
                            </FormControl> */}
                            <Flex w='full' justify='space-between'>
                                <FormControl id='work-order-number' isRequired w='49%'>
                                    <FormLabel>Work Order #</FormLabel>
                                    <Input
                                        autoFocus
                                        size={selectSize}
                                        placeholder='Work order #'
                                        value={workOrder}
                                        onChange={e => setWorkOrder(e.currentTarget.value)}
                                        isDisabled={currentPeople.length === 0}
                                    />
                                </FormControl>
                                <FormControl id='goal-num-parts' isRequired w='49%'>
                                    <FormLabel>Goal No. Parts</FormLabel>
                                        <NumberInput
                                            min={0}
                                            size={selectSize}
                                            isDisabled={currentPeople.length === 0}
                                        >
                                            <NumberInputField
                                                placeholder={'Goal No. Parts'}
                                                value={goalParts || ''}
                                                onKeyDown={e => 
                                                    (e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') 
                                                    && e.preventDefault()
                                                }
                                                onChange={e => setGoalParts(e.currentTarget.value)}
                                            />
                                        </NumberInput>
                                </FormControl>
                            </Flex>
                            <FormControl id='next-Part' isRequired>
                                <FormLabel>Next Part</FormLabel>
                                <Select
                                    isRequired
                                    // onKeyDown={e => {
                                    //     if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    placeholder='Select Part'
                                    size={selectSize}
                                    onChange={e => {
                                        setNextPart(e.currentTarget.value)
                                        setNumPeople(configCell.parts.find(p => p.partNum === e.currentTarget.value)?.designData['default'] || 0)
                                    }}
                                    isDisabled={currentPeople.length === 0}
                                >
                                    {configCell.parts.sort().map((part, index) => {
                                        return <option value={part.partNum} key={index}>{`${part.alias} - ${part.partNum}`}</option>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl id='no-people' isRequired>
                                <FormLabel>No. People</FormLabel>
                                <Select
                                    isRequired
                                    // onKeyDown={e => {
                                    //     if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    value={numPeople}
                                    size={selectSize}
                                    // default={nextPart && cellDoc.part_map[nextPart]['default']}
                                    placeholder='Select No. People'
                                    onChange={e => setNumPeople(Number(e.currentTarget.value))}
                                    isDisabled={!nextPart || currentPeople.length === 0}
                                >
                                    {nextPart && Object.keys(configCell.parts.find(p => p.partNum === nextPart)?.designData || {}).filter((key) => key !== 'default').map((ppl, index) => {
                                        return <option value={ppl} key={index}>{ppl}</option>
                                    })}
                                </Select>
                            </FormControl>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme='teal'
                            onClick={() => {
                                dispatch(runBatch({
                                    workOrder,
                                    goalParts,
                                    nextPart,
                                    numPeople,
                                    cycleTime: configCell.parts.find(p => p.partNum === nextPart)?.designData[numPeople] || 0
                                }))
                                clear()
                                onClose()
                            }}
                            mx='auto'
                            {...hwProps}
                            size='lg'
                            isDisabled={
                                !workOrder || 
                                !nextPart || 
                                !numPeople || 
                                !goalParts || 
                                !Number.isInteger(Number(goalParts)) || 
                                Number(goalParts) < 0 || 
                                currentPeople.length === 0
                            }
                        >
                            Complete Changeover
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChangeModal