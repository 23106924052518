import { useEffect, useState } from "react"
import useCellListDoc, { CellIdName } from "../../hooks/useCellList"
import { GroupBase, Select } from "chakra-react-select";
import { Box } from "@chakra-ui/react"

export interface SelectOptions {
    value: string
    label: string
    colorScheme?: string
}
interface ICellMultiSelect {
    idx: number,
    selectedIds: Array<string>,
    updateSelectedCells: (idx: number, selectedCells: Array<string>) => void,
}

const CellMultiSelect = ({ idx, selectedIds, updateSelectedCells }: ICellMultiSelect) => {

    const { cellListDoc } = useCellListDoc()

    const [selectedOptions, setSelectedOptions] = useState<Array<SelectOptions>>()
    const [activeCellOptions, setActiveCellOptions] = useState<Array<SelectOptions>>([])
    const [inactiveCellOptions, setInactiveCellOptions] = useState<Array<SelectOptions>>([])

    const groupedOptions = [
        {
            label: "Active Cells",
            options: activeCellOptions
        },
        {
            label: "Inactive Cells",
            options: inactiveCellOptions
        },
    ]

    const getOptions = () => {
        const activeOptions: Array<SelectOptions> = []
        const inactiveOptions: Array<SelectOptions> = []
        cellListDoc.all_cells.forEach((cell: CellIdName) => {
            if (cellListDoc.active_users.includes(cell.id)) {  // user is active
                activeOptions.push({
                    value: cell.id,
                    label: cell.name,
                    colorScheme: 'green'
                })
            } else {  // user is inactive
                inactiveOptions.push({
                    value: cell.id,
                    label: cell.name
                })
            }
        })
        setActiveCellOptions(activeOptions)
        setInactiveCellOptions(inactiveOptions)
    }

    const getSelectedOptions = () => {
        const options: Array<SelectOptions> = []
        selectedIds.forEach(id => {
            cellListDoc.all_cells.forEach((cell: CellIdName) => {
                if (id === cell.id) {
                    if (cellListDoc.active_users.includes(id)) {
                        options.push({
                            value: cell.id,
                            label: cell.name,
                            colorScheme: 'green'
                        })
                    } else {
                        options.push({
                            value: cell.id,
                            label: cell.name,
                        })
                    }
                }
            })
        })
        setSelectedOptions(options)
    }
    
    useEffect(() => {
        getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellListDoc])

    useEffect(() => {
        getSelectedOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIds, cellListDoc])

    return (
        <>
            <Box w='full' bg='white'>
                <Select<SelectOptions, true, GroupBase<SelectOptions>>
                    isMulti={true}
                    name="cells"
                    options={groupedOptions}
                    placeholder="Select cells..."
                    closeMenuOnSelect={false}
                    blurInputOnSelect={false}
                    size="sm"
                    isClearable={false}
                    backspaceRemovesValue={false}
                    value={selectedOptions}
                    onChange={e => {
                        const newSelected: Array<string> = []
                        e.forEach(option =>
                            newSelected.push(option.value)
                        )
                        updateSelectedCells(idx, newSelected)
                    }}
                />
            </Box>
        </>
    )
}

export default CellMultiSelect