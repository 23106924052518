import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions'
import 'firebase/analytics'
import "firebase/performance"
import { cellData, sessionData, setCellsData } from "../redux/sessionsSlice";
import { ITimerSlice } from "../redux/timerSlice";
import { userData } from "../redux/userSlice";

const firebaseConfig = {
    apiKey: "AIzaSyBEFoFsoWbWvqfg3R4ThuF6z-CndADuno0",
    authDomain: "takt-timer.firebaseapp.com",
    projectId: "takt-timer",
    storageBucket: "takt-timer.appspot.com",
    messagingSenderId: "386774682578",
    appId: "1:386774682578:web:ca8e475d6765a70164560b",
    measurementId: "G-P66EHVRTHV"
};

// export const createUserDocument = async (userAuth: any, additionalData?: any) => {
//     if (!userAuth) return;

//     const userRef = firestore.doc(`users/${userAuth.uid}`)

//     const snapshot = await userRef.get();

//     if (!snapshot.exists) {
//         const { company, displayName, email, role } = userAuth;
//         const createdAt = new Date().getTime();
//         const adminData = {
//             company,
//             displayName,
//             email,
//             role,
//             createdAt,
//             ...additionalData
//         }

//         try {
//             await userRef.set(adminData)
//         } catch (error: any) {
//             console.log('error creating user', error.message)
//         }
//     }

//     return userRef;
// }

// export const adminCreateUserDocument = async (userAuth: any, additionalData?: any) => {
//     if (!userAuth) return;

//     const userRef = firestore.doc(`users/${userAuth.uid}`)

//     const snapshot = await userRef.get();

//     if (!snapshot.exists) {
//         const createdAt = new Date();
//         if (userAuth.role === 'manager') {
//             const { firstName, lastName, email, role, adminUID } = userAuth;
//             const managerData = {
//                 firstName,
//                 lastName,
//                 email,
//                 role,
//                 createdAt,
//                 ...additionalData
//             }

//             try {
//                 await userRef.set(managerData)
//                 copyUserToAdminDoc(adminUID, role, managerData)
//                 // await firestore.collection(`users/${adminUID}/managers`).add(managerData)
//             } catch (error) {
//                 console.log('error creating user', error.message)
//             }
//         } else if (userAuth.role === 'manager') {
//             const { email, role, adminUID } = userAuth;
//             const cellData = {
//                 email,
//                 role,
//                 createdAt,
//                 ...additionalData
//             }

//             try {
//                 await userRef.set(cellData)
//                 copyUserToAdminDoc(adminUID, role, cellData)
//             } catch (error) {
//                 console.log('error creating user', error.message)
//             }
//         }
//     }
//     console.log('Created:', userRef)
//     return userRef;
// }

// const copyUserToAdminDoc = async (adminUID: string, role: string, data: any) => {
//     await firestore.collection(`users/${adminUID}/${role}s`).add(data)
// }


// ----- SESSIONS -----

// Maybe use map? checkout ecommFirebaseUtils
export const convertSessionsDataSnapshotToArray = (sessions: any): Array<sessionData> => {
    const sessionsArray: Array<sessionData> = [];
    sessions.docs.forEach((doc: any) => {
        const sessionData = doc.data()
        sessionsArray.push({
            'id': doc.id,
            'startTime': sessionData.startTime.toDate().getTime(),
            'cycleTime': sessionData.cycleTime,
            'planned': sessionData.planned,
            'actual': sessionData.actual,
            'rejected': sessionData.rejected,
            'downRecords': sessionData.downRecords,
            'stoppedRecords': sessionData.stoppedRecords,
        })
    })
    return sessionsArray
}

export const convertCellsSnapshotToArray = (cells: any): Array<cellData> => {
    const cellsArray: Array<cellData> = [];
    cells.docs.forEach((doc: any) => {
        const cells = doc.data()
        cellsArray.push({
            'id': doc.id,
            'displayName': cells.displayName
        })
    })
    return cellsArray
}

export const cellsDataListener = (currentUser: userData) => {
    let collectionRef: string;
    if (currentUser.role === 'admin') {
        collectionRef = `users/${currentUser.id}/cells`
    } else if (currentUser.role === 'manager') {
        collectionRef = `users/${currentUser.adminUID}/cells`
    }
    return (dispatch: any) => {
        const unsubscribe = firestore.collection(collectionRef)
            .onSnapshot(snapshot => {
                const cellsArray = convertCellsSnapshotToArray(snapshot)
                dispatch(setCellsData(cellsArray))
            }, error => error.message)
        return () => unsubscribe()
        
    }
}

export const fetchSessionsDataAsync = async (currentUser: userData, cell: cellData) => {
    let collectionRef: string = '';
    if (currentUser.role === 'admin') {
        collectionRef = `users/${currentUser.id}/cells/${cell.id}/sessions`
    } else if (currentUser.role === 'manager') {
        collectionRef = `users/${currentUser.adminUID}/cells/${cell.id}/sessions`
    }
    const sessionsRef = firestore.collection(collectionRef);
    const snapshot = await sessionsRef.get();
    const sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
    return sessionsArray
}

// export const sessionsDataListener = (currentUser: userData, cell: cellData) => {
//     let collectionRef: string;
//     if (currentUser.role === 'admin') {
//         collectionRef = `users/${currentUser.id}/cells/${cell.id}/sessions`
//     } else if (currentUser.role === 'manager') {
//         collectionRef = `users/${currentUser.adminUID}/cells/${cell.id}/sessions`
//     }
//     return (dispatch: any) => {
//         const unsubscribe = firestore.collection(collectionRef)
//             .onSnapshot(snapshot => {
//                 const sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
//                 console.log(sessionsArray)
//                 dispatch(setCellSessions({cellId: cell.id, sessionsData: sessionsArray}))
//             }, error => error.message)
//         return () => unsubscribe()
//     }
// }

// export const sessionsDataListener = (currentUser: userData, cell: cellData) => {
//     let collectionRef: string;
//     if (currentUser.role === 'admin') {
//         collectionRef = `users/${currentUser.id}/cells/${cell.id}/sessions`
//     } else if (currentUser.role === 'manager') {
//         collectionRef = `users/${currentUser.adminUID}/cells/${cell.id}/sessions`
//     }
//     return (dispatch: any) => {
//         const unsubscribe = firestore.collection(collectionRef)
//             .onSnapshot(snapshot => {
//                 const sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
//                 console.log(sessionsArray)
//                 dispatch(setSessionsData(sessionsArray))
//             }, error => error.message)
//         return () => unsubscribe()
//     }
// }

// export const sessionsDataListener = (currentUser: IUserData, cell: Icell) => {
//     let collectionRef: string;
//     if (currentUser.role === 'admin') {
//         collectionRef = `users/${currentUser.id}/cells/${cell}/sessions`
//     } else if (currentUser.role === 'manager') {
//         collectionRef = `users/${currentUser.adminUID}/cells/${cell}/sessions`
//     }
//     return (dispatch: any) => {
//         const unsubscribe = firestore.collection(collectionRef)
//             .onSnapshot(snapshot => {
//                 const sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
//                 dispatch(setSessionsData(sessionsArray))
//             }, error => {dispatch(setSessionsDataError(error.message))})
//         return () => unsubscribe()
        
//     }
// }

// not quite right
// export const fetchSessionsData = (currentUser: IUserData): Array<Isession> => {
//     let sessionsArray: Array<Isession> = [];
//     firestore.collection(`users/${currentUser.id}/sessions`)
//             .onSnapshot(snapshot => {
//                 sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
//             }, error => error.message)
//     return sessionsArray
// }

// --const listenerUnsubscribeList = []
// export const fetchSessionsDataAsync = (currentUser: any) => {
//     return (dispatch: any) => {
//         dispatch(fetchSessionsDataPending())
        
//         const unsubscribe = firestore.collection(`users/${currentUser.id}/sessions`)
//             .onSnapshot(snapshot => {
//                 const sessionsArray = convertSessionsDataSnapshotToArray(snapshot)
//                 dispatch(fetchSessionsDataSuccess(sessionsArray))
//             }, error => {dispatch(fetchSessionsDataError(error.message))})
//         return () => unsubscribe()
//         --listenerUnsubscribeList.push(unsubscribe)
//     }
// }

export const createSessionDocument = async (currentUser: userData, timer: ITimerSlice) => {
    // const sessionRef = firestore.collection(`users/${currentUser}/sessions`)
    const sessionRef = firestore.collection(`users/${currentUser.adminUID}/cells/${currentUser.id}/sessions`)
    
    // try {
    //     await sessionRef.add({
    //         timestamp: new Date(session.timestamp),
    //         cycleTime: session.cycleTime,
    //         planned: session.planned,
    //         actual: session.actual,
    //         rejected: session.rejected,
    //         downRecords: session.downRecords,
    //         breakRecords: session.breakRecords
    //     })
    // } catch (error) {
    //     console.log('error creating user', error.message)
    // }

    try {
        await sessionRef.add({
            startTime: new Date(timer.shiftStartTime),
            cycleTime: timer.cycleTime,
            planned: timer.plannedParts,
            actual: timer.actualParts,
            rejected: timer.rejectedParts,
            downRecords: timer.downRecords,
            stoppedRecords: timer.stoppedRecords,
        })
    } catch (error: any) {
        console.log('error creating session', error.message)
    }

    return sessionRef;
}

// ----- CELL_LIST -----

export const fetchCellListData = async (currentUserId: string) => {
    const cellListRef = firestore
                        .collection(`users/${currentUserId}/cells`)
                        .doc('cell_list')
    const snapshot = await cellListRef.get()
    if (!snapshot.exists) {
        console.log('No matching documents.')
        return
    }
    const { all_cells, active_users }: any = snapshot.data()
    return { all_cells, active_users }
}


// ----- SUBSCRIPTIONS -----


// export const convertActiveSubsSnapshotToArray = (subscriptions: any): Array<subscriptionData> => {
//     const subscriptionsArray: Array<subscriptionData> = [];
//     subscriptions.docs.forEach((doc: any) => {  // Should be only single sub
//         const subscription = doc.data()
//         subscriptionsArray.push({
//             'id': doc.id,
//             'plan': subscription.role === "premium" ? PlanType.PREMIUM : PlanType.BASIC,
//             'quantity': subscription.quantity,
//             // 'active_users': subscription.active_users ? subscription.active_users : []
//         })
//     })
//     return subscriptionsArray
// }

// export const fetchActiveSubsData = async (currentUserId: string) => {
//     const subscriptionsRef = firestore.collection(`users/${currentUserId}/subscriptions`);
//     const snapshot = await subscriptionsRef
//                             .where('status', 'in', ['trialing', 'active'])
//                             .get()
//     if (snapshot.empty) {
//         console.log('No matching documents.')
//         return []
//     }
//     const subscriptionsArray = convertActiveSubsSnapshotToArray(snapshot)
//     // Sort array so basic plan is first [basic, premium]
//     // const order = [PlanType.BASIC, PlanType.PREMIUM]
//     // subscriptionsArray.sort((a,b) => order.indexOf(a.plan) - order.indexOf(b.plan))
//     return subscriptionsArray
// }

export const getIsSub = async (currentUserId: string) => {
    if (currentUserId === '') return false
    const subscriptionsRef = firestore.collection(`users/${currentUserId}/subscriptions`);
    const snapshot = await subscriptionsRef
                            .where('status', 'in', ['trialing', 'active'])
                            .get()
    if (snapshot.empty) {
        console.log('Not sub')
        return false
    } else {
        console.log('Is sub')
        return true
    }
}

// export const fetchSubUsersByPlan = async (currentUser: userData, plan: PlanType) => {
//     const subUsersArray: Array<string> = []
//     // Determine location in sorted subscriptions array based on plan
//     let subId: string
//     if (plan === PlanType.BASIC) {
//         subId = currentUser.subscriptions[0].id
//     } else {
//         subId = currentUser.subscriptions[1].id
//     }
//     const refString = `users/${currentUser.id}/subscriptions/${subId}/subscription_users`
//     const subUsersRef = firestore.collection(refString)
//     const snapshot = await subUsersRef.get()
//     // add each user id to the array
//     snapshot.docs.forEach((doc: any) => {
//         subUsersArray.push(doc.id)
//     })

//     return subUsersArray
// }


// ----- STRIPE -----

// Copied from Docs, reference blog, and improve
export const fetchSubscriptionProducts = () => {
    firestore.collection('products')
    .where('active', '==', true)
    .get()
    .then(function (querySnapshot) {
        querySnapshot.forEach(async function (doc) {
            console.log(doc.id, ' => ', doc.data());
            const priceSnap = await doc.ref.collection('prices').get();
            priceSnap.docs.forEach((doc) => {
                console.log(doc.id, ' => ', doc.data());
            });
        });
    });
}

export const sendToCustomerPortal = async () => {
    const functionRef = firebase
        .app()
        .functions('us-central1')
        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    window.location.assign(data.url);
}

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const analytics = firebase.analytics()
// Initialize Performance Monitoring and get a reference to the service
export const perf = firebase.performance()

firestore.settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    merge: true
})

// FIXME: not syching across tabs
firestore.enablePersistence({synchronizeTabs: true})
    .catch((err) => {
        if (err.code === 'failed-precondition') {
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
            console.log(err)
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
            console.log(err)
        }
    });
// Subsequent queries will use persistence, if it was enabled successfully

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account'});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase