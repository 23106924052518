import { Box, Button, Flex, FormControl, FormLabel, Heading, Select, useMediaQuery, VStack, Text, FormErrorMessage } from "@chakra-ui/react"
import _ from "lodash"
import { Select as MultiSelect } from "chakra-react-select"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { SelectOptions } from "../../components/admin/CellMultiSelect"
import Loading from "../../components/Loading"
import useConfigCell from "../../hooks/useConfigCell"
import { RootState } from "../../redux/store"
import { startShift } from "../../redux/timerSlice"
import { auth, firestore } from "../../utils/firebase"


const StartShift = () => {
    const { currentUser, userLoaded } = useSelector((state: RootState) => state.user)
    // const { path } = useRouteMatch()
    const dispatch = useDispatch()
    let history = useHistory()

    const [shift, setShift] = useState<string>('')
    // const [numPeople, setNumPeople] = useState<string>('')

    const clear = () => {
        setShift('')
        // setNumPeople('')
    }

    const signout = async () => {
        await auth.signOut()
        history.push('/signin')
    }

    const { loading, configCell, error } = useConfigCell('cell')
    // const { cellDoc } = useCellDoc('cell')

    const currentPeople = useSelector((state: RootState) => state.timer.peopleRecords.slice(-1)[0]?.peopleList)
    const [selectedOptions, setSelectedOptions] = useState<Array<SelectOptions>>()
    const [peopleOptions, setPeopleOptions] = useState<Array<SelectOptions>>()

    const getOptions = () => {
        const options: Array<SelectOptions> = []
        configCell.operators.forEach(o => options.push({
            value: o.code,
            label: o.code,
            colorScheme: 'green'
        }))
        setPeopleOptions(options)
    }

    const getSelectedOptions = () => {
        const options: Array<SelectOptions> = []
        currentPeople?.forEach(person => options.push({
            value: person,
            label: person,
            colorScheme: 'green'
        }))
        setSelectedOptions(options)
    }

    useEffect(() => {
        getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configCell])

    useEffect(() => {
        getSelectedOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPeople, configCell])


    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let inputSize: 'sm' | 'md' | 'lg' = 'sm'
    let spacing: number = 4
    let title: string = '3xl'
    let desc: string = 'lg'
    let bullet: string = 'md'

    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        inputSize = 'sm'
        spacing = 4
        title = '3xl'
        desc = 'lg'
        bullet = 'md'
    }
    if (smH) {  // 480px
        inputSize = 'md'
        spacing = 4
        title = '3xl'
        desc = 'lg'
        bullet = 'md'
    }
    if (mdH) {  // 768px
        inputSize = 'lg'
        spacing = 8
        title = '4xl'
        desc = 'xl'
        bullet = 'lg'
    }
    if (lgH) {  // 992px
        inputSize = 'lg'
        spacing = 12
        title = '5xl'
        desc = '2xl'
        bullet = 'xl'
    }
    if (xlH) {  // 1280px
        inputSize = 'lg'
        spacing = 16
        title = '3xl'
        desc = 'lg'
        bullet = 'md'
    }

    if (loading || !userLoaded) {
        return <Loading />
    } else if (error) {
        // handle this
    }

    return (
        // <Switch>
        //     <Route path={`${path}/:docId`} render={() => <TimerMain />} />
        //     <Route path={`${path}`} render={() =>
        <>
            <Flex
                w={3 / 4}
                h='100vh'
                mx='auto'
                flexDir='column'
                justifyContent='space-evenly'
                alignItems='center'
            >
                <Box alignSelf='flex-end' w={1 / 4}>
                    <Button
                        w='full'
                        h='12vh'
                        colorScheme='teal'
                        variant='outline'
                        border='3px solid'
                        fontSize={['md', 'lg', 'xl', '2xl']}
                        onClick={signout}
                    >
                        Sign out
                    </Button>
                </Box>
                <Heading size='2xl'>
                    {`Welcome ${currentUser?.displayName}`}
                </Heading>
                {(_.isEmpty(configCell.parts) ||
                    // !configCell.shift_names || 
                    !configCell.shift_names.length ||
                    !configCell.down_codes.length ||
                    !configCell.reject_codes.length ||
                    !configCell.operators.length ||
                    !currentUser?.status) ?

                    (!currentUser?.status ?
                        <Flex flexDir='column'>
                            <Text mb={4} fontSize={title}>
                                {'You must be an active cell to use the timer.'}
                            </Text>
                            <Text mb={2} fontSize={desc}>
                                {'Have an admin activate this cell to start a shift'}
                            </Text>
                        </Flex>
                        :
                        // missing cellDoc info
                        <Flex flexDir='column'>
                            <Text mb={4} fontSize={title}>
                                {'Oops! You\'re missing details'}
                            </Text>
                            <Text mb={2} fontSize={desc}>
                                {'Have an admin add the following to start a shift:'}
                            </Text>
                            <Text ml={4} fontSize={bullet}>
                                {' - at least one part'}
                            </Text>
                            <Text ml={4} fontSize={bullet}>
                                {' - at least one shift name'}
                            </Text>
                            <Text ml={4} fontSize={bullet}>
                                {' - at least one down code'}
                            </Text>
                            <Text ml={4} fontSize={bullet}>
                                {' - at least one reject code'}
                            </Text>
                            <Text ml={4} fontSize={bullet}>
                                {' - at least one person'}
                            </Text>
                        </Flex>
                    )
                    :

                    // start shift form
                    <>
                        <VStack spacing={spacing} w='50vw'>
                            <FormControl id='shift' isRequired>
                                <FormLabel>Shift</FormLabel>
                                <Select
                                    isRequired
                                    autoFocus
                                    // onKeyDown={e => {
                                    //     if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    //         e.preventDefault()
                                    //     }
                                    // }}
                                    placeholder='Select Shift'
                                    size={inputSize}
                                    value={shift}
                                    onChange={e => setShift(e.currentTarget.value)}
                                >
                                    {configCell.shift_names.map((shift, index) => {
                                        return <option value={shift.code} key={index}>{shift.code}</option>
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl
                                id='people'
                                isRequired
                                // isInvalid={selectedOptions?.length === 0}
                            >
                                <FormLabel>Select People</FormLabel>
                                    <MultiSelect
                                        isMulti={true}
                                        name="people"
                                        options={peopleOptions}
                                        placeholder="Select people..."
                                        closeMenuOnSelect={false}
                                        blurInputOnSelect={false}
                                        size={inputSize}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        menuPlacement='auto'
                                        value={selectedOptions}
                                        onChange={e => setSelectedOptions(e.map(o => ({ value: o.value, label: o.label, colorScheme: 'green' })))}
                                    />
                                <FormErrorMessage textTransform='initial'>
                                    {'At least one person must be selected'}
                                </FormErrorMessage>
                            </FormControl>
                            {/* <FormControl 
                                        id='num-people' 
                                        isRequired 
                                        isInvalid={!!numPeople && (!Number.isInteger(Number(numPeople)) || Number(numPeople) < 1)}
                                    >
                                        <FormLabel>Number of people</FormLabel>
                                        <NumberInput
                                            min={1}
                                            size={inputSize}
                                        >
                                            <NumberInputField
                                                value={numPeople || ''}
                                                onKeyDown={e => 
                                                    (e.key === 'e' || e.key === '.' || e.key === '+' || e.key === '-') 
                                                    && e.preventDefault()
                                                }
                                                onChange={e => setNumPeople(e.currentTarget.value)}
                                            />
                                        </NumberInput>
                                        <FormErrorMessage textTransform='initial'>
                                            {'No. people must be greater than 0'}
                                        </FormErrorMessage>
                                    </FormControl> */}
                        </VStack>
                        {/* <Box minW={1 / 4}> */}
                            <Button
                                w='30vw'
                                h='10vh'
                                fontSize={['md', 'lg', 'xl', '2xl']}
                                colorScheme='teal'
                                onClick={() => {
                                    const shiftId = firestore.collection(`users/${currentUser.adminUID}/cells/${currentUser.id}/shifts`).doc().id
                                    dispatch(startShift({
                                        shiftName: shift,
                                        peopleList: selectedOptions?.map(o => o.value),
                                        shiftId: shiftId
                                        // setupChecklist: configCell.setup_check_map,
                                        // cleanupChecklist: configCell.cleanup_check_map
                                    }))
                                    // // init checklists
                                    // dispatch(initChecklists({
                                    //     setupChecklist: cellDoc.setup_check_map, 
                                    //     cleanupChecklist: cellDoc.cleanup_check_map
                                    // }))
                                    clear()
                                    history.push(`/timer-main`)
                                }}
                                // isDisabled={!shift || !numPeople || !Number.isInteger(Number(numPeople)) || Number(numPeople) < 1}
                                isDisabled={!shift || selectedOptions?.length === 0}
                            >
                                Start Shift
                            </Button>
                        {/* </Box> */}
                    </>
                }
            </Flex>
        </>
        //     } />
        // </Switch>
    )
}

export default StartShift