import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import Loading from './components/Loading';
import useAuth from './hooks/useAuth';
import Header from './components/navs/Header';
import { ChakraProvider, Flex } from "@chakra-ui/react"
import Routing from './components/routing/Routing';
import AdminNav from './components/navs/AdminNav';




const App = () => {
  // const dispatch = useDispatch();
  const { currentUser, userLoaded, isLoading } = useSelector((state: RootState) => state.user);
  // const [isLoading, setIsLoading] = useState<Boolean>(true)
  const { token } = useAuth()
  // const { subLoading, activeSubDoc, subError } = useActiveSubDoc()
  // const [ ui, setUi] = useState<any>()

  // Track user login
  // useEffect(() => {console.log(currentUser)}, [currentUser])
  // useEffect(() => {console.log(token)}, [token])
  // useEffect(() => {console.log(activeSubDoc, subLoading, subError)}, [activeSubDoc, loading, error])

  // useEffect(() => {
  //   if (!userLoaded) {
  //     setUi(<Loading />)
  //   } else {
  //     if (!currentUser) {
  //       setUi(<NoUser />)
  //     }
  //     if (token) {
  //       if (token.claims.admin) {
  //         setUi(<Admin token={token} />)
  //       } else if (token.claims.cell) {
  //         setUi(<Cell token={token} />)
  //       } else if (token.claims.manager) {
  //         // setUi(<Cell token={token} />)
  //       }
  //     }
  //   }
  // }, [currentUser, token, userLoaded])

  if (!userLoaded || isLoading) {
    return <Loading />
  }

  // if (token) {
  //   if (token.claims.admin)
  //     return <Admin token={token} />
  //   else if (token.claims.cell)
  //     return <Cell token={token} />
  // }

  return (
    // <div>
    //   {ui}
    // </div>
    <ChakraProvider>
      <>
        {/* {!token?.claims.admin ? 
        <Header />
        :
        <AdminNav />
      } */}
        <Flex overflow='hidden' h='100vh' flex={1} flexDir='column' w='full'>
          {!currentUser && <Header />}
          <Flex w='full'>
            {currentUser && token?.claims.admin && <AdminNav />}
            {/* <Flex flex='1' overflow='auto' h='100vh' flexDir='column'> */}
            <Routing currentUser={currentUser} token={token} />
            {/* <Switch> */}
            {/* ADMIN */}
            {/* <PrivateRoute 
            exact 
            path='/subscription' 
            component={Subscription} 
            access={!!token?.claims.admin} 
            // access={!!token?.claims.admin}
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <AdminPrivateRoute
          exact 
          path='/subscription' 
          component={Subscription} 
          access={!!token?.claims.admin} 
          isSub={true}
          toAccess={'admin'}
        />
        <AdminPrivateRoute
          exact 
          path='/manage-cells' 
          component={ManageCells} 
          access={!!token?.claims.admin} 
          isSub={!!currentUser?.subscriptions.length}
          toAccess={'admin'}
        />
        <AdminPrivateRoute 
            exact 
            path='/admin-register' 
            component={AdminRegister} 
            access={!!token?.claims.admin} 
            isSub={!!currentUser?.subscriptions.length}
            toAccess={'admin'}
        /> */}
            {/* <AdminPrivateRoute 
            exact 
            path='/admin-register' 
            component={AdminRegister} 
            access={!!token?.claims.admin}
            isSub={!!token?.claims.stripeRole}
            // isSub={!!activeSubDoc.id}
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <PrivateRoute 
            exact 
            path='/admin-register' 
            component={AdminRegister} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <AdminPrivateRoute 
            // exact 
            path='/manage-cells' 
            component={ManageCells} 
            access={!!token?.claims.admin}
            isSub={!!token?.claims.stripeRole}
            // isSub={!!activeSubDoc.id}
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <PrivateRoute 
            exact 
            path='/manage-cells' 
            component={ManageCells} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <Route exact path='/summary' render={() => <Summary />} /> */}
            {/* <AdminPrivateRoute 
            exact 
            path='/summary' 
            component={Summary} 
            access={!!token?.claims.admin}
            isSub={!!token?.claims.stripeRole}
            // isSub={!!activeSubDoc.id}
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* <PrivateRoute 
            exact 
            path='/summary' 
            component={Summary} 
            access={!!token?.claims.admin && (currentUser?.subscriptions.length)} 
            message={'You must be an admin to visit this page'}
            displayButton={true}
        /> */}
            {/* CELL */}
            {/* <PrivateRoute 
            exact 
            path='/timer' 
            component={TaktTimer} 
            access={!!token?.claims.cell && !!token?.claims.status} 
            message={'You must be an active cell to use the timer'}
            displayButton={false}
        /> */}
            {/* NO USER */}
            {/* <Route 
          exact 
          path='/' 
          render={() => !currentUser ? (
              <Home />
            ) : (
              token?.claims.cell ? (
                <Redirect to='/timer' />
              ) : (
                token?.claims.admin ? (
                    <Redirect to='/manage-cells' />
                ) : (
                  <Redirect to='/summary' /> // Manager
                )
              )
            )
          } 
        />
        <Route 
          exact 
          path='/register' 
          render={() => currentUser ? (
              <Redirect to='/' />
            ) : (
              <Register />
            )
          } 
        />
        <Route 
          exact 
          path='/plans' 
          render={() => currentUser ? (
              <Redirect to='/' />
            ) : (
              <Plans />
            )
          } 
        />
        <Route 
          exact 
          path='/signin' 
          render={() => currentUser ? (
              <Redirect to='/' />
            ) : (
              <Signin />
            )
          } 
        />
        <Route 
          exact 
          path='/forgot-password' 
          render={() => currentUser ? (
              <Redirect to='/' />
            ) : (
              <ForgotPass />
            )
          } 
        />
        <Route path="/404" component={NotFound} />
        <Redirect to="/404" />
      </Switch> */}
          </Flex>
        </Flex>
      </>
    </ChakraProvider>
  )
}

export default App;