import { createSlice } from "@reduxjs/toolkit";

// export enum PlanType {
//     BASIC = "basic",
//     PREMIUM = "premium"
// }

// export type subscriptionsObj = {
//     [PlanType.basic]: subscriptionData,
//     [PlanType.premium]: subscriptionData
// };
// export interface subscriptionData {
//     id: string,
//     plan: PlanType,
//     quantity: number,
//     // active_users: Array<string>,
// }

// export interface allCells {
//     [key: string]: string
// }

export enum RoleType {
    ADMIN = "admin",
    MANAGER = "manager",
    VIEWER = "viewer",
    CELL = 'cell'
}
export interface userData {
    id: string,
    displayName: string,
    email: string,
    role: string,
    createdAt: number,
    // subscriptions: Array<subscriptionData>, // Should only have single plan
    // all_cells: Array<allCells>,
    // active_users: Array<string>,
    company?: string,
    adminUID?: string,
    status?: string
    [key: string]: any;
}

interface IUserSlice {
    currentUser: userData | null,
    isLoading: boolean,
    errorMessage: any,
    userLoaded: boolean
}

const initialState: IUserSlice = {
    currentUser: null,
    isLoading: false,
    errorMessage: null,
    userLoaded: false
}

// export const getActiveSubsThunk = createAsyncThunk('user/fetchSubsData', 
//     async (currentUserId: string) => { 
//         try {
//             const subscriptionsData = await fetchActiveSubsData(currentUserId)
//             return subscriptionsData
//         } catch (error) {
//             // console.log(err)
//             return error
//         }
//     }
// )

// export const getCellListThunk = createAsyncThunk('user/fetchCellList', 
//     async (currentUserId: string) => { 
//         try {
//             const cellListData = await fetchCellListData(currentUserId)
//             // console.log(cellListData)
//             return cellListData
//         } catch (error) {
//             // console.log(err)
//             return error
//         }
//     }
// )

// export interface IgetSubUsers {
//     currentUser: userData,
//     plan: PlanType
// }

// export const getSubUsersThunk = createAsyncThunk('user/fetchSubUsers', 
//     async (data: IgetSubUsers) => { 
//         try {
//             const { currentUser, plan } = data
//             const subUsers = await fetchSubUsersByPlan(currentUser, plan)
//             return { subUsers, plan }
//         } catch (err) {
//             // console.log(err)
//             return err
//         }
//     }
// )

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload
        },
        setUserLoaded: (state, action) => {
            state.userLoaded = action.payload
        },
    },
    extraReducers: (builder) => {
        // getCellListThunk
        // builder.addCase(getCellListThunk.pending, (state, action) => {
        //     state.isLoading = true
        // })
        // builder.addCase(getCellListThunk.fulfilled, (state, { payload }: any) => {
        //     if (state.currentUser) {
        //         // console.log(payload.all_cells)
        //         state.currentUser.all_cells = payload.all_cells
        //         state.currentUser.active_users = payload.active_users
        //     }
        //     state.isLoading = false
        //     state.errorMessage = null
        // })
        // builder.addCase(getCellListThunk.rejected, (state, action) => {
        //     state.isLoading = false
        //     state.errorMessage = action.error.message
        // })
        // // getActiveSubsThunk
        // builder.addCase(getActiveSubsThunk.pending, (state, action) => {
        //     state.isLoading = true
        // })
        // builder.addCase(getActiveSubsThunk.fulfilled, (state, { payload }: any) => {
        //     if (state.currentUser) {
        //         state.currentUser.subscriptions = payload
        //     }
        //     state.isLoading = false
        //     state.errorMessage = null
        // })
        // builder.addCase(getActiveSubsThunk.rejected, (state, action) => {
        //     state.isLoading = false
        //     state.errorMessage = action.error.message
        // })
        // getSubUsersThunk
        // builder.addCase(getSubUsersThunk.pending, (state, action) => {
        //     state.isLoading = true
        // })
        // builder.addCase(getSubUsersThunk.fulfilled, (state, { payload }) => {
        //     const { subUsers, plan } = payload
        //     if (plan === PlanType.BASIC) {
        //         state.currentUser.subscriptions[0].active_users = subUsers
        //     } else if (plan === PlanType.PREMIUM) {
        //         state.currentUser.subscriptions[1].active_users = subUsers
        //     }
        //     state.isLoading = false
        //     state.errorMessage = null
        // })
        // builder.addCase(getSubUsersThunk.rejected, (state, action) => {
        //     state.isLoading = false
        //     state.errorMessage = action.error.message
        // })
    }
})

// -- ASYNC EXAMPLE --
// const asyncNextFlashCard = state => {
//     return async dispatch => {
//         if (state.current < state.cards.legnth - 1) {
//             if (state.flipped) flashCards.caseReducers.flipFlashCard(state) // call a caseReducer from within a caseReducer
//             setTimeout(state => state.current++, 1000) // Mutative code is possible thanks to immer running under the hood
//         }
//     }
// }

export const {
    setCurrentUser,
    setUserLoaded,
} = user.actions
export default user.reducer