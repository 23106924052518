import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { firestore } from "../utils/firebase"

export enum PlanType {
    BASIC = "basic",
    PREMIUM = "premium"
}

interface Data {
    subError: Error | null;
    subLoading: boolean;
    activeSubDoc: ActiveSubDoc;
}

interface ActiveSubDoc {
    id: string,
    plan: PlanType | undefined,
    quantity: number
}

const initData: Data = {
    subError: null,
    subLoading: true,
    activeSubDoc: {
        id: "",
        plan: undefined,
        quantity: 0
    }
}

const useActiveSubDoc = () => {
    const [data, setData] = useState<Data>(initData)
    const { currentUser } = useSelector((state: RootState) => state.user)
    // OR pass currentUserId as prop

    useEffect(() => {
        const unsubscribe = firestore
            .collection(`users/${currentUser?.id}/subscriptions`)
            .where('status', 'in', ['trialing', 'active'])
            .onSnapshot(snapshot => {
                if (snapshot.size > 1) {
                    setData({
                        subError: new Error("Too many active subscriptions"),
                        subLoading: false,
                        activeSubDoc: {
                            id: "",
                            plan: undefined,
                            quantity: 0
                        }
                    })
                } else {
                    snapshot.forEach(doc => {
                        const { role, quantity }: any = doc.data()
                        setData({
                            subError: null,
                            subLoading: false,
                            activeSubDoc: {
                                id: doc.id,
                                plan: role === "premium" ? PlanType.PREMIUM : PlanType.BASIC,
                                quantity: quantity
                            }
                        })
                    })
                }
            },
                (error) => {
                    setData({
                        subError: error,
                        subLoading: false,
                        activeSubDoc: {
                            id: "",
                            plan: undefined,
                            quantity: 0
                        }
                    })
                }
            )

        return unsubscribe
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data
}

export default useActiveSubDoc