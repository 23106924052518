import { Button, Text } from "@chakra-ui/react"
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/modal"
import { useState, useRef } from "react"

interface IDeleteCell {
    deleteCell: () => void
}

const DeleteCell = ({ deleteCell }: IDeleteCell) => {
    const DeleteCellAlert = () => {
        const [isOpen, setIsOpen] = useState<boolean>(false)
        const onClose = () => setIsOpen(false)
        const cancelRef = useRef<any>()
    
        return (
            <>
                <Button 
                    colorScheme="red" 
                    size='sm' 
                    onClick={() => setIsOpen(true)}
                >
                    Delete Account
                </Button>
    
                <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                Delete Cell Account
                            </AlertDialogHeader>
    
                            <AlertDialogBody>
                                Are you sure? All data for this cell will be deleted. You can't undo this action afterwards.
                            </AlertDialogBody>
    
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button 
                                    colorScheme="red" 
                                    onClick={() => {onClose(); deleteCell()}} 
                                    ml={3}
                                >
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </>
        )
    }

    return (
        <>
            <Text fontWeight='bold'>
                Delete cell account and data
            </Text>
            <Text size='sm' mt={1} mb={3} >
                Once you delete this cell, there is no going back. Please be certain.
            </Text>
            <DeleteCellAlert />
        </>
    )
}

export default DeleteCell