import { createSlice } from "@reduxjs/toolkit";

export interface PartData {
    startTime: number,
    endTime: number,
    downIdxs: Array<number>
    stoppedIdxs: Array<number>
    totalChange: Array<number>
    rejectIdx: number | null
    removed: number | null
}

export interface RejectData {
    part: string,
    code: string,
    note: string,
}

export interface DownData {
    startTime: number,
    code: string,
    note: string,
    duration: number,
    numParts: number,
    stoppedIdxs: Array<number>,  // already removed from duration
}

export interface PeopleData {
    peopleList: Array<string>,
    startTime: number,
    endTime: number,
    // numPeople: number,
}
export interface StoppedData {
    reason: 'setup' | 'cleanup' | 'break' | 'zero people',
    startTime: number,
    duration: number,
}

export interface BatchData {
    startTime: number,
    prevPart: string,
    endTime: number,
    workOrder: string,
    goalParts: number,
    part: string,
    numPeople: number,
    cycleTime: number,
    plan: number,
    actual: number,
    rejects: number,
    downIdxs: Array<number>,
    stoppedIdxs: Array<number>,
    peopleIdxs: Array<number>,
    partIdxs: Array<number>,
    totalChange: number,  // seconds
    // runTime: number, // ((endTime - startTime) * 1000) - totalBreak
    // downPartsMissed = downIdxs / cycleTime
}

export interface HourDownChange {
    duration: number,
    numParts: number
}

export interface HourData {
    startTime: number,
    endTime: number,
    plan: number,
    actual: number,
    rejects: number,
    totalStopped: Array<number>,  // seconds
    totalDown: Array<HourDownChange>,  // seconds
    totalChange: Array<HourDownChange>,
    fstBatchIdx: number,  // index of the batch at the start of the hour
    fstBatchPlan: number,  // planned parts of fst batch at change of hour
}

export interface CheckTask {
    [taskName: string]: number | null
}
export interface ChecklistData {
    [index: string]: CheckTask
}

export interface ITimerSlice {
    shiftId: string,
    shiftName: string,
    shiftNotes: string,
    shiftStartTime: number,
    shiftEndTime: number,
    isDown: boolean,
    isChange: boolean,
    isStopped: boolean,
    isEditingRedux: boolean,
    displayType: 'shift' | 'batch' | 'hour',
    cycleTime: number,
    displayTime: number,
    plannedParts: number,
    actualParts: number,
    rejectedParts: number,
    partRecords: Array<PartData>,
    peopleRecords: Array<PeopleData>,
    rejectRecords: Array<RejectData>,
    stoppedRecords: Array<StoppedData>,
    downRecords: Array<DownData>,
    batchRecords: Array<BatchData>,
    hourRecords: Array<HourData>,
    prevHourStopped: number,
    prevHourDown: HourDownChange,
    prevHourChange: HourDownChange,
    // setupChecklist: ChecklistData,
    // cleanupChecklist: ChecklistData,
};

const initialState: ITimerSlice = {
    shiftId: '',
    shiftName: '',
    shiftNotes: '',
    shiftStartTime: 0,
    shiftEndTime: 0,
    isDown: false,
    isChange: false,
    isStopped: true,
    isEditingRedux: false,
    displayType: 'shift',
    cycleTime: 0,
    displayTime: 0,
    plannedParts: 0,
    actualParts: 0,
    rejectedParts: 0,
    partRecords: [],
    rejectRecords: [],
    peopleRecords: [],
    stoppedRecords: [],
    downRecords: [],
    batchRecords: [],
    hourRecords: [],
    prevHourStopped: 0,
    prevHourDown: { duration: 0, numParts: 0 },
    prevHourChange: { duration: 0, numParts: 0 },
    // setupChecklist: {},
    // cleanupChecklist: {},
}

const timer = createSlice({
    name: 'timer',
    initialState,
    reducers: {
        resetTimerSlice: () => {
            return initialState
        },
        // initChecklists: (state, action: {
        //     payload: {
        //         setupChecklist: ChecklistMap,
        //         cleanupChecklist: ChecklistMap,
        //     }; 
        //     type: string;
        // }) => {
        //     const { setupChecklist, cleanupChecklist } = action.payload
        //     Object.keys(setupChecklist).forEach(key => {
        //         const task: string = setupChecklist[key]
        //         state.setupChecklist[key] = { [task]: null } 
        //     })
        //     Object.keys(cleanupChecklist).forEach(key => {
        //         const task: string = cleanupChecklist[key]
        //         state.cleanupChecklist[key] = { [task]: null } 
        //     })
        // },
        // TIMERS
        // setCycleTime: (state, action) => {
        //     const { cycleTime } = action.payload
        //     state.cycleTime = cycleTime
        // },
        // decDisplayTime: state => {
        //     state.displayTime--
        // },
        // resetDisplayTime: state => {
        //     state.displayTime = state.cycleTime
        // },
        // incDownTime: state => {
        //     // state.downTime++
        //     state.downRecords.slice(-1)[0].duration++
        //     state.downRecords.slice(-1)[0].numParts += 1 / state.batchRecords.slice(-1)[0].cycleTime
        //     // state.batchRecords.slice(-1)[0].downIdxs++
        //     // state.hourRecords.slice(-1)[0].downIdxs++
        //     // state.hourRecords.slice(-1)[0].downPartsMissed += 1 / state.batchRecords.slice(-1)[0].cycleTime
        // },
        // incChangeTime: state => {
        //     // state.changeTime++
        //     // state.batchRecords.slice(-1)[0].totalChange++
        //     // state.hourRecords.slice(-1)[0].totalChange++
        // },
        // incStoppedTime: state => {
        //     // state.stoppedTime++
        //     state.stoppedRecords.slice(-1)[0].duration++
        //     if (state.batchRecords.slice(-1)[0]) {
        //         // state.batchRecords.slice(-1)[0].stoppedIdxs++
        //     }
        //     // state.hourRecords.slice(-1)[0].stoppedIdxs++
        // },
        // NEW TIMER
        setStopped: (state, action) => {
            const currentTime = action.payload
            // const currentBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentStop = state.stoppedRecords.slice(-1)[0]
            // const currentPart = state.partRecords.slice(-1)[0]

            const stoppedTime = Math.floor((currentTime - currentStop.startTime) / 1000)
            
            // stopped record
            currentStop.duration = stoppedTime
            // batch record
            // if (!!state.batchRecords.length) {
            //     currentBatch.stoppedIdxs[currentBatch.stoppedIdxs.length - 1] = stoppedTime
            //     // part record
            //     currentPart.stoppedIdxs[currentPart.stoppedIdxs.length - 1] = stoppedTime
            // }
            // hour record
            currentHour.totalStopped[currentHour.totalStopped.length - 1] = stoppedTime - state.prevHourStopped

            // down record
            // if (state.isDown) {
            //     const currentDown = state.downRecords.slice(-1)[0]
            //     currentDown.stoppedIdxs[currentDown.stoppedIdxs.length - 1] = stoppedTime
            // }
        },
        setChange: (state, action) => {
            const currentTime = action.payload
            const currentBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentPart = state.partRecords.slice(-1)[0]

            const elapsed = Math.floor(((currentTime - currentBatch.startTime) / 1000)) 
            const changeTime = elapsed - currentBatch.stoppedIdxs.reduce((acc, idx) => acc + state.stoppedRecords[idx].duration, 0) 
            
            // batch record
            currentBatch.totalChange = changeTime
            // hour record - set numParts at end of change
            const currentChangeInHour = currentHour.totalChange[currentHour.totalChange.length - 1]
            currentChangeInHour.duration = changeTime - state.prevHourChange.duration
            // currentChangeInHour.numParts = Math.round((((changeTime - state.prevHourChange.numParts) / currentBatch.cycleTime) * 10)) / 10
            // currentHour.totalChange[currentHour.totalChange.length - 1] = {
            //     duration: changeTime - state.prevHourChange.duration,
            //     numParts: Math.round((((changeTime / currentBatch.cycleTime) - state.prevHourChange.numParts) * 10)) / 10
            // }
            // part record
            currentPart.totalChange[currentPart.totalChange.length - 1] = changeTime
        },
        setTakt: (state, action) => {
            const currentTime = action.payload
            const currentBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentPart = state.partRecords.slice(-1)[0]

            const batchElapsed = Math.floor(((currentTime - currentBatch.startTime) / 1000)) 
            // const hourElapsed = Math.floor(((currentTime - currentHour.startTime) / 1000))
            const batchRunTime = batchElapsed - currentBatch.stoppedIdxs.reduce((acc, idx) => acc + state.stoppedRecords[idx].duration, 0)
            // const hourRunTime = hourElapsed - currentHour.stoppedIdxs.reduce((acc, num) => acc + num, 0)

            // display time
            // state.displayTime = currentBatch.cycleTime - (batchRunTime % currentBatch.cycleTime)
            if (!state.isDown) {
                state.displayTime = Math.floor((currentTime - currentPart.startTime) / 1000)
                    - currentPart.downIdxs.reduce((acc, idx) => acc + state.downRecords[idx].duration, 0)
                    - currentPart.totalChange.reduce((acc, num) => acc + num, 0)
                    - currentPart.stoppedIdxs.reduce((acc, idx) => acc + state.stoppedRecords[idx].duration, 0)
            }
            // if (state.isDown) {
            //     state.displayTime = state.displayTime - 1
            // }
            
            // batch
            currentBatch.plan = Number((batchRunTime / currentBatch.cycleTime).toFixed(3))
            // hour
            const fstBHourPlan = state.batchRecords[currentHour.fstBatchIdx].plan - currentHour.fstBatchPlan
            const restBHourPlan = state.batchRecords.slice(currentHour.fstBatchIdx + 1).reduce((acc, obj) => acc + obj.plan, 0)
            currentHour.plan = fstBHourPlan + restBHourPlan
            // shift
            state.plannedParts = state.batchRecords.reduce((acc, obj) => acc + obj.plan, 0)
        },
        setDown: (state, action) => {
            const currentTime = action.payload
            const currentBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentDown = state.downRecords.slice(-1)[0]
            // const currentPart = state.partRecords.slice(-1)[0]
            
            const elapsed = Math.floor(((currentTime - currentDown.startTime) / 1000)) 
            const downTime = elapsed - currentDown.stoppedIdxs.reduce((acc, idx) => acc + state.stoppedRecords[idx].duration, 0)

            // down record
            currentDown.duration = downTime < 0 ? 0 : downTime
            currentDown.numParts = Number((currentDown.duration / currentBatch.cycleTime).toFixed(1))
            // batch record
            // currentBatch.downIdxs[currentBatch.downIdxs.length - 1] = downTime < 0 ? 0 : downTime
            // hour record
            const currentDownInHour = currentHour.totalDown[currentHour.totalDown.length - 1]
            currentDownInHour.duration = downTime < 0 ? 0 : downTime - state.prevHourDown.duration
            currentDownInHour.numParts = downTime < 0 ? 0 : Math.round((((downTime - state.prevHourDown.duration) / currentBatch.cycleTime) * 10)) / 10
            // currentHour.totalDown[currentHour.totalDown.length - 1] = downTime < 0 ? 0 : downTime - state.prevHourDown
            // part record
            // fast down clicking returns downTime of -1
            // currentPart.downIdxs[currentPart.downIdxs.length - 1] = downTime < 0 ? 0 : downTime
        },

        // COUNTERS
        // incPlannedParts: state => {
        //     state.plannedParts++
        //     state.batchRecords.slice(-1)[0].plan++
        //     state.hourRecords.slice(-1)[0].plan++
        // },
        incActual: state => {
            const currentTime = new Date().getTime()

            state.actualParts++
            state.batchRecords.slice(-1)[0].actual++
            state.hourRecords.slice(-1)[0].actual++
            // update parts record
            state.partRecords.slice(-1)[0].endTime = currentTime
            state.partRecords.push({
                startTime: currentTime,
                endTime: 0,
                downIdxs: [],
                totalChange: [],
                stoppedIdxs: [],
                rejectIdx: null,
                removed: null
            })
            state.batchRecords.slice(-1)[0].partIdxs.push(state.partRecords.length - 1)
        },
        decActual: state => {
            state.actualParts--
            state.batchRecords.slice(-1)[0].actual--
            state.hourRecords.slice(-1)[0].actual--
            // remove most recent good part than isn't already removed
            const currentTime = new Date().getTime()
            for (let i = state.partRecords.length - 2; i >= 0; i--) {
                const record = state.partRecords[i]
                if (!record.removed && !record.rejectIdx) {
                    record.removed = currentTime
                    break
                }
            }
        },
        incRejected: state => {
            const currentTime = new Date().getTime()

            state.rejectedParts++
            state.batchRecords.slice(-1)[0].rejects++
            state.hourRecords.slice(-1)[0].rejects++
            state.rejectRecords.push({
                part: state.batchRecords.slice(-1)[0].part,
                code: '',
                note: ''
            })
            // update parts records
            state.partRecords.slice(-1)[0].endTime = new Date().getTime()
            state.partRecords.slice(-1)[0].rejectIdx = state.rejectRecords.length - 1
            state.partRecords.push({
                startTime: currentTime,
                endTime: 0,
                downIdxs: [],
                totalChange: [],
                stoppedIdxs: [],
                rejectIdx: null,
                removed: null
            })
            state.batchRecords.slice(-1)[0].partIdxs.push(state.partRecords.length - 1)
        },

        // CLICK ACTIONS
        startShift: (state, action) => {
            const { shiftName, peopleList, shiftId } = action.payload
            state.shiftId = shiftId
            state.shiftStartTime = new Date().getTime()
            state.shiftName = shiftName
            timer.caseReducers.setPeople(state, {
                type: 'setPeople',
                payload: { peopleList }
            })
            state.displayTime = state.cycleTime
            timer.caseReducers.changeHour(state)
            // TODO: write to DB
            // create shiftDoc
            // write currentShift on cellDoc
            // return docId? or just read it from cellDoc

            // begin setup
            timer.caseReducers.beginStopped(state, {
                type: 'stoppedReason',
                payload: 'setup'
            })
            
            // timer.caseReducers.initChecklists(state, {
            //     type: 'initChecklists',
            //     payload: {
            //         setupChecklist,
            //         cleanupChecklist
            //     }
            // })
        },
        endSetup: (state, action) => {
            const { workOrder, goalParts, nextPart: part, numPeople, cycleTime } = action.payload
            state.isStopped = false
            // state.setupChecklist = newSetupChecklist
            const currentTime = new Date().getTime()
            const currentPeopleIdx = state.peopleRecords.length - 1
            // starting batch
            state.batchRecords.push({
                startTime: currentTime,
                prevPart: '',
                endTime: 0,
                workOrder,
                goalParts,
                part,
                numPeople,
                cycleTime,
                plan: 0,
                actual: 0,
                rejects: 0,
                downIdxs: [],
                stoppedIdxs: [],
                peopleIdxs: [currentPeopleIdx],
                partIdxs: [0],
                totalChange: 0,
            })
            state.partRecords.push({
                startTime: currentTime,
                endTime: 0,
                downIdxs: [],
                totalChange: [],
                stoppedIdxs: [],
                rejectIdx: null,
                removed: null
            })
            state.cycleTime = cycleTime
            // state.displayTime = cycleTime
        },
        startCleanUp: state => {
            // end batch
            state.batchRecords.slice(-1)[0].endTime = new Date().getTime()
            // last stopped record
            timer.caseReducers.beginStopped(state, {
                type: 'stoppedReason',
                payload: 'cleanup'
            })
        },
        endShift: (state) => {
            // const { newCleanupChecklist } = action.payload
            // state.cleanupChecklist = newCleanupChecklist
            const timestamp = new Date().getTime()
            state.shiftEndTime = timestamp
            state.peopleRecords.slice(-1)[0].endTime = timestamp
            state.hourRecords.slice(-1)[0].endTime = timestamp
            // end cleanup
            // timer.caseReducers.endStopped(state)

            // timer.caseReducers.endCleanup(state)
            // stop everything - ?? !shiftEndTime ??
            // TODO: write to DB
            // last shiftDoc update
            // cellDoc - last_shift_notes && currentShift = ''
            // show summary
        },
        setShiftName: (state, action) => {
            state.shiftName = action.payload
        },
        setShiftNotes: (state, action) => {
            const { note } = action.payload
            state.shiftNotes = note
        },
        setIsEditingRedux: (state, action) => {
            state.isEditingRedux = action.payload
        },
        changeDisplayType: (state, action) => {
            state.displayType = action.payload
        },

        // PEOPLE
        setPeople: (state, action) => {
            // const { numPeople } = action.payload
            const { peopleList } = action.payload
            const timestamp = new Date().getTime()

            // First record - happens before starting setup
            if (!state.peopleRecords.length) {
                state.peopleRecords.push({
                    startTime: timestamp,
                    endTime: 0,
                    peopleList
                })
                return
            }
            
            // Zero people => stopped time
            if (peopleList.length === 0 && !state.isStopped) {
                timer.caseReducers.beginStopped(state, {
                    type: 'stoppedReason',
                    payload: 'zero people'
                })
            } else if (peopleList.length !== 0 && state.isStopped) {
                timer.caseReducers.endStopped(state)
            }

            // only add new record if > 10s since last change
            if (timestamp - state.peopleRecords.slice(-1)[0].startTime > 10000) {
                // update last end time
                state.peopleRecords.slice(-1)[0].endTime = timestamp
                // add new record
                state.peopleRecords.push({
                    startTime: timestamp,
                    endTime: 0,
                    peopleList
                })
                // add new idx to batch
                const currentBatch = state.batchRecords.slice(-1)[0]
                const newPeopleIdx = state.peopleRecords.length - 1
                currentBatch.peopleIdxs.push(newPeopleIdx)
            } else {  // update last entry
                state.peopleRecords.slice(-1)[0].peopleList = peopleList
            }
        },

        // REJECTS
        setRejectCode: (state, action) => {
            const { index, code } = action.payload
            state.rejectRecords[index].code = code
        },
        setRejectNote: (state, action) => {
            const { index, note } = action.payload
            state.rejectRecords[index].note = note
        },

        // DOWN TIME
        // single function for key binding (alternative - conditional keybinding)
        startStopDownTime: state => {
            if (state.isDown) {  // end down
                state.isDown = false
                // clear prevHourDown if exists (only exists if down during hour change)
                if (state.prevHourDown.duration !== 0) {
                    state.prevHourDown = { duration: 0, numParts: 0 }
                }
                // // add down data
                // state.downRecords.slice(-1)[0].duration = state.downTime
                // state.downRecords.slice(-1)[0].numParts = state.downTime / state.cycleTime
                // // update batch
                // state.batchRecords.slice(-1)[0].downIdxs += state.downTime
                // // reset
                // state.downTime = 0
            } else {  // start down
                state.isDown = true
                const currentBatch = state.batchRecords.slice(-1)[0]
                const currentHour = state.hourRecords.slice(-1)[0]
                const currentPart = state.partRecords.slice(-1)[0]

                state.downRecords.push({
                    startTime: new Date().getTime(),
                    code: '',
                    note: '',
                    duration: 0,
                    numParts: 0,
                    stoppedIdxs: [],
                })

                const newDownIdx = state.downRecords.length - 1
                currentBatch.downIdxs.push(newDownIdx)
                currentHour.totalDown.push({ duration: 0, numParts: 0 })
                currentPart.downIdxs.push(newDownIdx)
            }
        },
        setDownCode: (state, action) => {
            const { index, code } = action.payload
            state.downRecords[index].code = code
        },
        setDownNote: (state, action) => {
            const { index, note } = action.payload
            state.downRecords[index].note = note
        },

        // STOPPED
        beginStopped: (state, action) => {
            const reason = action.payload
            state.isStopped = true
            const currentBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentPart = state.partRecords.slice(-1)[0]
        
            state.stoppedRecords.push({
                reason,
                startTime: new Date().getTime(),
                duration: 0,
            })
            const newStoppedIdx = state.stoppedRecords.length - 1
            if (!!state.batchRecords.length) {
                currentBatch.stoppedIdxs.push(newStoppedIdx)
                currentPart.stoppedIdxs.push(newStoppedIdx)
            }
            currentHour.totalStopped.push(0)

            // Add stop to down record
            if (state.isDown) {
                const currentDown = state.downRecords.slice(-1)[0]
                currentDown.stoppedIdxs.push(newStoppedIdx)
            }
        },
        endStopped: state => {
            state.isStopped = false
            if (state.prevHourStopped) {
                state.prevHourStopped = 0
            }
            // add break data
            // state.stoppedRecords.slice(-1)[0].duration = state.stoppedTime
            // state.batchRecords.slice(-1)[0].stoppedIdxs += state.stoppedTime
            // state.stoppedTime = 0
        },

        // BATCH
        changeBatch: state => {
            state.isChange = true
            const lastBatch = state.batchRecords.slice(-1)[0]
            const currentHour = state.hourRecords.slice(-1)[0]
            const currentPart = state.partRecords.slice(-1)[0]
            const currentPeopleIdx = state.peopleRecords.length - 1
            const currentPartIdx = state.partRecords.length - 1
            // end batch
            lastBatch.endTime = new Date().getTime()
            // start new batch
            state.batchRecords.push({
                startTime: new Date().getTime(),
                prevPart: lastBatch.part,
                endTime: 0,
                workOrder: '',
                goalParts: 0,
                part: '',
                numPeople: 0,
                cycleTime: 0,
                plan: 0,
                actual: 0,
                rejects: 0,
                downIdxs: [],
                stoppedIdxs: [],
                peopleIdxs: [currentPeopleIdx],
                partIdxs: [currentPartIdx],
                totalChange: 0,
            })
            currentHour.totalChange.push({ duration: 0, numParts: 0 })
            currentPart.totalChange.push(0)
        },
        runBatch: (state, action) => {  // end changeover
            const { workOrder, goalParts, nextPart, numPeople, cycleTime } = action.payload
            const currentBatch = state.batchRecords.slice(-1)[0]
            // end change
            state.isChange = false
            // update hourChange numParts
            const currentChangeHour = state.hourRecords.slice(-1)[0].totalChange.slice(-1)[0]
            currentChangeHour.numParts = Number(((currentChangeHour.duration - state.prevHourChange.numParts) / cycleTime).toFixed(1))
            // clear prevHourDown if exists (only exists if down during hour change)
            if (state.prevHourChange.duration !== 0) {
                state.prevHourChange = { duration: 0, numParts: 0 }
            }
            // currentBatch.totalChange = state.changeTime
            // state.changeTime = 0

            // update batch records
            currentBatch.workOrder = workOrder
            currentBatch.goalParts = goalParts
            currentBatch.part = nextPart
            currentBatch.numPeople = numPeople
            currentBatch.cycleTime = cycleTime
            // update plan based off change time
            currentBatch.plan = Math.floor(currentBatch.totalChange / cycleTime)
            state.plannedParts += Math.floor(currentBatch.totalChange / cycleTime)

            // set timer
            state.cycleTime = cycleTime
            state.displayTime = cycleTime - (currentBatch.totalChange % cycleTime)
        },

        // HOUR
        changeHour: state => {
            const now = new Date().getTime()
            console.log('change hour', now)
            const prevHour = state.hourRecords.slice(-1)[0]

            let totalStopped: Array<number> = []
            let totalDown:    Array<HourDownChange> = []
            let totalChange:  Array<HourDownChange> = []
            let fstBatchIdx: number = 0
            let fstBatchPlan: number = 0

            // end hour
            if (state.hourRecords.length) {
                prevHour.endTime = now
                fstBatchIdx = state.batchRecords.length - 1
                fstBatchPlan = state.batchRecords.slice(-1)[0].plan
                // Save last record of category, if currently in that state
                // The prevHourState category is emptied after ending each state - endStopped, startStopDown, runBatch
                if (state.isStopped) {
                    totalStopped = [0]
                    state.prevHourStopped = prevHour.totalStopped.slice(-1)[0]
                }
                if (state.isDown) {
                    totalDown = [{ duration: 0, numParts: 0 }]
                    state.prevHourDown = prevHour.totalDown.slice(-1)[0]
                }
                if (state.isChange) {
                    totalChange = [{ duration: 0, numParts: 0 }]
                    state.prevHourChange = prevHour.totalChange.slice(-1)[0]
                }
            }

            // start hour
            state.hourRecords.push({
                startTime: now,
                endTime: 0,
                plan: 0,
                actual: 0,
                rejects: 0,
                totalStopped: totalStopped,  // to update if changes hour during break
                totalDown: totalDown,     // to update if changes hour during down
                totalChange: totalChange,   // to update if changes hour during change
                fstBatchIdx: fstBatchIdx,
                fstBatchPlan: fstBatchPlan
            })
            // TODO: write to DB
        },
    }
    // -- ASYNC EXAMPLE --
    // const asyncNextFlashCard = state => {
    //     return async dispatch => {
    //         if (state.current < state.cards.legnth - 1) {
    //             if (state.flipped) flashCards.caseReducers.flipFlashCard(state) // call a caseReducer from within a caseReducer
    //             setTimeout(state => state.current++, 1000) // Mutative code is possible thanks to immer running under the hood
    //         }
    //     }
    // }
})

export const {
    resetTimerSlice,
    // initChecklists,
    // TIMERS
    setStopped,
    setChange,
    setTakt,
    setDown,
    // COUNTERS
    incActual,
    decActual,
    incRejected,
    // CLICK ACTIONS
    startShift,
    endSetup,
    startCleanUp,
    endShift,
    setShiftNotes,
    setIsEditingRedux,
    changeDisplayType,
    // NUM PEOPLE
    setPeople,
    // REJECTS
    setRejectCode,
    setRejectNote,
    // DOWN
    startStopDownTime,
    setDownCode,
    setDownNote,
    // STOPPED
    beginStopped,
    endStopped,
    // BATCH
    changeBatch,
    runBatch,
    // HOUR
    changeHour,
} = timer.actions
export default timer.reducer