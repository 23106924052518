import _ from "lodash"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { formatSeconds, formatSecondsToMinutes } from "../utils"
import useCurrentTime from "./useCurrentTime"
import { ShiftData } from "./useShifts"
import dayjs from "dayjs"

export interface OLEData {
    name: string
    parts?: string | number
    min?: string | number
}

export interface RejectReasonsData {
    name: string,
    count: number
}

export interface DownReasonsData {
    name: string,
    duration: number,
    missingParts: number
}

export interface DayThroughputData {
    // name: string,
    day: string,
    xLabel: string,
    plan: number,
    actual: number,
    gap: number,
    dispQual: number,
    dispPacing: number,
    dispAvail: number,
    rejects: number,
    pacingPartsMissed: number,
    availPartsMissed: number,
    downPartsMissed: number,
    changePartsMissed: number,
    downTime: string,
    changeTime: string,
    totalTime: string,
    stoppedTime: string,
    availTime: string,
    runTime: string,
}

export interface HourThroughputData {
    xLabel: string,
    plan: number,
    actual: number,
    gap: number,
    dispQual: number,
    dispPacing: number,
    dispAvail: number,
    rejects: number,
    pacingPartsMissed: number,
    availPartsMissed: number,
    downPartsMissed: number,
    changePartsMissed: number,
    downTime: string,
    changeTime: string,
    totalTime: string,
    stoppedTime: string,
    availTime: string,
    runTime: string,
}

export const getShiftsByDay = (shifts: Array<ShiftData>, offset: number) => {
    // const offset = 0 // set by admin - 10pm = 2 offset
    return _.groupBy(shifts, shift => dayjs(shift.shiftStartTime).add(offset, 'hour').startOf('day').valueOf())
}

export const getDaysArray = (startTime: number, endTime: number, offset: number): Array<number> => {
    if (startTime > endTime) return []
    
    const daysArray: Array<number> = []
    for (let i = new Date(startTime); i <= new Date(endTime); i.setDate(i.getDate() + 1)) {
        daysArray.push(dayjs(i).startOf('day').add(offset, 'hour').valueOf())
    }
    return daysArray

    // const startTimeDay: number = dayjs(startTime).startOf('day').add(offset, 'hour').valueOf()
    // const endTimeDay: number = dayjs(endTime).startOf('day').add(offset, 'hour').valueOf()
    // if (startTimeDay > endTimeDay) return []
    
    // for (let i = startTimeDay; i <= endTimeDay; i += (24 * 60 * 60 * 1000)) {
    //     daysArray.push(i)
    // }
}

// // Groups array of shifts by day of week
// export const getShiftsByDayOfWeek = (shifts: Array<ShiftData>) => {
//     return _.groupBy(shifts, shift => new Date(shift.shiftStartTime).getDay())
//     // const offset = 0 // set by admin - 10pm = 2 offset
//     // return _.groupBy(shifts, shift => dayjs(shift.shiftStartTime).add(offset, 'hour').startOf('day'))
// }

// // Groups array of shifts by day of month
// export const getShiftsByDayOfMonth = (shifts: Array<ShiftData>) => {
//     return _.groupBy(shifts, shift => new Date(shift.shiftStartTime).getDate())
// }

// export const getDaysArray = (unit: 'week' | 'month', monthLength: number): Array<number> => {
//     return unit === 'week' ? [1, 2, 3, 4, 5, 6, 0] : Array.from(Array(monthLength).keys())
// }

// Sun 12AM - //TODO: add hours to start monday (or sunday night)
export const getStartOfTimeUnit = (timestamp: number, unit: 'week' | 'month', offset: number): number => {
    // const offset = 0
    const startWeek = dayjs(timestamp).startOf(unit).add(24 + offset, 'hour').valueOf()
    const startMonth = dayjs(timestamp).startOf(unit).add(offset, 'hour').valueOf()
    return unit === 'week' ? startWeek : startMonth
}

export const getEndOfTimeUnit = (timestamp: number, unit: 'week' | 'month', offset: number): number => {
    // const offset = 0
    const endWeek = dayjs(timestamp).endOf(unit).add(24 + offset, 'hour').valueOf()
    const endMonth = dayjs(timestamp).endOf(unit).add(offset, 'hour').valueOf()
    return unit === 'week' ? endWeek : endMonth
}

export const getLastTimeUnit = (timestamp: number, unit: 'week' | 'month'): number => {
    return dayjs(timestamp).subtract(1, unit).valueOf()
}

const useCalcs = () => {
    const actual = useSelector((state: RootState) => state.timer.actualParts)
    const plan = useSelector((state: RootState) => state.timer.plannedParts)
    const rejects = useSelector((state: RootState) => state.timer.rejectedParts)

    const shiftStartTime = useSelector((state: RootState) => state.timer.shiftStartTime)
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const changeRecords = useSelector((state: RootState) => state.timer.changeRecords)
    const stoppedRecords = useSelector((state: RootState) => state.timer.stoppedRecords)
    const batchRecords = useSelector((state: RootState) => state.timer.batchRecords)
    const downRecords = useSelector((state: RootState) => state.timer.downRecords)
    const rejectRecords = useSelector((state: RootState) => state.timer.rejectRecords)
    // const partRecords = useSelector((state: RootState) => state.timer.partRecords)

    const hourRecords = useSelector((state: RootState) => state.timer.hourRecords)
    const peopleRecords = useSelector((state: RootState) => state.timer.peopleRecords)

    const currentTime = useCurrentTime().getTime()


    // TESTING FUNCTIONS

    // const getPlan = (): number => {
    //     if (batchRecords.length) {
    //         const batchesPlan = batchRecords.slice(0, -1).reduce((acc, obj) => acc + (((obj.endTime 
    //             - obj.startTime) / 1000) 
    //             - obj.totalStopped.reduce((acc, num) => acc + num, 0)
    //         ) / obj.cycleTime, 0)
    //         const lastBatch = batchRecords.slice(-1)[0]
    //         const lastPlan = (((currentTime - lastBatch.startTime) / 1000) - lastBatch.totalStopped.reduce((acc, num) => acc + num, 0)) / lastBatch.cycleTime
    //         return batchesPlan + lastPlan
    //     }
    //     return 0
    // }

    // const totalBatchTime = () => {
    //     if (batchRecords.length) {
    //         const batchesTime = batchRecords.slice(0, -1).reduce((acc, obj) => acc + 
    //             (obj.endTime - obj.startTime) / 1000
    //         , 0)
    //         const lastBatch = batchRecords.slice(-1)[0]
    //         const lastBatchTime = (currentTime - lastBatch.startTime) / 1000
    //         // console.log(((currentTime - lastBatch.startTime) / 1000))
    //         return batchesTime + lastBatchTime
    //     }
    // }

    // const totalBatchTimeShift = () => {
    //     return ((currentTime - shiftStartTime) / 1000) - stoppedRecords[0].duration
    // }

    // const runTimeBatch = () => {
    //     if (batchRecords.length) {
    //         const batchesTime = batchRecords.slice(0, -1).reduce((acc, obj) => acc + 
    //             ((obj.endTime - obj.startTime) / 1000) - obj.totalStopped.reduce((acc, num) => acc + num, 0)
    //         , 0)
    //         const lastBatch = batchRecords.slice(-1)[0]
    //         const lastBatchTime = ((currentTime - lastBatch.startTime) / 1000) - lastBatch.totalStopped.reduce((acc, num) => acc + num, 0)
    //         return batchesTime + lastBatchTime
    //     }
    // }

    // const runTimeShift = () => {
    //     return ((currentTime - shiftStartTime) / 1000) - stoppedRecords.reduce((acc, obj) => acc + obj.duration, 0)
    // }


    const getCurrentEarnedTime = (): number => {
        return batchRecords.reduce((acc, batch) => acc + (batch.actual * batch.cycleTime), 0)
    }

    const shiftsEarnedTime = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.batchRecords).reduce((acc, batch) => acc + (batch.actual * batch.cycleTime), 0)
    }

    const getCurrentYield = (): number => {
        return (Math.round(actual / (actual + rejects) * 1000) / 10) || 0
    }

    const shiftsYield = (shifts: Array<ShiftData>): number => {
        const total = shifts.reduce((acc, shift) => {
            acc.actual += shift.actualParts
            acc.rejects += shift.rejectRecords.length
            return acc
            }, { actual: 0, rejects: 0 }
        )
        return Number((total.actual / (total.actual + total.rejects) * 100).toFixed(1)) || 0
    }

    const getCurrentDownPartsMissed = () => {
        return downRecords.reduce((acc, down) => acc + down.numParts, 0)
        // return batchRecords.reduce((acc, batch) =>
        //     acc + (batch.downIdxs.reduce((acc, idx) => acc + downRecords[idx].duration, 0) / batch.cycleTime)
        //     , 0
        // )
    }

    const shiftsDownPartsMissed = (shifts: Array<ShiftData>) => {
        return shifts.flatMap(s => s.downRecords).reduce((acc, down) => acc + down.numParts, 0)
    }

    const getCurrentChangePartsMissed = () => {
        return batchRecords.reduce((acc, batch) =>
            acc + (batch.totalChange / batch.cycleTime)
            , 0
        )
    }

    const shiftsChangePartsMissed = (shifts: Array<ShiftData>) => {
        return shifts.flatMap(s => s.batchRecords).reduce((acc, batch) =>
            acc + (batch.totalChange / batch.cycleTime)
            , 0
        )
    }

    const getCurrentRunParts = () => {
        return plan - getCurrentDownPartsMissed() - getCurrentChangePartsMissed()
    }

    const shiftsRunParts = (shifts: Array<ShiftData>) => {
        return shifts.reduce((acc, shift) => acc + shift.plannedParts, 0) 
            - shiftsDownPartsMissed(shifts) 
            - shiftsChangePartsMissed(shifts)
    }

    // ----- Totals -----
    const getCurrentTotalTimeSec = (): number => {
        return !shiftEndTime ?
            (currentTime - shiftStartTime) / 1000
            :
            (shiftEndTime - shiftStartTime) / 1000
    }

    const shiftsTotalTimeSec = (shifts: Array<ShiftData>): number => {
        return shifts.reduce((acc, shift) => acc + ((shift.shiftEndTime - shift.shiftStartTime) / 1000), 0)
    }

    const getCurrentTotalStoppedSec = (): number => {
        return stoppedRecords.reduce((acc, stop) => acc + stop.duration, 0)
    }

    const shiftsTotalStoppedSec = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.stoppedRecords).reduce((acc, stop) => acc + stop.duration, 0)
    }

    const getCurrentActualAvailableSec = (): number => {
        return (
            getCurrentTotalTimeSec()        // total time (s)
            - getCurrentTotalStoppedSec()   // total break (s)
        )
    }

    const shiftsActualAvailableSec = (shifts: Array<ShiftData>): number => {
        return (
            shiftsTotalTimeSec(shifts)        // total time (s)
            - shiftsTotalStoppedSec(shifts)   // total break (s)
        )
    }

    // const getBatchTime = (): number => {
    //     return (currentTime - batchRecords[0].startTime) / 1000
    // }

    const getCurrentTotalChangeSec = (): number => {
        return batchRecords.reduce((acc, batch) => acc + batch.totalChange, 0)
    }

    const shiftsTotalChangeSec = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.batchRecords).reduce((acc, batch) => acc + batch.totalChange, 0)
    }

    const getCurrentTotalDownSec = (): number => {
        return downRecords.reduce((acc, down) => acc + down.duration, 0)
    }

    const shiftsTotalDownSec = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.downRecords).reduce((acc, down) => acc + down.duration, 0)
    }

    const getCurrentTotalRunSec = (): number => {
        return (
            getCurrentActualAvailableSec()
            - getCurrentTotalChangeSec()
            - getCurrentTotalDownSec()
        )
        // if (!partRecords.length) return 0
        // const lastPart = partRecords.slice(-1)[0]
        // const otherPartRecords = partRecords.slice(0, -1)
        // const lastPartEnd = lastPart.endTime ? lastPart.endTime : currentTime
        // const lastPartDuration = Math.floor((lastPartEnd - lastPart.startTime) / 1000)
        //     - lastPart.totalDown.reduce((acc2, num) => acc2 + num, 0)
        //     - lastPart.totalChange.reduce((acc2, num) => acc2 + num, 0)
        //     - lastPart.totalStopped.reduce((acc2, num) => acc2 + num, 0)

        // const partTime = lastPartDuration +
        //     otherPartRecords.reduce((acc: number, currentPart: partData) => 
        //         acc + ((currentPart.endTime - currentPart.startTime) / 1000)
        //             - currentPart.totalDown.reduce((acc2, num) => acc2 + num, 0)
        //             - currentPart.totalChange.reduce((acc2, num) => acc2 + num, 0)
        //             - currentPart.totalStopped.reduce((acc2, num) => acc2 + num, 0)
        //     , 0)
        // console.log(getCurrentActualAvailableSec() - getCurrentTotalChangeSec() - getCurrentTotalDownSec(), partTime)

        // return partTime
    }

    const shiftsTotalRunSec = (shifts: Array<ShiftData>): number => {
        return (
            shiftsActualAvailableSec(shifts)
            - shiftsTotalChangeSec(shifts)
            - shiftsTotalDownSec(shifts)
        )
    }

    const getCurrentTotalCountTimeSec = (): number => {
        return batchRecords.reduce((acc, batch) =>
            acc + ((batch.actual + batch.rejects) * batch.cycleTime)
            , 0
        )
    }

    const shiftsTotalCountTimeSec = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.batchRecords).reduce((acc, batch) =>
            acc + ((batch.actual + batch.rejects) * batch.cycleTime)
            , 0
        )
    }

    // ----- Percents -----
    const getCurrentOLE = (): number => {
        // return getCurrentAvailabilityPercent() * getCurrentPerformancePercent() * getCurrentQualityPercent() / 10000
        return Math.round(getCurrentEarnedTime() / getCurrentActualAvailableSec() * 1000) / 10
        // return Math.round(actual / plan * 1000) / 10
    }

    const shiftsOLE = (shifts: Array<ShiftData>): number => {
        return Number((
            shiftsAvailabilityPercent(shifts) * 
            shiftsPerformancePercent(shifts) * 
            shiftsQualityPercent(shifts) / 
            10000).toFixed(1))
        // return Math.round(shiftsEarnedTime(shifts) / shiftsActualAvailableSec(shifts) * 1000) / 10
        // const actual = shifts.reduce((acc, shift) => acc + shift.actualParts, 0)
        // const plan = shifts.reduce((acc, shift) => acc + shift.plannedParts, 0)
        // return Math.round(actual / plan * 1000) / 10
    }

    const getCurrentAvailabilityPercent = (): number => {
        return Math.round(getCurrentTotalRunSec() / getCurrentActualAvailableSec() * 1000) / 10
    }

    const shiftsAvailabilityPercent = (shifts: Array<ShiftData>): number => {
        return Math.round(shiftsTotalRunSec(shifts) / shiftsActualAvailableSec(shifts) * 1000) / 10
    }

    const getCurrentPerformancePercent = (): number => {
        return Math.round(getCurrentTotalCountTimeSec() / getCurrentTotalRunSec() * 1000) / 10
        // return Math.round((actual + rejects) / (plan - downPartsMissed() - changePartsMissed()) * 1000) / 10
    }

    const shiftsPerformancePercent = (shifts: Array<ShiftData>): number => {
        return Math.round(shiftsTotalCountTimeSec(shifts) / shiftsTotalRunSec(shifts) * 1000) / 10
        // return Math.round((actual + rejects) / (plan - downPartsMissed() - changePartsMissed()) * 1000) / 10
    }

    const getCurrentQualityPercent = (): number => {
        return getCurrentYield()
    }

    const shiftsQualityPercent = (shifts: Array<ShiftData>): number => {
        return shiftsYield(shifts)
    }


    // ----- People -----
    const getCurrentPPH = (): number => {
        return Number((actual / getCurrentManHours()).toFixed(2))
    }

    const shiftsPPH = (shifts: Array<ShiftData>): number => {
        const actual = shifts.reduce((acc, shift) => acc + shift.actualParts, 0)
        return Number((actual / shiftsManHours(shifts)).toFixed(2))
    }

    const getCurrentAvgPeople = (): number => {
        const data = peopleRecords.reduce(
            (acc, obj) => {
                const duration = obj.endTime ?
                    (obj.endTime - obj.startTime)
                    :
                    (currentTime - obj.startTime)
                acc.total += duration
                acc.people += (duration * obj.peopleList.length)
                return acc
            }, { people: 0, total: 0 })
        return data.people / data.total
    }

    const shiftsAvgPeople = (shifts: Array<ShiftData>): number => {
        const data = shifts.flatMap(s => s.peopleRecords).reduce(
            (acc, ppl) => {
                const duration = ppl.endTime - ppl.startTime
                acc.total += duration
                acc.people += (duration * ppl.peopleList.length)
                return acc
            }, { people: 0, total: 0 })
        return data.people / data.total
    }

    const getCurrentManHours = (): number => {
        return peopleRecords.reduce(
            (acc, obj) => {
                const duration = obj.endTime ?
                    (obj.endTime - obj.startTime)
                    :
                    (currentTime - obj.startTime)
                return acc + (duration * obj.peopleList.length)
            }, 0
        ) / 1000 / 60 / 60  // hours
    }

    const shiftsManHours = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.peopleRecords).reduce(
            (acc, ppl) => {
                const duration = ppl.endTime - ppl.startTime
                return acc + (duration * ppl.peopleList.length)
            }, 0
        ) / 1000 / 60 / 60  // hours
    }

    // ----- Losses -----
    const getCurrentAvailabilityLoss = (): number => {
        return getCurrentTotalChangeSec() + getCurrentTotalDownSec()
    }

    const shiftsAvailabilityLoss = (shifts: Array<ShiftData>): number => {
        return shiftsTotalChangeSec(shifts) + shiftsTotalDownSec(shifts)
    }

    const getCurrentQualityLoss = (): number => {
        return batchRecords.reduce((acc, batch) => acc + (batch.rejects * batch.cycleTime), 0)
    }

    const shiftsQualityLoss = (shifts: Array<ShiftData>): number => {
        return shifts.flatMap(s => s.batchRecords).reduce((acc, batch) => acc + (batch.rejects * batch.cycleTime), 0)
    }

    const getCurrentPerformanceLoss = (): number => {
        // return getCurrentTotalTimeSec()
        //     - getCurrentTotalStoppedSec()
        //     - getCurrentAvailabilityLoss()
        //     - getCurrentQualityLoss()
        //     - getCurrentEarnedTime()
        return getCurrentTotalRunSec() - getCurrentTotalCountTimeSec()
    }

    const shiftsPerformanceLoss = (shifts: Array<ShiftData>): number => {
        return shiftsTotalRunSec(shifts) - shiftsTotalCountTimeSec(shifts)
    }

    // ----- Reasons -----
    const shiftsDownsByReason = (shifts: Array<ShiftData>): Record<string, { duration: number, missingParts: number }> => {
        return shifts.flatMap(s => s.downRecords).reduce((acc: any, down) => {
            if (down.code in acc) {
                acc[down.code].duration += down.duration
                acc[down.code].missingParts += down.numParts
            } else {
                acc[down.code] = {
                    duration: down.duration,
                    missingParts: down.numParts
                }
            }
            return acc
        }, {})
    }

    const currentDownsByReason = (): Record<string, { duration: number, missingParts: number }> => {
        return downRecords.reduce((acc: any, down) => {
            if (down.code in acc) {
                acc[down.code].duration += down.duration
                acc[down.code].missingParts += down.numParts
            } else {
                acc[down.code] = {
                    duration: down.duration,
                    missingParts: down.numParts
                }
            }
            return acc
        }, {})
    }

    const getDownBarData = (downReasons: Record<string, { duration: number, missingParts: number }>): Array<DownReasonsData> => {
        const downBarData: Array<DownReasonsData> = []
        Object.entries(downReasons).forEach(([reason, { duration, missingParts }]) => {
            downBarData.push({
                name: reason,
                duration: duration,
                missingParts: missingParts
            })
        })

        return _.orderBy(downBarData, d => d.duration, 'desc').slice(0, 4)
    }

    const shiftsRejectsByReason = (shifts: Array<ShiftData>): Record<string, number> => {
        return shifts.flatMap(s => s.rejectRecords).reduce((acc: any, reject) => {
            if (reject.code in acc) {
                acc[reject.code] += 1
            } else {
                acc[reject.code] = 1
            }
            return acc
        }, {})
    }

    const currentRejectsByReason = (): Record<string, number> => {
        return rejectRecords.reduce((acc: any, reject) => {
            if (reject.code in acc) {
                acc[reject.code] += 1
            } else {
                acc[reject.code] = 1
            }
            return acc
        }, {})
    }

    const getRejectBarData = (rejectReasons: Record<string, number>): Array<RejectReasonsData> => {
        const rejectBarData: Array<RejectReasonsData> = []
        Object.entries(rejectReasons).forEach(([reason, count]) => {
            rejectBarData.push({
                name: reason,
                count: count
            })
        })

        return _.orderBy(rejectBarData, r => r.count, 'desc').slice(0, 4)
    }

    // ----- Throughput -----
    const currentHoursThroughputData = (): Array<HourThroughputData> => {
        const hourData: Array<HourThroughputData> = []
        hourRecords.forEach((hour, idx) => {
            const gap = Math.floor(hour.plan) - hour.actual
            const downPartsMissed = hour.totalDown.reduce((acc, down) => acc + down.numParts, 0)
            const changePartsMissed = hour.totalChange.reduce((acc, change) => acc + change.numParts, 0)
            const availPartsMissed = Number((downPartsMissed + changePartsMissed).toFixed(1))
            const pacingPartsMissed = Number((gap - hour.rejects - downPartsMissed - changePartsMissed).toFixed(1))

            const endTime = hour.endTime ? hour.endTime : currentTime
            const totalTime = Math.floor((endTime - hour.startTime) / 1000)
            const totalStopped = hour.totalStopped.reduce((acc, num) => acc + num, 0)
            const totalDown = hour.totalDown.reduce((acc, down) => acc + down.duration, 0)
            const totalChange = hour.totalChange.reduce((acc, change) => acc + change.duration, 0)

            const totalQualAvail = availPartsMissed + hour.rejects
            const adjustedQual = pacingPartsMissed < 0 ?
                Number((hour.rejects - ((hour.rejects / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
                :
                hour.rejects
            const adjustedAvail = pacingPartsMissed < 0 ?
                Number((availPartsMissed - ((availPartsMissed / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
                :
                availPartsMissed

            hourData.push({
                xLabel: `Hour ${idx + 1}`,
                plan: Math.floor(hour.plan),
                actual: hour.actual,
                gap: gap,
                dispQual: (adjustedQual < 0 || !adjustedQual) ? 0 : adjustedQual,
                dispPacing: pacingPartsMissed < 0 ? 0 : pacingPartsMissed,
                dispAvail: (adjustedAvail < 0 || !adjustedAvail) ? 0 : adjustedAvail,
                rejects: hour.rejects,
                pacingPartsMissed: pacingPartsMissed,
                availPartsMissed: availPartsMissed,
                downPartsMissed: downPartsMissed,
                changePartsMissed: changePartsMissed,
                downTime: formatSeconds(totalDown),
                changeTime: formatSeconds(totalChange),
                totalTime: formatSeconds(totalTime),
                stoppedTime: formatSeconds(totalStopped),
                availTime: formatSeconds(totalTime - totalStopped),
                runTime: formatSeconds(totalTime - totalStopped - totalDown - totalChange)
            })
        })
        return hourData
    }

    // shifts: list of shifts during query
    // startTime: start of query in milliseconds
    // endTime: end of query in milliseconds
    // offset: when the day starts (...-1 = 11pm(prev day), 0 = 12am, 1 = 1am...)
    const dailyThroughputData = (shifts: Array<ShiftData>, startTime: number, endTime: number, offset: number): Array<DayThroughputData> => {
        // const dayOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const dayData: Array<DayThroughputData> = []
        // const shiftByDay = unit === 'week' ? getShiftsByDayOfWeek(shifts) : getShiftsByDayOfMonth(shifts)
        const shiftByDay = getShiftsByDay(shifts, offset)

        // Set order of days [1,2,3,4,5,6,0] - start week with monday
        // const sortedDayKeys = Object.keys(shiftByDay).sort()
        // const sundayKey = sortedDayKeys.shift()
        // sundayKey && sortedDayKeys.push(sundayKey)
        // const sortedDayKeys = []
        // let currentDay = dayjs(startTime).startOf('day').add(offset, 'hour')
        // const endDay = dayjs(endTime).startOf('day').add(offset, 'hour')
        // while (currentDay.isBefore(endDay.add(1, 'day'))) {
        //     sortedDayKeys.push(currentDay)
        //     currentDay.add(1, 'day')
        // }
        // console.log(sortedDayKeys)
        // const sortedDayKeys: Array<number> = unit === 'week' ? [1, 2, 3, 4, 5, 6, 0] : Array.from(Array(monthLength).keys())
        const sortedDayKeys = getDaysArray(startTime, endTime, offset)
        // const sortedDayKeys = Object.keys(shiftByDay).sort()
        // console.log(sortedDayKeys)
        sortedDayKeys.forEach(dayMilli => {
            const day = dayjs(dayMilli)
            const dayShifts: Array<ShiftData> = shiftByDay[dayMilli] || []
            const plan: number = dayShifts.reduce((acc, shift) => acc + shift.plannedParts, 0)
            const actual: number = dayShifts.reduce((acc, shift) => acc + shift.actualParts, 0)
            const rejects: number = dayShifts.reduce((acc, shift) => acc + shift.rejectRecords.length, 0)
            const gap = Math.floor(plan) - actual

            const totalTime = dayShifts.reduce((acc, shift) => acc + Math.floor((shift.shiftEndTime - shift.shiftStartTime) / 1000), 0)
            const totalStopped = dayShifts.flatMap(s => s.stoppedRecords).reduce((acc, stopped) => acc + stopped.duration, 0)
            const totalDown = dayShifts.flatMap(s => s.downRecords).reduce((acc, down) => {
                acc.duration += down.duration
                acc.numParts += down.numParts
                return acc
            }, { duration: 0, numParts: 0 })
            const totalChange = dayShifts.flatMap(s => s.batchRecords).reduce((acc, batch) => {
                acc.duration += batch.totalChange
                acc.numParts += Number((batch.totalChange / batch.cycleTime).toFixed(1))
                return acc
            }, { duration: 0, numParts: 0 })

            const availPartsMissed = Number((totalDown.numParts + totalChange.numParts).toFixed(1))
            const pacingPartsMissed = Number((gap - rejects - totalDown.numParts - totalChange.numParts).toFixed(1))

            const totalQualAvail = availPartsMissed + rejects
            const adjustedQual = pacingPartsMissed < 0 ?
                Number((rejects - ((rejects / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
                :
                rejects
            const adjustedAvail = pacingPartsMissed < 0 ?
                Number((availPartsMissed - ((availPartsMissed / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
                :
                availPartsMissed

            dayData.push({
                day: day.format('MMM D [-] ddd'),
                // name: unit === 'week' ? dayOfWeek[key] : String(key + 1),
                // xLabel: unit === 'week' ? day.format('ddd') : day.format('D'),
                xLabel: day.format('M[/]D'),
                plan: Math.floor(plan),
                actual: actual,
                gap: gap,
                dispQual: (adjustedQual < 0 || !adjustedQual) ? 0 : adjustedQual,
                dispPacing: pacingPartsMissed < 0 ? 0 : pacingPartsMissed,
                dispAvail: (adjustedAvail < 0 || !adjustedAvail) ? 0 : adjustedAvail,
                rejects: rejects,
                pacingPartsMissed: pacingPartsMissed,
                availPartsMissed: availPartsMissed,
                downPartsMissed: totalDown.numParts,
                changePartsMissed: totalChange.numParts,
                downTime: formatSeconds(totalDown.duration),
                changeTime: formatSeconds(totalChange.duration),
                totalTime: formatSeconds(totalTime),
                stoppedTime: formatSeconds(totalStopped),
                availTime: formatSeconds(totalTime - totalStopped),
                runTime: formatSeconds(totalTime - totalStopped - totalDown.duration - totalChange.duration)
            })
        })

        return dayData

        // hourRecords.forEach((hour, idx) => {
        //     const gap = Math.floor(hour.plan) - hour.actual
        //     const downPartsMissed = hour.totalDown.reduce((acc, down) => acc + down.numParts, 0)
        //     const changePartsMissed = hour.totalChange.reduce((acc, change) => acc + change.numParts, 0)
        //     const availPartsMissed = downPartsMissed + changePartsMissed
        //     const pacingPartsMissed = Number((gap - hour.rejects - downPartsMissed - changePartsMissed).toFixed(1))

        //     const totalQualAvail = availPartsMissed + hour.rejects
        //     const adjustedQual = pacingPartsMissed < 0 ? 
        //         Number((hour.rejects - ((hour.rejects / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
        //         : 
        //         hour.rejects
        //     const adjustedAvail = pacingPartsMissed < 0 ? 
        //         Number((availPartsMissed - ((availPartsMissed / totalQualAvail) * Math.abs(pacingPartsMissed))).toFixed(1))
        //         : 
        //         availPartsMissed

        //     hourData.push({
        //         name: `Hour ${idx + 1}`,
        //         plan: Math.floor(hour.plan),
        //         actual: hour.actual,
        //         gap: gap,
        //         dispQual: (adjustedQual < 0 || !adjustedQual) ? 0 : adjustedQual,
        //         dispPacing: pacingPartsMissed < 0 ? 0 : pacingPartsMissed,
        //         dispAvail: (adjustedAvail < 0 || !adjustedAvail) ? 0 : adjustedAvail,
        //         rejects: hour.rejects,
        //         pacingPartsMissed: pacingPartsMissed,
        //         availPartsMissed: availPartsMissed,
        //         downPartsMissed: downPartsMissed,
        //         changePartsMissed: changePartsMissed,
        //         downTime: formatSeconds(hour.totalDown.reduce((acc, down) => acc + down.duration, 0)),
        //         changeTime: formatSeconds(hour.totalChange.reduce((acc, change) => acc + change.duration, 0)),
        //         stoppedTime: formatSeconds(hour.totalStopped.reduce((acc, num) => acc + num, 0)),
        //     })
        // })
        // return hourData
    }

    // ----- Data -----
    const currentTimeBarData = [
        {
            name: "All time",
            earned: getCurrentEarnedTime(),
            quality: getCurrentQualityLoss(),
            performance: getCurrentPerformanceLoss() > 0 ? getCurrentPerformanceLoss() : 0,
            available: getCurrentAvailabilityLoss(),
            stopped: getCurrentTotalStoppedSec()
        },
    ]

    const currentPeopleData = [
        { name: 'PPH', value: getCurrentPPH() },
        { name: 'Available', value: formatSecondsToMinutes(getCurrentActualAvailableSec()) },
        { name: 'Stopped', value: formatSecondsToMinutes(getCurrentTotalStoppedSec()) },
        // { name: 'Total', value: formatSecondsToMinutes(getCurrentTotalTimeSec()) },
        { name: 'Man Hours', value: getCurrentManHours().toFixed(2) },
    ]

    const shiftsPeopleData = (shifts: Array<ShiftData>) => {
        return [
            { name: 'PPH', value: shiftsPPH(shifts) },
            { name: 'Available', value: formatSecondsToMinutes(shiftsActualAvailableSec(shifts)) },
            { name: 'Stopped', value: formatSecondsToMinutes(shiftsTotalStoppedSec(shifts)) },
            // { name: 'Total', value: formatSecondsToMinutes(getCurrentTotalTimeSec()) },
            { name: 'Man Hours', value: shiftsManHours(shifts).toFixed(2) },
        ]
    }

    const currentOleData = [
        { name: 'OE', value: `${getCurrentOLE()}%` },
        { name: 'Actual', value: actual },
        { name: 'Plan', value: Math.floor(plan) }
    ]

    const shiftsOleData = (shifts: Array<ShiftData>) => {
        return [
            { name: 'OE', value: `${shiftsOLE(shifts)}%` },
            // { name: 'OE', value: `${shifts.reduce((acc, shift) => acc + shift.actualParts, 0) / shifts.reduce((acc, shift) => acc + shift.plannedParts, 0)}%` },
            { name: 'Actual', value: shifts.reduce((acc, shift) => acc + shift.actualParts, 0) },
            { name: 'Plan', value: Math.floor(shifts.reduce((acc, shift) => acc + shift.plannedParts, 0)) }
        ]
    }

    const currentQualData: Array<OLEData> = [
        { name: 'Total Parts', parts: actual + rejects },
        { name: 'Good', parts: actual },
        { name: 'Rejects', parts: rejects },
    ]

    const shiftsQualData = (shifts: Array<ShiftData>): Array<OLEData> => {
        const actual = shifts.reduce((acc, shift) => acc + shift.actualParts, 0)
        const rejects = shifts.reduce((acc, shift) => acc + shift.rejectRecords.length, 0)
        return [
            { name: 'Total Parts', parts: actual + rejects },
            { name: 'Good', parts: actual },
            { name: 'Rejects', parts: rejects },
        ]
    }

    const currentPerformData: Array<OLEData> = [
        { name: 'Run Time', parts: Math.round(getCurrentRunParts()), min: formatSecondsToMinutes(getCurrentTotalRunSec()) },
        { name: 'Total Parts Time', parts: actual + rejects, min: formatSecondsToMinutes(getCurrentTotalCountTimeSec()) },
        { name: 'Performance Loss', parts: Math.round(getCurrentRunParts() - actual - rejects), min: formatSecondsToMinutes(getCurrentPerformanceLoss()) }
    ]

    const shiftsPerformData = (shifts: Array<ShiftData>): Array<OLEData> => {
        const actual = shifts.reduce((acc, shift) => acc + shift.actualParts, 0)
        const rejects = shifts.reduce((acc, shift) => acc + shift.rejectRecords.length, 0)
        return [
            { name: 'Run Time', parts: Math.round(shiftsRunParts(shifts)), min: formatSecondsToMinutes(shiftsTotalRunSec(shifts)) },
            { name: 'Total Parts', parts: actual + rejects, min: formatSecondsToMinutes(shiftsTotalCountTimeSec(shifts)) },
            { name: 'Pacing Loss', parts: Math.round(shiftsRunParts(shifts) - actual - rejects), min: formatSecondsToMinutes(shiftsPerformanceLoss(shifts)) }
        ]
    }

    const currentAvailData: Array<OLEData> = [
        { name: 'Available Time', parts: Math.round(plan), min: formatSecondsToMinutes(getCurrentActualAvailableSec()) },
        { name: 'Run Time', parts: Math.round(getCurrentRunParts()), min: formatSecondsToMinutes(getCurrentTotalRunSec()) },
        { name: 'Down Time', parts: Math.round(getCurrentDownPartsMissed()), min: formatSecondsToMinutes(getCurrentTotalDownSec()) },
        { name: 'Change Time', parts: Math.round(getCurrentChangePartsMissed()), min: formatSecondsToMinutes(getCurrentTotalChangeSec()) },
    ]

    const shiftsAvailData = (shifts: Array<ShiftData>): Array<OLEData> => {
        const plan = Math.floor(shifts.reduce((acc, shift) => acc + shift.plannedParts, 0))
        return [
            { name: 'Avail Time', parts: Math.round(plan), min: formatSecondsToMinutes(shiftsActualAvailableSec(shifts)) },
            { name: 'Run Time', parts: Math.round(shiftsRunParts(shifts)), min: formatSecondsToMinutes(shiftsTotalRunSec(shifts)) },
            { name: 'Down Time', parts: Math.round(shiftsDownPartsMissed(shifts)), min: formatSecondsToMinutes(shiftsTotalDownSec(shifts)) },
            { name: 'Change Time', parts: Math.round(shiftsChangePartsMissed(shifts)), min: formatSecondsToMinutes(shiftsTotalChangeSec(shifts)) },
        ]
    }

    // const getChangeRecords = (shifts: Array<ShiftData>): Array<ChangeTableRecord> => {
    //     return shifts.flatMap(s => s.batchRecords.filter(b => b.totalChange).map(b => (
    //         {
    //             fromTo: `${b.prevPart}-${b.part}`,
    //             duration: b.totalChange,
    //             missingParts: Number((b.totalChange / b.cycleTime).toFixed(1)),
    //             startTime: b.startTime
    //         }
    //     )))
    // }

    // const getDownRecords = (shifts: Array<ShiftData>): Array<DownTableRecord> => {
    //     return shifts.flatMap(s => s.downRecords.map(d => (
    //         {
    //             startTime: d.startTime,
    //             code: d.code === '' ? '_' : d.code,
    //             note: d.note,
    //             duration: d.duration,
    //             partsMissed: d.numParts,
    //         }
    //     )))
    // }

    // const getRejectRecords = (shifts: Array<ShiftData>): Array<RejectTableRecord> => {
    //     const records: Array<RejectTableRecord> = []
    //     shifts.flatMap(s => s.partRecords.forEach(p => {
    //         if (p.rejectIdx !== null) {
    //             const totalDown = p.downIdxs.reduce((acc, idx) => acc + s.downRecords[idx].duration, 0)
    //             const totalStopped = p.stoppedIdxs.reduce((acc, idx) => acc + s.stoppedRecords[idx].duration, 0)
    //             const totalChange = p.totalChange.reduce((acc, sec) => acc + sec, 0)
    //             records.push({
    //                 // ...s.rejectRecords[p.rejectIdx],
    //                 part: s.rejectRecords[p.rejectIdx].part,
    //                 code: s.rejectRecords[p.rejectIdx].code === '' ? '_' : s.rejectRecords[p.rejectIdx].code,
    //                 note: s.rejectRecords[p.rejectIdx].note,
    //                 duration: Math.floor((p.endTime - p.startTime) / 1000) - totalDown - totalStopped - totalChange,
    //                 endTime: p.endTime
    //             })
    //         }
    //     }))
    //     return records
    // }

    // const getBatchRecords = (shifts: Array<ShiftData>): Array<BatchTableRecord> => {
    //     return shifts.flatMap(s => s.batchRecords.map(b => (
    //         {
    //             workOrder: b.workOrder,
    //             part: b.part,
    //             plan: b.plan,
    //             actual: b.actual,
    //             rejects: b.rejects,
    //             people: b.peopleIdxs.map(idx => s.peopleRecords[idx].peopleList),
    //             changeTime: b.totalChange,
    //             startTime: b.startTime
    //         }
    //     )))
    // }

    return {
        getCurrentEarnedTime,
        shiftsEarnedTime,
        getCurrentYield,
        shiftsYield,
        getCurrentDownPartsMissed,
        shiftsDownPartsMissed,
        getCurrentChangePartsMissed,
        shiftsChangePartsMissed,
        getCurrentRunParts,
        shiftsRunParts,
        // Totals
        getCurrentTotalTimeSec,
        shiftsTotalTimeSec,
        getCurrentTotalStoppedSec,
        shiftsTotalStoppedSec,
        getCurrentActualAvailableSec,
        shiftsActualAvailableSec,
        getCurrentTotalChangeSec,
        shiftsTotalChangeSec,
        getCurrentTotalDownSec,
        shiftsTotalDownSec,
        getCurrentTotalRunSec,
        shiftsTotalRunSec,
        getCurrentTotalCountTimeSec,
        shiftsTotalCountTimeSec,
        // Percents
        getCurrentOLE,
        shiftsOLE,
        getCurrentAvailabilityPercent,
        shiftsAvailabilityPercent,
        getCurrentPerformancePercent,
        shiftsPerformancePercent,
        getCurrentQualityPercent,
        shiftsQualityPercent,
        // People
        getCurrentAvgPeople,
        shiftsAvgPeople,
        getCurrentManHours,
        shiftsManHours,
        getCurrentPPH,
        shiftsPPH,
        // Losses
        getCurrentAvailabilityLoss,
        shiftsAvailabilityLoss,
        getCurrentQualityLoss,
        shiftsQualityLoss,
        getCurrentPerformanceLoss,
        shiftsPerformanceLoss,
        // Reasons
        shiftsDownsByReason,
        currentDownsByReason,
        getDownBarData,
        currentRejectsByReason,
        shiftsRejectsByReason,
        getRejectBarData,
        // Throughput
        currentHoursThroughputData,
        dailyThroughputData,
        // Data
        currentTimeBarData,
        currentPeopleData,
        shiftsPeopleData,
        currentOleData,
        shiftsOleData,
        currentQualData,
        shiftsQualData,
        currentPerformData,
        shiftsPerformData,
        currentAvailData,
        shiftsAvailData,
        // getChangeRecords,
        // getDownRecords,
        // getRejectRecords,
        // getBatchRecords,
    }
}

export default useCalcs