import { Button, ButtonGroup, FormControl, FormErrorMessage, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { PartData } from "../../hooks/useConfigCell"
import TextInput from "../TextInput"

interface IRemovePart {
    parts: Array<PartData>
    handleRemovePart: (part: string) => void
}

const RemovePartForm = ({ parts, handleRemovePart }: IRemovePart) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<HTMLInputElement>(null)
    const [partNum, setPartNum] = useState<string>('')

    const handleAdd = () => {
        handleRemovePart(partNum)
        setPartNum('')
        onClose()
    }

    const handleClose = () => {
        setPartNum('')
        onClose()
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
                // closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button
                        colorScheme="red"
                        variant='outline'
                        size='sm'
                    >
                        {`remove part`}
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    {/* Form */}
                    {/* <Form firstFieldRef={firstFieldRef} onCancel={onClose} /> */}
                    <Stack spacing={4}>
                        <FormControl isRequired isInvalid={!(parts.find(p => p.partNum.toLowerCase() === partNum.toLowerCase()))}>
                            <TextInput
                                label={`Part Number`}
                                id={`Part-num`}
                                ref={firstFieldRef}
                                // placeholder="press jammed"
                                value={partNum}
                                onChange={(e: any) => setPartNum(e.currentTarget.value)}
                                // defaultValue=""
                            />
                            <FormErrorMessage textTransform='initial'>
                                {'Must be an existing part number'}
                            </FormErrorMessage>
                        </FormControl>
                        <ButtonGroup d="flex" justifyContent="flex-end">
                            <Button 
                                variant="outline" 
                                size='sm'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                size='sm'
                                colorScheme="red"
                                onClick={handleAdd}
                                disabled={!(parts.find(p => p.partNum === partNum))}
                            >
                                Remove
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default RemovePartForm