import { Flex, Heading, Text } from "@chakra-ui/layout"
import { useMediaQuery } from "@chakra-ui/react"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

interface IDipsData {
    data: string | number,
    label: string,
    button?: JSX.Element,
    total?: number
}

const DispData = ({ data, label, button, total }: IDipsData) => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let dataSize: string = '3xl'
    let labelSize: string = 'xl'

    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        dataSize = '55px'
        labelSize = '25px'
    }
    if (smH) {  // 480px
        dataSize = '70px'
        labelSize = '30px'
    }
    if (mdH) {  // 768px
        dataSize = '75px'
        labelSize = '35px'
    }
    if (lgH) {  // 992px
        dataSize = '80px'
        labelSize = '40px'
    }
    if (xlH) {  // 1280px
        dataSize = '100px'
        labelSize = '50px'
    }
    
    return (
        <Flex flexDir='column' alignItems='center'>
            {/* <Heading size={dataSize}>
                {data}
            </Heading> */}
            <Heading fontSize={dataSize} lineHeight='1' textColor={isDown ? 'black' : (label === 'plan' ? 'gray.600' : '')}>
                {total && total > 0 ?
                    <Flex>
                        {data}
                        <Text alignSelf='flex-end' mb={2} fontSize={labelSize} textColor='gray.600'>
                            / {total}
                        </Text>
                    </Flex>
                    :
                    data
                }
            </Heading>
            <Text fontSize={labelSize} textColor={isDown ? 'gray.700' : 'gray.600'}>
                {label}{button}
            </Text>
        </Flex>
    )
}

export default DispData