import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchSessionsDataAsync } from "../utils/firebase";
import { DownData, StoppedData } from "./timerSlice";
import { userData } from "./userSlice";



export interface sessionData {
    id: string,
    startTime: number,
    cycleTime: number,
    planned: number,
    actual: number,
    rejected: number,
    downRecords: Array<DownData>,
    stoppedRecords: Array<StoppedData>,
}
export interface cellSessions {
    [cellId: string]: Array<sessionData>
}
export interface cellData {
    displayName: string,
    id: string
}

interface SliceState {
    isLoading: boolean,
    cellsArray: Array<cellData>,
    cellSessionsData: cellSessions,
    //sessionsData: Array<sessionData>,
    errorMessage: any,
}

const initialState: SliceState = {
    isLoading: false,
    cellsArray: [],
    cellSessionsData: {},
    //sessionsData: [],
    errorMessage: null,
}

export interface IgetSessionsData {
    currentUser: userData,
    cell: cellData,
}
export const getSessionsData = createAsyncThunk('sessions/getSessions', 
    async (data: IgetSessionsData) => { 
        const {currentUser, cell }= data
        const sessionsData = await fetchSessionsDataAsync(currentUser, cell)
        const cellId = cell.id
        return {sessionsData, cellId}
    }
)

const sessions = createSlice({
    name: 'sessions',
    initialState,
    reducers: {
        // setSessionsData: (state, action) => {
        //     state.sessionsData = action.payload
        // },
        setCellSessions: (state, action) => {
            const {cellId, sessionsData} = action.payload
            state.cellSessionsData[cellId] = sessionsData
        },
        setSessionsDataError: (state, action) => {
            state.errorMessage = action.payload
        },
        setCellsData: (state, action) => {
            state.cellsArray = action.payload
        },
        // fetchSessionsDataPending: (state) => {
        //     state.isFetching = true
        // },
        // fetchSessionsDataSuccess: (state, action) => {
        //     state.isFetching = false
        //     state.sessionsData = action.payload
        // },
        // fetchSessionsDataError: (state, action) => {
        //     state.isFetching = false
        //     state.errorMessage = action.payload
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(getSessionsData.pending, (state, action) => {
          state.isLoading = true
        })
        builder.addCase(getSessionsData.fulfilled, (state, { payload }) => {
          const { sessionsData, cellId } = payload
          state.cellSessionsData[cellId] = sessionsData
        })
        builder.addCase(getSessionsData.rejected, (state, action) => {
          state.errorMessage = action.payload
        })
    }
})

export const {
    //setSessionsData,
    setCellSessions,
    setCellsData,
    setSessionsDataError,
} = sessions.actions
export default sessions.reducer