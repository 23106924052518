import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { firestore } from "../utils/firebase"

interface Data {
    error: Error | null;
    loading: boolean;
    configCell: ConfigCell;
}

export interface ConfigCell {
    parts: Array<PartData>,
    reject_codes: Array<CodeData>,
    down_codes: Array<CodeData>,
    shift_names: Array<CodeData>,
    operators: Array<CodeData>,
    setup_check_map: ChecklistMap,
    cleanup_check_map: ChecklistMap,
}

export interface CodeData {
    code: string
    cells: Array<string>  // UID of cells
}

export interface PartData {
    partNum: string
    alias: string
    cells: Array<string>  // UID of cells
    // setupTime: number
    // cleanupTime: number
    // setupCheck: ChecklistMap
    // cleanupCheck: ChecklistMap
    designData: DesignData
    // form? first pass yeild?
}

// export interface PartMap {
//     [partName: string]: PartData
// }

export interface DesignData {
    default: number  // #ppl
    [numPeople: string]: number  // #ppl -> cycle time
}

export interface ChecklistMap {
    [index: string]: string  // idx -> task
}

export interface WorkOrder {  // TODO: Not used yet - other data?
    workOrderNum: string
    goalParts: number
    cells: Array<string>
    parts: Array<string>
}

export const convertChecklistMapToList = (checkMap: ChecklistMap): Array<string> => {
    const finalList: Array<string> = []
    Object.keys(checkMap).map(key => 
        finalList[Number(key)] = checkMap[key]
    )
    return finalList
}

export const convertChecklistToMap = (checklist: Array<string>): ChecklistMap => {
    const newChecklistMap: ChecklistMap = {}
    checklist.forEach((value, i) => {
        newChecklistMap[i] = value
    })
    return newChecklistMap
}

const initData: Data = {
    error: null,
    loading: true,
    configCell: {
        parts: [],
        reject_codes: [],
        operators: [],
        down_codes: [],
        shift_names: [],
        setup_check_map: {},
        cleanup_check_map: {}
    }
}

const useConfigCell = (type: string) => {
    const [data, setData] = useState<Data>(initData)
    const currentUser = useSelector((state: RootState) => state.user.currentUser)
    
    let adminId: string
    let cellId: string
    if (currentUser) {
        if (type === 'admin') {
            adminId = `${currentUser?.id}`
            // if (cellUID) {
            //     cellId = cellUID
            // }
        } else if (type === 'cell') {
            adminId = `${currentUser?.adminUID}`
            cellId = `${currentUser?.id}`
        }
    }

    const getParts = (list: Array<PartData>) => {
        if (type === 'cell' && list) {
            return list.filter(item => item.cells.includes(cellId))
        }
        return list
    }

    const getCodes = (list: Array<CodeData>) => {
        if (type === 'cell' && list) {
            return list.filter(item => item.cells.includes(cellId))
        }
        return list
    }

    useEffect(() => {
        if (currentUser) {
            const unsubscribe = firestore
                .doc(`users/${adminId}/config/config_cell`)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        const { 
                            parts,
                            reject_codes,
                            operators,
                            down_codes,
                            shift_names,
                            setup_check_map,
                            cleanup_check_map,
                        }: any = doc.data()
                        
                        setData({
                            error: null,
                            loading: false,
                            configCell: {
                                parts: getParts(parts) || [],
                                reject_codes: getCodes(reject_codes) || [],
                                operators: getCodes(operators) || [],
                                down_codes: getCodes(down_codes) || [],
                                shift_names: getCodes(shift_names) || [],
                                setup_check_map: setup_check_map || {},
                                cleanup_check_map: cleanup_check_map || {},
                            }
                        })
                    }
                    // var source = doc.metadata.fromCache ? "local cache" : "server";
                    // console.log("Data came from " + source);
                },
                    (error) => {
                        setData({
                            error: error,
                            loading: false,
                            configCell: {
                                parts: [],
                                reject_codes: [],
                                operators: [],
                                down_codes: [],
                                shift_names: [],
                                setup_check_map: {},
                                cleanup_check_map: {}
                            }
                        })
                    })

            return unsubscribe
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return data
}

export default useConfigCell