import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { Text, Flex, Box, Tooltip, Divider } from "@chakra-ui/react"
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"

interface IPieProgress {
    name: string,
    percent: number,
    color: string,
    size: string,
    dataTable?: ReactJSXElement,
}

const PieProgress = ({ name, percent, color, size, dataTable }: IPieProgress) => {
    const displayPercent = percent < 100 ? percent : 100
    const dataPercent = [
        { name: name, value: displayPercent },
        { name: `${name} Loss`, value: Math.floor((100 - displayPercent) * 10) / 10 },
    ]

    let progressLabel: any = ''
    if (name === 'Quality') {
        // progressLabel = 'Good Parts / Total Parts'
        progressLabel = (
            <Box m={1} textAlign='center'>
                <Text>
                    Good Parts
                </Text>
                <Divider />
                <Text>
                    Total Parts
                </Text>
            </Box>
        )
    } else if (name === 'Performance') {
        // progressLabel = '(DCT * Total Parts) / Run Time'
        progressLabel = (
            <Box m={1} textAlign='center'>
                <Text>
                    Total Parts * DCT
                </Text>
                <Divider />
                <Text>
                    Run Time
                </Text>
            </Box>
        )
    } else if (name === 'Availability') {
        progressLabel = (
            <Box m={1} textAlign='center'>
                <Text>
                    Run Time
                </Text>
                <Divider />
                <Text>
                    Available Time
                </Text>
            </Box>
        )
    } else if (name === 'Overall Labor Effectiveness') {
        progressLabel = (
            <Box m={1} textAlign='center'>
                <Box display='inline-block'>
                    <Text>
                        Good Parts * DCT
                    </Text>
                    <Divider />
                    <Text>
                        Available Time
                    </Text>
                </Box>
                {/* <Text my={1}>
                    or
                </Text>
                <Text>
                    Qual * Perform * Avail
                </Text> */}
                {/* <Text>
                    Quality * Performance * Availability
                </Text> */}
            </Box>
        )
    }

    return (
        <>
            {/* <Flex> */}
            <Flex flexDir='column' align='center' justifySelf='center'>
                <Tooltip label={progressLabel} placement='bottom'>
                <Box w={size} h={size}>
                    <ResponsiveContainer>
                        <PieChart >
                            <text
                                x='50%'
                                y='50%'
                                fontSize={`calc(${size} / 5.5)`}
                                textAnchor='middle'
                                dominantBaseline='middle'
                            >
                                {`${percent}%`}
                            </text>
                            <Pie
                                data={dataPercent}
                                dataKey='value'
                                innerRadius='80%'
                                outerRadius='100%'
                                fill='#CCC'
                                startAngle={90}
                                endAngle={-270}
                                paddingAngle={0}
                                blendStroke
                            >
                                <Cell key={name} fill={color} />
                                {/* {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                            ))} */}
                            </Pie>
                            {/* <Legend /> */}
                            {/* <Tooltip /> */}
                        </PieChart>
                    </ResponsiveContainer>
                </Box>
                </Tooltip>
                <Text mb={dataTable && 4} fontSize='2.7vh'>
                    {name}
                </Text>
                <Box>
                    {dataTable}
                </Box>
            </Flex>
            {/* {name === "Overall Labor Effectiveness" &&
                    <Box alignSelf='center' ml={8} justifySelf='flex-end'>
                        {dataTable}
                    </Box>
                }
            </Flex> */}
        </>
    )
}

export default PieProgress