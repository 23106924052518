import { useMemo } from "react"
import { ShiftData } from "../../hooks/useShifts"
import { StoppedData } from "../../redux/timerSlice"
import BatchesTable, { BatchTableRecord } from "../charts/BatchesTable"
import ChangesTable, { ChangeTableRecord } from "../charts/ChangesTable"
import DownsTable, { DownTableRecord } from "../charts/DownsTable"
import RejectsTable, { RejectTableRecord } from "../charts/RejectsTable"
import StopsTable from "../charts/StopsTable"

interface IInsightsRecords {
    shifts: Array<ShiftData>, 
    records: string
}

const InsightsRecords = ({ shifts, records }: IInsightsRecords): JSX.Element => {

    const getChangeRecords = (shifts: Array<ShiftData>): Array<ChangeTableRecord> => {
        return shifts.flatMap(s => s.batchRecords.filter(b => b.totalChange).map(b => (
            {
                fromTo: `${b.prevPart}-${b.part}`,
                duration: b.totalChange,
                missingParts: Number((b.totalChange / b.cycleTime).toFixed(1)),
                startTime: b.startTime
            }
        )))
    }

    const getDownRecords = (shifts: Array<ShiftData>): Array<DownTableRecord> => {
        return shifts.flatMap(s => s.downRecords.map(d => (
            {
                startTime: d.startTime,
                code: d.code === '' ? '_' : d.code,
                note: d.note,
                duration: d.duration,
                partsMissed: d.numParts,
            }
        )))
    }

    const getRejectRecords = (shifts: Array<ShiftData>): Array<RejectTableRecord> => {
        const records: Array<RejectTableRecord> = []
        shifts.flatMap(s => s.partRecords.forEach(p => {
            if (p.rejectIdx !== null) {
                const totalDown = p.downIdxs.reduce((acc, idx) => acc + s.downRecords[idx].duration, 0)
                const totalStopped = p.stoppedIdxs.reduce((acc, idx) => acc + s.stoppedRecords[idx].duration, 0)
                const totalChange = p.totalChange.reduce((acc, sec) => acc + sec, 0)
                records.push({
                    // ...s.rejectRecords[p.rejectIdx],
                    part: s.rejectRecords[p.rejectIdx].part,
                    code: s.rejectRecords[p.rejectIdx].code === '' ? '_' : s.rejectRecords[p.rejectIdx].code,
                    note: s.rejectRecords[p.rejectIdx].note,
                    duration: Math.floor((p.endTime - p.startTime) / 1000) - totalDown - totalStopped - totalChange,
                    endTime: p.endTime
                })
            }
        }))
        return records
    }

    const getBatchRecords = (shifts: Array<ShiftData>): Array<BatchTableRecord> => {
        return shifts.flatMap(s => s.batchRecords.map(b => {
            const batchTime = (b.endTime - b.startTime) / 1000
            const totalDown = b.downIdxs.reduce((acc, idx) => acc + s.downRecords[idx].duration, 0)
            const totalStopped = b.stoppedIdxs.reduce((acc, idx) => acc + s.stoppedRecords[idx].duration, 0)
            const runTime = batchTime - totalDown - totalStopped - b.totalChange
            const totalCount = b.actual + b.rejects
            const avgCt = runTime / totalCount
            // console.log(runTime / totalCount)
            return {
                workOrder: b.workOrder,
                part: b.part,
                plan: b.plan,
                actual: b.actual,
                rejects: b.rejects,
                ctDiff: Number((((avgCt - b.cycleTime) / b.cycleTime) * 100).toFixed(1)),
                // avgCT: runTime / totalCount,
                // dct: b.cycleTime,
                people: b.peopleIdxs.map(idx => s.peopleRecords[idx].peopleList),
                changeTime: b.totalChange,
                startTime: b.startTime
            }
        }))
    }

    const useChangeRecords: Array<ChangeTableRecord> = useMemo(() => getChangeRecords(shifts), [shifts])
    const useDownRecords: Array<DownTableRecord> = useMemo(() => getDownRecords(shifts), [shifts])
    const useRejectRecords: Array<RejectTableRecord> = useMemo(() => getRejectRecords(shifts), [shifts])
    const useBatchRecords: Array<BatchTableRecord> = useMemo(() => getBatchRecords(shifts), [shifts])
    const useStoppedRecords: Array<StoppedData> = useMemo(() => shifts.flatMap(s => s.stoppedRecords), [shifts])

    if (records === 'batch') {
        return <BatchesTable queryChange={shifts} batchRecords={useBatchRecords} />
    } else if (records === 'down') {
        return <DownsTable queryChange={shifts} downRecords={useDownRecords} />
    } else if (records === 'reject') {
        return <RejectsTable queryChange={shifts} rejectRecords={useRejectRecords} />
    } else if (records === 'stopped') {
        return <StopsTable queryChange={shifts} stoppedRecords={useStoppedRecords} />
    } else if (records === 'change') {
        return <ChangesTable queryChange={shifts} changeRecords={useChangeRecords} />
    } else {
        return <></>
    }
}

export default InsightsRecords