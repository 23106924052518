import { Link } from "react-router-dom";
import { Box, Flex, Image, Button } from "@chakra-ui/react"
import DarkLogo from "../../assets/Dark-logo.svg"

const Header = () => {

    return (
        <Box 
            bg='white' 
            boxShadow='base'
        >
            <Flex
                px={8}
                py={2}
                justifyContent='space-between'
                alignItems='center'
                borderBottom='2px'
                borderBottomColor='gray.100'
            >
                {/* Home Logo */}
                <a href="https://www.leansystems.io/">
                    <Image
                        maxH='6vh'
                        my={2}
                        ml={12}
                        src={DarkLogo}
                        alt="Lean Systems"
                    />
                </a>

                {/* Sign in */}
                <Link to='/signin' >
                    <Button
                        colorScheme='teal'
                        px={8}
                        mx={[null, 8]}
                    >
                        Sign In
                    </Button>
                </Link>
            </Flex>
        </Box >
    )
}

export default Header;