import { Button, ButtonGroup, FormControl, FormErrorMessage, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import { CodeData } from "../../hooks/useConfigCell"
import TextInput from "../TextInput"

interface IRemoveCode {
    type: string
    codes: Array<CodeData>
    handleRemoveCode: (part: string) => void
}

const RemoveCodeForm = ({ type, codes, handleRemoveCode }: IRemoveCode) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<HTMLInputElement>(null)
    const [code, setCode] = useState<string>('')

    const handleAdd = () => {
        handleRemoveCode(code)
        setCode('')
        onClose()
    }

    const handleClose = () => {
        setCode('')
        onClose()
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
            // closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button
                        colorScheme="red"
                        variant='outline'
                        size='sm'
                        textTransform='lowercase'
                    >
                        {`remove ${type}`}
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    {/* Form */}
                    {/* <Form firstFieldRef={firstFieldRef} onCancel={onClose} /> */}
                    <Stack spacing={4}>
                        <FormControl isRequired isInvalid={!(codes.find(c => c.code.toLowerCase() === code.toLowerCase()))}>
                            <TextInput
                                label={`Code`}
                                id={`code`}
                                ref={firstFieldRef}
                                // placeholder="press jammed"
                                value={code}
                                onChange={(e: any) => setCode(e.currentTarget.value)}
                            // defaultValue=""
                            />
                            <FormErrorMessage textTransform='initial'>
                                {'Must be an existing code'}
                            </FormErrorMessage>
                        </FormControl>
                        <ButtonGroup d="flex" justifyContent="flex-end">
                            <Button
                                variant="outline"
                                size='sm'
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                size='sm'
                                colorScheme="red"
                                onClick={handleAdd}
                                disabled={!(codes.find(p => p.code === code))}
                            >
                                Remove
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default RemoveCodeForm