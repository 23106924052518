import { Flex, Button, ButtonGroup } from "@chakra-ui/react"

interface ISaveChange {
    discard: () => void
    save: () => void
    isSaving: boolean
}

const SaveChange = ({discard, save, isSaving}: ISaveChange) => {
    return (
        <Flex w='full' py={2} bg='blackAlpha.800' >
            <Flex flex={1} textColor='gray.100' align='center' px={4} fontWeight='semibold' >
                Unsaved Changes
            </Flex>
            <ButtonGroup spacing={4} >
                <Button 
                    variant='outline' 
                    size='sm'
                    textColor='gray.100' 
                    _hover={{ bg: "gray.600" }}
                    onClick={discard}
                >
                    Discard
                </Button>
                <Button 
                    isLoading={isSaving}
                    size='sm' 
                    mr={8} 
                    px={6} 
                    textColor='gray.100' 
                    colorScheme='blue'
                    // bg='blue.500'
                    // _hover={{ bg: "blue.400" }}
                    onClick={save}
                >
                    Save
                </Button>
            </ButtonGroup>
        </Flex>
    )
}

export default SaveChange