import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, useDisclosure, Textarea, IconButton, useMediaQuery, Icon } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { setIsEditingRedux } from "../../redux/timerSlice"
import { MdOutlineEdit } from "react-icons/md"
import { RootState } from "../../redux/store"

interface INoteModal {
    size: string,
    title: string,
    value: string,
    update: (note: string) => void
}

const NoteModal = ({ size, title, value, update }: INoteModal) => {
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()

    const [ smH ] = useMediaQuery([
        '(min-height: 30em)', // 480px
    ])

    let areaH: string = '30vh'
    if (!smH) {  // < 480px
        areaH = '30vh'
    }
    if (smH) {  // 480px
        areaH = '50vh'
    }

    // TODO: Escape key to close modal
    // Escape will close modal
    // useEffect(() => {
    //     const handleKeyDown = (event: KeyboardEvent) => {
    //         if (event.key === 'Escape') {
    //             close()
    //         }
    //     }

    //     window.addEventListener('keydown', handleKeyDown)

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const close = async () => {
        dispatch(setIsEditingRedux(false))
        onClose()
    }

    return (
        <>
            <IconButton
                size={size}
                icon={<Icon as={MdOutlineEdit} w='60%' h='60%' />
                // <EditIcon />
                }
                border='1px'
                borderColor='gray.200'
                boxShadow='sm'
                onClick={() => {
                    onOpen()
                    dispatch(setIsEditingRedux(true))
                }}
                aria-label="edit-note"
                // limit size blowout on PWA
                maxW={size === 'sm' ? 8 : (size === 'md' ? 10 : 12)}
            />

            <Modal
                onClose={onClose}
                size='lg'
                isOpen={isOpen}
                onOverlayClick={close}
                onEsc={close}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalCloseButton onClick={close} />
                    <ModalBody>
                        <Textarea
                            h={areaH}
                            autoFocus
                            value={value}
                            onChange={e => update(e.currentTarget.value)}
                            onFocus={e => {
                                e.currentTarget.value = ''
                                e.currentTarget.value = value
                                e.currentTarget.scrollTop = e.currentTarget.scrollHeight
                            }}
                            placeholder='Add notes'
                            disabled={!!shiftEndTime}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='teal' onClick={close}>Done</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default NoteModal