import { Box, Flex, keyframes } from "@chakra-ui/react";


const Loading = () => {
    // const commonClasses = "h-2.5 w-2.5 bg-blue-500 rounded-full"

    const bounce = keyframes`
        0%, 100% {
            transform: translateY(-25%);
            animationTimingFunction: cubic-bezier(0.8, 0, 1, 1);
        }
        50% {
            transform: translateY(0);
            animationTimingFunction: cubic-bezier(0, 0, 0.2, 1);
        }
    `

    return (
        <>
            <Flex w='full' h='full' position='fixed' display='block' top='0px' left='0px' bg='white' opacity='0.75' zIndex='50'>
                <Flex top='50%' my={0} mx='auto' position='relative'>
                    <Flex flex={1} justify='center'>
                        <Box mr={1} animation={`${bounce} 1s infinite`} h={2.5} w={2.5} bg='teal.500' borderRadius='9999px' />
                        <Box mr={1} sx={{'animationDelay': '200ms'}} animation={`${bounce} 1s infinite`} h={2.5} w={2.5} bg='teal.500' borderRadius='9999px' />
                        <Box sx={{'animationDelay': '400ms'}} animation={`${bounce} 1s infinite`} h={2.5} w={2.5} bg='teal.500' borderRadius='9999px' />
                    </Flex>
                </Flex>
            </Flex>
            {/* <div className='flex w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50'>
            <div className="flex top-1/2 my-0 mx-auto block relative">
                <div className={`${commonClasses} mr-1 animate-bounce`}/>
                <div className={`${commonClasses} rounded-full mr-1 animate-bounce200`}/>
                <div className={`${commonClasses} rounded-full animate-bounce400`}/>
            </div>
        </div> */}
        </>
    )
}

export default Loading;