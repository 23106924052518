import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/modal"
import { Button, Heading, Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import useCurrentTime from "../../hooks/useCurrentTime"
import { RootState } from "../../redux/store"
import { beginStopped, endStopped } from "../../redux/timerSlice"
import { formatDateTime } from "../../utils"

const BreakModal = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    // const stoppedTime = useSelector((state: RootState) => state.timer.stoppedRecords.slice(-1)[0].duration)
    const endTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const isEditing = useSelector((state: RootState) => state.timer.isEditingRedux)

    const startBreak = () => {
        onOpen()
        dispatch(beginStopped('break'))
    }

    const currentTime = formatDateTime(useCurrentTime())

    // Key bindings for 'b'
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isEditing && !isStopped && !isChange && event.key === 'b') {
                startBreak()
            }
        }
        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChange, isStopped, isEditing])
    
    return (
        <>
            <Button
                // colorScheme='blue'
                colorScheme='teal'
                w='full'
                h='full'
                fontSize={['md', 'lg', 'xl', '2xl']}
                onClick={startBreak}
                variant='outline'
                border='3px solid'
                bg='white'
                isDisabled={!!endTime || isStopped}
            >
                Break
            </Button>

            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size='full'
            >
                <ModalOverlay />
                <ModalContent>
                    <Flex flexDir='column' flex={1} w={3 / 5} alignItems='center' justifyContent='space-evenly' mx='auto'>
                        <Heading size='3xl' >
                            Stopped
                        </Heading>
                        <Heading size='4xl' >
                            {currentTime}
                            {/* {stoppedTime === -1 ? 
                                formatSeconds(0)
                                :
                                formatSeconds(stoppedTime)
                            } */}
                        </Heading>
                        <Button
                            colorScheme='teal'
                            onClick={() => {
                                dispatch(endStopped())
                                onClose()
                            }}
                            autoFocus
                            mx='auto'
                            h='20vh'
                            w='full'
                            size='lg'
                        >
                            Resume Production
                        </Button>
                    </Flex>
                </ModalContent>
            </Modal>
        </>
    )
}

export default BreakModal