import { Box, Stack, Table, Tbody, Tr, Th, Td, chakra, Thead, Text, Divider, useToast, Input, Flex } from "@chakra-ui/react"
import HeadingCellDoc from "./HeadingCellDoc"
import AddCellCode from "./AddCellCode"
import { CodeData } from "../../hooks/useConfigCell"
import _ from "lodash"
import { useMemo } from "react"
import { TriangleDownIcon, TriangleUpIcon, ChevronRightIcon, ChevronDownIcon } from "@chakra-ui/icons"
import { useExpanded, useFlexLayout, useGlobalFilter, useSortBy, useTable } from "react-table"
import React from "react"
import CellMultiSelect from "./CellMultiSelect"
import RemoveCodeForm from "./RemoveCode"

// interface heading {
//     title: string
//     desc: string
// }

// interface headingMap {
//     [type: string]: heading
// }

interface ICellCodeTable {
    title: string,
    desc: string,
    single: string,
    codes: Array<CodeData>,
    setNewCodes: (code: React.SetStateAction<CodeData[]>) => void
    setIsChanged: (code: React.SetStateAction<boolean>) => void
}

const CellCodeTable = ({ title, desc, single, codes, setNewCodes, setIsChanged }: ICellCodeTable) => {

    // const headingMap: headingMap = {
    //     'Down Code': {
    //         title: 'Down Codes',
    //         desc: 'The codes this cell can select for down time' 
    //     },
    //     'Reject Code': {
    //         title: 'Reject Codes',
    //         desc: 'The codes this cell can select for rejects'
    //     },
    //     'Shift Name': {
    //         title: 'Shift Names',
    //         desc: 'The names this cell can select for current shift'
    //     }
    // }

    const toast = useToast()

    // const data: Array<CodeData> = useMemo(() => [], [])
    const data: Array<CodeData> = useMemo(() => codes, [codes])
    // console.log('data', data)

    const columns: any = useMemo(
        () => [
            {
                id: 'expander', // Make sure it has an ID
                // Header: () => null,
                maxWidth: 10,
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded ?
                            <ChevronDownIcon aria-label='expanded' />
                            :
                            <ChevronRightIcon aria-label='collapsed' />
                        }
                    </span>
                ),
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    // row.canExpand ? (
                    <span {...row.getToggleRowExpandedProps()}>
                        {row.isExpanded ?
                            <ChevronDownIcon aria-label='expanded' />
                            :
                            <ChevronRightIcon aria-label='collapsed' />
                        }
                    </span>
                // ) : null,
            },
            {
                id: 'code list',
                Header: title,
                accessor: 'code',
            },
            // {
            //     id: 'delete', // Make sure it has an ID
            //     Header: () => null,
            //     maxWidth: 20,
            //     Cell: ({ row }: any) =>
            //         <DeleteIcon
            //             size='sm'
            //             onClick={() => handleDeleteCode(row.index)}
            //             cursor='pointer'
            //         />
            // }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title, data],
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        state: { globalFilter },
        // @ts-ignore
        setGlobalFilter,
        // @ts-ignore
    } = useTable({ columns, data, autoResetExpanded: false, defaultPageSize: 10 },
        useFlexLayout,
        useGlobalFilter,
        useSortBy,
        useExpanded,
    )

    const updateSelectedCells = (idx: number, selectedCells: Array<string>) => {
        setIsChanged(true)
        const newCodes = _.cloneDeep(codes)
        newCodes[idx].cells = selectedCells
        setNewCodes(newCodes)
    }

    // const handleDeleteCode = (index: number) => {
    //     setIsChanged(true)
    //     const newCodes = _.cloneDeep(codes)
    //     newCodes.splice(index, 1)
    //     setNewCodes(newCodes)
    // }

    const handleRemoveCode = (codeName: string) => {
        setIsChanged(true)
        const newCodes = _.cloneDeep(codes)
        const index: number = newCodes.findIndex(c => c.code.toLowerCase() === codeName.toLowerCase())
        newCodes.splice(index, 1)
        setNewCodes(newCodes)
    }

    const handleAddCode = (addCode: string) => {
        // check if code exists
        if (codes.filter(c => c.code.toLowerCase() === addCode.toLowerCase()).length > 0) {
            toast({
                title: `${single} ${addCode} already exists`,
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        }
        // code doesn't already exist
        setIsChanged(true)
        const newCodes = _.cloneDeep(codes)

        if (title === 'Setup Checklist' || title === 'Cleanup Checklist') {
            newCodes.push({ code: addCode, cells: [] })  // FIXME: handle this case for checklist
        } else {
            newCodes.push({ code: addCode, cells: [] })
        }
        setNewCodes(_.sortBy(newCodes, c => c.code))
    }

    return (
        // Same as ContainerCellDoc, but with Box p={2} instead of p={6}
        <Stack spacing={6} minW='xl'>
            <HeadingCellDoc title={`${title}`} desc={desc} />
            <Box
                boxShadow='base'
                borderRadius="lg"
                p={8}
                bg='white'
            >
                <Flex px={2} justify='space-between' align='center'>
                    <AddCellCode type={single} handleAddCode={handleAddCode} />
                    {data.length > 0 && <RemoveCodeForm type={single} codes={codes} handleRemoveCode={handleRemoveCode} />}
                </Flex>
                {data.length > 0 &&
                    <>
                        <Box px={2} >
                            {/* <Text mb={1} ml={1} textColor='gray.800'>
                            {`Filter`}
                        </Text> */}
                            <Input
                                placeholder={`Search ${title}...`}
                                my={6}
                                bg='white'
                                value={globalFilter || ''}
                                onChange={e => setGlobalFilter(e.currentTarget.value)}
                                size='sm'
                            />
                            {/* <Divider /> */}
                        </Box>
                        <Box 
                            maxH='50vh'
                            overflow='auto'
                        >
                        <Table {...getTableProps()} border='1px' borderColor='gray.100'>
                            {/* {data.length > 0 && */}
                            <Thead bg='gray.100'>
                                {headerGroups.map((headerGroup: any, idx: number) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                        {headerGroup.headers.map((column: any) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                key={column.id}
                                                flex={column.accessor === 'code' ? 1 : ''}
                                                px={4}
                                            >
                                                {column.render('Header')}
                                                <chakra.span pl='4' key={column.id + 'arrow'}>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <TriangleDownIcon aria-label='sorted descending' />
                                                        ) : (
                                                            <TriangleUpIcon aria-label='sorted ascending' />
                                                        )
                                                    ) : null}
                                                </chakra.span>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                                {rows.map((row: any) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={data[row.index].code} >
                                            <Tr {...row.getRowProps()} key={data[row.index].code + 'data'}>
                                                {row.cells.map((cell: any) => (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        px={4}
                                                    >
                                                        {cell.render('Cell')}
                                                    </Td>
                                                ))}
                                            </Tr>
                                            {row.isExpanded ? (
                                                <Tr key={data[row.index].code + 'cells'}>
                                                    <Td pl={8}>
                                                        <Text mb={2} textColor='gray.800'>
                                                            {`Assign cells - ${data[row.index].code}`}
                                                        </Text>
                                                        <CellMultiSelect
                                                            idx={row.index}
                                                            selectedIds={data[row.index].cells}
                                                            updateSelectedCells={updateSelectedCells}

                                                        />
                                                        <Divider />
                                                    </Td>
                                                </Tr>
                                            ) : null}
                                        </React.Fragment>
                                    )
                                })}
                            </Tbody>
                            {/* } */}
                            {/* <Tfoot>
                        <Tr>
                        <Th>
                        <AddCellCode type={single} handleAddCode={handleAddCode} />
                        </Th>
                        </Tr>
                    </Tfoot> */}
                        </Table>
                        </Box>
                    </>
                }
            </Box>
        </Stack>
    )
}

export default CellCodeTable
