import { Flex, Heading, Stack, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import SaveChange from "../../components/admin/SaveChange"
import { useSelector } from "react-redux"
import { firestore } from "../../utils/firebase"
import { RootState } from "../../redux/store"
import _ from "lodash"
import useConfigCell, { CodeData, PartData } from "../../hooks/useConfigCell"
import CellCodeTable from "../../components/admin/CellCodeTable"
import PartTable from "../../components/admin/PartTable"

const ConfigCell = () => {
    const adminUID = useSelector((state: RootState) => state.user.currentUser?.id);
    // const { cellId } = useParams<{ cellId: string }>()
    // const [isLoading, setIsLoading] = useState<boolean>(false)
    // let history = useHistory()

    const { error: ccError, configCell } = useConfigCell('admin')
    // const { cellDoc } = useCellDoc(cellId)
    // useEffect(() => console.log(cellDoc, loading, error), [cellDoc, error, loading])

    // const [newDocData, setNewDocData] = useState<CellDoc>(cellDoc)

    const toast = useToast()

    const [isChanged, setIsChanged] = useState<boolean>(false)
    const [newShifts, setNewShifts] = useState<Array<CodeData>>([])
    const [newDowns, setNewDowns] = useState<Array<CodeData>>([])
    const [newRejects, setNewRejects] = useState<Array<CodeData>>([])
    const [newOperators, setNewOperators] = useState<Array<CodeData>>([])
    // const [newSetupList, setNewSetupList] = useState<Array<string>>([])
    // const [newCleanupList, setNewCleanupList] = useState<Array<string>>([])
    const [newParts, setNewParts] = useState<Array<PartData>>([])
    
    // SAVE DOC
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const saveDoc = async () => {
        const cellConfigRef = firestore
                           .doc(`users/${adminUID}/config/config_cell`)
                        //    .doc(cellId)
        setIsSaving(true)
        try {
            await cellConfigRef.set({
                shift_names: newShifts,
                down_codes: newDowns,
                reject_codes: newRejects,
                operators: newOperators,
                parts: newParts,
                // setup_check_map: convertChecklistToMap(newSetupList),
                // cleanup_check_map: convertChecklistToMap(newCleanupList),
            }, { merge: true })
            setIsSaving(false)
            setIsChanged(false)
            toast({
                title: "Success! Settings have been saved.",
                status: "success",
                duration: 5000,
                isClosable: true
            })
        } catch (error) {
            // console.log(error)
            toast({
                title: "Oops, something went wrong saving settings.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            setIsSaving(false)
        }
    }

    // DELETE CELL
    // const deleteCell = async () => {
    //     setIsLoading(true)
    //     try {
    //         const deleteCell = functions.httpsCallable('deleteCell');
    //         await deleteCell({
    //             cellId: cellId, 
    //             cellName: cellDoc.name,
    //             adminUID: adminUID
    //         })
    //         setIsLoading(false)
    //         history.push('/manage-cells')
    //         // toast?
    //     } catch (err) {
    //         toast({
    //             title: "Oops, something went wrong",
    //             status: "error",
    //             duration: 5000,
    //             isClosable: true
    //         })
    //         setIsLoading(false)
    //         // setIsLoading(false)
    //         // console.log(err)
    //     }
    // }

    const initFields = () => {
        setIsChanged(false)
        setNewShifts(configCell.shift_names)
        setNewDowns(configCell.down_codes)
        setNewRejects(configCell.reject_codes)
        setNewOperators(configCell.operators)
        setNewParts(_.cloneDeep(configCell.parts))
        // setNewSetupList(convertChecklistMapToList(cellDoc.setup_check_map))
        // setNewCleanupList(convertChecklistMapToList(cellDoc.cleanup_check_map))
    }

    useEffect(() => {
        initFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configCell])

    // if (ccLoading) {
    //     return <Loading />
    // }
    
    // TODO: create error handler
    if (ccError) {
        // insert warning
    }

    return (
        <>
            <Flex flex='1' h='100vh' flexDir='column'>
                {isChanged &&
                    <SaveChange 
                        discard={initFields} 
                        save={saveDoc} 
                        isSaving={isSaving} 
                    />
                }
                <Flex flex='1' overflow='auto' h='100vh' flexDir='column' bg='gray.100' >
                    <Stack
                        spacing={12}
                        py={8}
                        mx={8}
                        maxW='xl'
                        minW='50vw'
                        align='center'
                        alignSelf='center'
                    >   
                        <Heading 
                            size='lg'  
                            textTransform='uppercase'
                            letterSpacing='wider'
                        >
                            Settings
                        </Heading>
                        <CellCodeTable 
                            title='Shift Names'
                            desc='The names that cells can select for current shift'
                            single='Shift Name'
                            codes={newShifts}
                            setNewCodes={setNewShifts}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeTable 
                            title='Down Codes'
                            desc='The codes that cells can select for down time'
                            single='Down Code'
                            codes={newDowns}
                            setNewCodes={setNewDowns}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeTable 
                            title='Reject Codes'
                            desc='The codes that cells can select for rejects'
                            single='Reject Code'
                            codes={newRejects}
                            setNewCodes={setNewRejects}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeTable 
                            title='Operators'
                            desc='The operators that can be working at a cell'
                            single='Operator'
                            codes={newOperators}
                            setNewCodes={setNewOperators}
                            setIsChanged={setIsChanged}
                        />
                        <PartTable
                            parts={newParts}
                            setNewParts={setNewParts} 
                            setIsChanged={setIsChanged}
                        />
                        {/* <PartAccord 
                            parts={newParts}
                            setNewParts={setNewParts} 
                            setIsChanged={setIsChanged}
                        /> */}
                        {/* <CellCodeList
                            // type='Shift Name'
                            title='Shift Names'
                            desc='The names cells can select for current shift'
                            single='Shift Name'
                            codes={newShifts}
                            setNewCodes={setNewShifts}
                            setIsChanged={setIsChanged}
                        /> */}
                        {/* <CellCodeList
                            // type='Down Code'
                            title='Down Codes'
                            desc='The codes that cells can select for down time'
                            single='Down Code'
                            codes={newDowns}
                            setNewCodes={setNewDowns}
                            setIsChanged={setIsChanged}
                        />
                        <CellCodeList
                            // type='Reject Code'
                            title='Reject Codes'
                            desc='The codes that cells can select for rejects'
                            single='Reject Code'
                            codes={newRejects}
                            setNewCodes={setNewRejects}
                            setIsChanged={setIsChanged}
                        /> */}
                    </Stack>
                </Flex>
            </Flex>
        </>
    )
}

export default ConfigCell