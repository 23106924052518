import { Box, Grid, GridItem, Flex, Button, Text, Select } from "@chakra-ui/react"
import { ShiftsQuery } from "../../hooks/useShifts"
import CellSingleSelect from "./CellSingleSelect"

interface IInsightsQuery {
    query: ShiftsQuery
    setCellId: (cellId: string, cellName: string) => void
    setTimeframe: (timeframe: string) => void
    getInsights: () => void
}

const InsightsQuery = ({ query, setCellId, setTimeframe, getInsights }: IInsightsQuery) => {

    return (
        <>
            <Box bg='white' p={6} borderRadius='lg' boxShadow='base' w='full'>
                <Grid w='full' templateColumns='repeat(3, 1fr)' gap={12}>
                    <GridItem colSpan={1}>
                        <Text mb='2' fontWeight='medium'>Select a cell</Text>
                        <Box w='80%'>
                            <CellSingleSelect
                                updateSelectedCell={setCellId}
                            />
                        </Box>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Text mb='2' fontWeight='medium'>Select timeframe</Text>
                        <Box minW='70%'>
                            <Select
                                size='sm'
                                placeholder='Select timeframe'
                                // value={timeframe}
                                onChange={e => setTimeframe(e.currentTarget.value)}
                            >
                                <option value='this week'>This Week</option>
                                <option value='this month'>This Month</option>
                                <option value='last week'>Last Week</option>
                                <option value='last month'>Last Month</option>
                            </Select>
                        </Box>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <Flex flex={1} h='full' align='center' justify='center'>
                            <Button
                                minW='60%' h='75%'
                                colorScheme='teal'
                                onClick={getInsights}
                                isDisabled={!query.cellId || !query.relativeTime || !query.unit}
                            >
                                Get Insights
                            </Button>
                        </Flex>
                    </GridItem>
                </Grid>
            </Box>
        </>
    )
}

export default InsightsQuery