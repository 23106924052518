import { Button, ButtonGroup, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverTrigger, Stack, useDisclosure } from "@chakra-ui/react"
import { useRef, useState } from "react"
import TextInput from "../TextInput"

interface IAddCellCode {
    type: string
    handleAddCode: (value: string) => void
}

const AddCellCode = ({ type, handleAddCode }: IAddCellCode) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<HTMLInputElement>(null)
    const [code, setCode] = useState<string>('')

    const handleAdd = (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        handleAddCode(code)
        setCode('')
        onClose()
    }

    const handleClose = () => {
        setCode('')
        onClose()
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={onOpen}
                onClose={onClose}
                placement="bottom-start"
            // closeOnBlur={false}
            >
                <PopoverTrigger>
                    <Button
                        cursor='pointer'
                        // onClick={() => console.log('add code')}
                        size='sm'
                        textTransform='lowercase'
                        // mt={1}
                        variant='outline'
                        borderColor='gray.400'
                    >
                        {`+ add ${type}`}
                    </Button>
                </PopoverTrigger>
                <PopoverContent p={5}>
                    <PopoverArrow />
                    <PopoverCloseButton onClick={handleClose} />
                    {/* Form */}
                    {/* <Form firstFieldRef={firstFieldRef} onCancel={onClose} /> */}
                    <form onSubmit={handleAdd}>
                        <Stack spacing={4}>
                            <TextInput
                                label={`${type}`}
                                id={`${type}`}
                                ref={firstFieldRef}
                                // placeholder="press jammed"
                                value={code}
                                onChange={(e: any) => setCode(e.currentTarget.value)}
                            // defaultValue=""
                            />
                            <ButtonGroup d="flex" justifyContent="flex-end">
                                <Button
                                    variant="outline"
                                    size='sm'
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type='submit'
                                    size='sm'
                                    colorScheme="blue"
                                    // onClick={handleAdd}
                                    disabled={code === ''}
                                >
                                    Add
                                </Button>
                            </ButtonGroup>
                        </Stack>
                    </form>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default AddCellCode