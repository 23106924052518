import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import Icon from "@chakra-ui/icon";
import { Flex, VStack, Box, Heading, InputGroup, InputLeftElement, Input, useToast, Text } from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { auth } from "../../utils/firebase";

const ForgotPass = () => {
    const [ email, setEmail ] = useState<string>('');
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    // const [ error, setError ] = useState<string>('')
    // const [ success, setSuccess ] = useState<boolean>(false)

    const toast = useToast()

    const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        // setError('')
        
        try {
            setIsLoading(true)
            await auth.sendPasswordResetEmail(email)

            // Clear form
            // setError('')
            setIsLoading(false)
            toast({
                title: `Success! Check ${email} for the reset link.`,
                status: "success",
                duration: 5000,
                isClosable: true
            })
            setEmail('')
            // setSuccess(true)
        } catch (error: any) {
            // console.log(error)
            // if (error.code === 'auth/user-not-found') {
            //     setError("There is no user record corresponding to this identifier. The user may have been deleted.")
            // } else {
            //     setError(error.message)
            // }
            setIsLoading(false)
            toast({
                title: "There is no user record corresponding to this email.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            // setError(error.message)
        }

    }

    return (
        <>
            <Flex
                w='full'
                h='92vh'
                align='center'
                justify='center'
                bg='gray.50'
            >
                <VStack spacing={8} mx={'auto'} w='600px' py={12} px={6}>
                    <Box
                        rounded='lg'
                        bg='white'
                        boxShadow='lg'
                        p={8}
                        w='full'
                    >
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={8}>
                                <VStack>
                                    <Heading fontSize={{ base: '2xl', md: '3xl' }}>Forgot your password?</Heading>
                                    <Text alignSelf='flex-start'>
                                        Only Admin - Cell passwords are reset by their admin
                                    </Text>
                                    {/* <Text alignSelf='flex-start'>
                                        
                                    </Text> */}
                                </VStack>
                                <FormControl isRequired w={{ base: 'full', md: '75%'}}>
                                    <FormLabel>Email</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdOutlineEmail} color='gray.400' />}
                                        />
                                        <Input
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.currentTarget.value)}
                                            placeholder='johnsmith@example.com'
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    isLoading={isLoading}
                                    loadingText='Submitting'
                                    // onClick={handleSubmit}
                                    colorScheme='teal'
                                    w='50%'
                                    isDisabled={!email}
                                    type='submit'
                                    size='lg'
                                >
                                    Submit
                                </Button>
                            </VStack>
                        </form>
                    </Box>
                </VStack>
            </Flex>
        </>
        // <div className="w-full min-w-screen min-h-screen bg-indigo-200 flex flex-col items-center justify-center px-5 py-5">
        //     {success ? 
        //         <div className="mb-2 py-4 px-4 text-white text-center font-bold bg-green-400">
        //             Success! Check {email} for the reset link.
        //         </div>
        //         : <div/>
        //     }
        //     {/* CARD */}
        //     <div className="bg-white text-gray-500 rounded-3xl shadow-xl max-w-md w-full overflow-hidden">
        //         <div className="flex w-full">
        //             <div className="w-full py-7 px-5 md:px-10">
        //                 {/* TITLE */}
        //                 <div className="text-center mb-7">
        //                     <h1 className="font-bold text-3xl text-gray-900 mb-2">
        //                         Forgot Password?
        //                     </h1>
        //                     <p>Enter your email for a reset link (admin only)</p>
        //                     <p>Cell passwords are reset by their admin</p>
        //                 </div>
        //                 {/* INPUTS */}
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Email
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     <i className="mdi mdi-email-outline text-gray-400 text-lg" />
        //                                 </div>
        //                                 <input 
        //                                     type="email" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="johnsmith@example.com" 
        //                                     value={email}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        //                                     required
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* ERROR */}
        //                     <div className="mb-2 text-xs text-center text-red-500">
        //                         {error}
        //                     </div>
        //                     {/* SUBMIT */}
        //                     <div className="flex -mx-3">
        //                         <div className="mt-6 w-full px-3 mb-5">
        //                             <button type='submit' className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
        //                                 SUBMIT
        //                             </button>
        //                         </div>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ForgotPass;