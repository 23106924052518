import { Route } from 'react-router-dom';
import Unauthorized from '../../pages/Unauthorized';

const PrivateRoute = ({component: Component, access, message, displayButton, ...rest}: any) => {
    // console.log(access)
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            access ? 
            <Component {...props} {...rest}/>
            : 
            <Unauthorized message={message} displayButton={displayButton} />
        )} />
    );
}

export default PrivateRoute;