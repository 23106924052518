import { GridItem, Grid, Button } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { RootState } from "../../redux/store"
import { resetTimerSlice, startStopDownTime } from "../../redux/timerSlice"
import CleanupModal from "./CleanupModal"
import Down from "./Down"

const RightCol = () => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const endTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    const dispatch = useDispatch()
    let history = useHistory()

    return (
        <>
            <GridItem colSpan={3}>
                <Grid h='full' overflow='hidden' templateRows='13% auto 17%' gap={2}>
                    <GridItem >
                        {!endTime ?
                            <CleanupModal />
                            :
                            <Button
                                colorScheme='teal'
                                w='full'
                                h='full'
                                fontSize={['md', 'lg', 'xl', '2xl']}
                                variant='outline'
                                border='3px solid'
                                onClick={() => {dispatch(resetTimerSlice()); history.push('/')}}
                            >
                                Finish
                            </Button>
                        }
                    </GridItem>

                    <Down />

                    <GridItem >
                        <Button
                            colorScheme='orange'
                            w='full'
                            h='full'
                            fontSize={['md', 'lg', 'xl', '2xl']}
                            // variant={isDown ? 'outline' : 'solid'}
                            // bg={isDown ? 'white' : 'orange.500'}
                            // borderColor='orange.600'
                            // border={isDown ? '5px solid' : ''}
                            // fontWeight={isDown ? 'bold' : ''}
                            onClick={() => dispatch(startStopDownTime())}
                            isDisabled={!!endTime || isStopped}
                        >
                            {isDown ? 'End Down' : 'Down'}
                        </Button>
                    </GridItem>
                </Grid>
            </GridItem>
        </>
    )
}

export default RightCol