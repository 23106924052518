import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter } from "@chakra-ui/modal"
import { Button, Text, ModalHeader, ModalCloseButton } from "@chakra-ui/react"
import useCellDoc from "../../hooks/useCellDoc"


const LastNotesModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    // const { loading, cellDoc, error } = useCellDoc('cell')
    const { cellDoc } = useCellDoc('cell')

    return (
        <>
            <Button
                colorScheme='teal'
                variant='outline'
                size='lg'
                fontSize={['md', 'lg', 'xl', '2xl']}
                onClick={onOpen}
            >
                Last Notes
            </Button>
            {
                // MODAL
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Last Shift Notes
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text whiteSpace='pre-line' >
                            {cellDoc.last_shift_notes}
                        </Text>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='teal' onClick={onClose}>Done</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default LastNotesModal