import { Icon } from "@chakra-ui/icons";
import { MdOutlineEmail, MdLockOutline, MdPersonOutline } from 'react-icons/md'
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../../utils/firebase";
import { VStack, Heading, Box, FormControl, FormLabel, Input, Button, Text, ButtonGroup, useRadioGroup, InputGroup, InputLeftElement, useToast } from "@chakra-ui/react";
import RadioOptions from "../../components/RadioOptions";

const Signin = () => {
    const [ email, setEmail ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    // const [ error, setError] = useState<string>('')
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ accountType, setAccountType ] = useState<string>('Admin')
    // const [ isAdmin, setIsAdmin ] = useState<boolean>(true)
    let history = useHistory()

    const toast = useToast()

    const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsLoading(true)
        // setError('')

        try {
            if (accountType === 'Cell') {
                await auth.signInWithEmailAndPassword(`${email}@email.com`, password)
            } else {
                await auth.signInWithEmailAndPassword(email, password)
            }

            // Clear form
            setIsLoading(false)
            // setError('')
            setEmail('')
            setPassword('')
            history.push('/')
        } catch (error: any) {
            // console.log(error, error.code)
            setIsLoading(false)
            if (error.code === "auth/invalid-email") {
                toast({
                    title: "Incorrect username formatting.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                })
                // setError('Incorrect username formatting')
            } else if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                toast({
                    title: "Invalid username or password.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                })
                // setError('Invalid username or password')
            } else {
                toast({
                    title: error.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true
                })
                // setError(error.message)
            }
        }

    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'account type',
        defaultValue: 'Admin',
        onChange: setAccountType,
    })

    const group = getRootProps()

    return (
        <>
            <Box
                w='full'
                // flex='1'
                h='100vh'
                // flexDir='column'
                overflow='auto'
                pb={24}
                // align='center'
                // justify='center'
                bg='gray.50'
            >
                <VStack spacing={8} mx={'auto'} maxW='600px' py={12} px={6}>
                    <Heading fontSize={'4xl'}>SIGN IN</Heading>
                    <Box
                        rounded='lg'
                        bg='white'
                        boxShadow='lg'
                        p={8}
                        w='full'
                    >
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={8}>
                                <FormControl>
                                    <FormLabel>Select Account Type</FormLabel>
                                    <ButtonGroup {...group} w='100%' size='sm' isAttached variant='outline'>
                                        {['Admin', 'Cell'].map((value) => {
                                            const radio = getRadioProps({ value })
                                            return (
                                                <RadioOptions key={value} {...radio}>
                                                    {value}
                                                </RadioOptions>
                                            )
                                        })}
                                        {/* <Button 
                                        w='50%' 
                                        onClick={() => setIsAdmin(true)}
                                        bg={isAdmin ? 'teal.400' : 'none'}
                                        textColor={isAdmin ? 'white' : 'gray.800'}
                                        _hover={{
                                            bg: 'gray.200'
                                        }}
                                    >
                                        Admin
                                    </Button>
                                    <Button 
                                        w='50%' 
                                        onClick={() => setIsAdmin(false)}
                                        bg={!isAdmin ? 'teal.400' : 'none'}
                                        _hover={!isAdmin ? {
                                            bg: 'gray.200'
                                        }: {}}
                                    >
                                        Cell
                                    </Button> */}
                                    </ButtonGroup>
                                </FormControl>
                                {accountType === 'Admin' ?
                                    <FormControl isRequired>
                                        <FormLabel>Email</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<Icon as={MdOutlineEmail} color='gray.400' />}
                                            />
                                            <Input
                                                type="email"
                                                value={email}
                                                onChange={e => setEmail(e.currentTarget.value)}
                                                placeholder='johnsmith@example.com'
                                            />
                                        </InputGroup>
                                    </FormControl>
                                    :
                                    <FormControl isRequired>
                                        <FormLabel>Username</FormLabel>
                                        <InputGroup>
                                            <InputLeftElement
                                                pointerEvents='none'
                                                children={<Icon as={MdPersonOutline} color='gray.400' />}
                                            />
                                            <Input
                                                type="text"
                                                value={email}
                                                onChange={e => setEmail(e.currentTarget.value)}
                                                placeholder='user123'
                                            />
                                        </InputGroup>
                                    </FormControl>
                                }
                                <FormControl isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdLockOutline} color='gray.400' />}
                                        />
                                        <Input
                                            type='password'
                                            value={password}
                                            onChange={e => setPassword(e.currentTarget.value)}
                                            placeholder='************'
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    isLoading={isLoading}
                                    loadingText='Submitting'

                                    // onClick={handleSubmit}
                                    colorScheme='teal'
                                    w='50%'
                                    isDisabled={!email || !password}
                                    type='submit'
                                    size='lg'
                                >
                                    SIGN IN
                                </Button>
                            </VStack>
                        </form>
                        {/* {error &&
                            <Text mt={2} textAlign='center' textColor='red.500'>
                                {error}
                            </Text>
                        } */}
                        <Text mt={2} textColor='blue.500' textAlign='center'>
                            {accountType === 'Admin' ?
                                <Link to="/forgot-password">
                                    Forgot password?
                                </Link>
                                :
                                'Forgot password? Have your admin reset it.'
                            }
                        </Text>
                    </Box>
                </VStack>
            </Box>
        </>
        // <div className="w-full min-h-screen bg-indigo-200 flex items-center justify-center px-5 py-5">
        //     {/* CARD */}
        //     <div className="bg-white text-gray-500 rounded-3xl shadow-xl max-w-md w-full overflow-hidden">
        //         <div className="md:flex w-full">
        //             <div className="w-full py-10 px-5 md:px-10">
        //                 {/* TITLE */}
        //                 <div className="text-center mb-10">
        //                     <h1 className="font-bold text-3xl text-gray-900">
        //                         SIGN IN
        //                     </h1>
        //                     <p>Enter your information to sign in</p>
        //                 </div>
        //                 <div className="flex -mx-3">
        //                     <div className="w-full px-3 mb-5">
        //                         <label htmlFor="" className="text-xs font-semibold px-1">
        //                             Select account type
        //                         </label>
        //                         <div className="flex">
        //                             <div 
        //                                 className="w-full rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                             >
        //                                 <button className={`w-1/2 border-r-2 ${isAdmin && "bg-gray-200 text-gray-900"}`}
        //                                     onClick={() => setIsAdmin(true)}
        //                                 >
        //                                     Admin
        //                                 </button>
        //                                 <button className={`w-1/2 ${!isAdmin && "bg-gray-200 text-gray-900"}`}
        //                                     onClick={() => setIsAdmin(false)}
        //                                 >
        //                                     Cell
        //                                 </button>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 {/* INPUTS */}
        //                 <form onSubmit={handleSubmit} >
        //                     {isAdmin ?
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Email
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-email-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdOutlineEmail} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="email" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="johnsmith@example.com"
        //                                     value={email}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     :
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Username
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-account-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdPersonOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="text" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="anode_cell"
        //                                     value={email}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     }
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-6">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Password
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-lock-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdLockOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="password" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="************"
        //                                     value={password}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)} 
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* ERROR */}
        //                     <div className="mb-2 text-xs text-center text-red-500">
        //                         {error}
        //                     </div>
        //                     {/* SUBMIT */}
        //                     <div className="mt-6 flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <button type='submit' className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
        //                                 SIGN IN
        //                             </button>
        //                         </div>
        //                     </div>
        //                 </form>
        //                 {isAdmin ? 
        //                     <div>
        //                         {/* <div className="text-yellow-600 text-center">
        //                             <Link to="/register">
        //                                 Don't have an account? Register here
        //                             </Link>
        //                         </div> */}
        //                         <div className="mt-2 text-blue-600 text-center">
        //                             <Link to="/forgot-password">
        //                                 Forgot password?
        //                             </Link>
        //                         </div>
        //                     </div>
        //                     :
        //                     <div>
        //                         <div className="mt-2 text-blue-600 text-center">
        //                             Forgot password? Have your admin reset it.
        //                         </div>
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Signin;