import { Box, Flex, Stack, Heading, Table, Tbody, Tfoot, Th, Tr, Thead } from "@chakra-ui/react"
import { Link, Route, Switch, useRouteMatch } from "react-router-dom"
import ListCell from "../../components/admin/ListCell"
import useActiveSubDoc from "../../hooks/useActiveSubDoc"
import useCellListDoc, { CellIdName } from "../../hooks/useCellList"
import CellDoc from "./CellDoc"


const ManageCells = () => {
    // const { isLoading, userLoaded, currentUser } = useSelector((state: RootState) => state.user)
    // const { userLoaded, currentUser } = useSelector((state: RootState) => state.user)
    // const [ cellCount, setCellCount ] = useState<number>(5)
    // const [ activeCells, setActiveCells ] = useState<number>(0)
    // const [ changePassCell, setChangePassCell ] = useState<{cellId: string, cellName: string} | undefined>(undefined)
    // const [ error, setError ] = useState<string>('')
    const { path } = useRouteMatch()

    // const { clError, clLoading, cellListDoc } = useCellListDoc()
    const { cellListDoc } = useCellListDoc()
    // useEffect(() => console.log(cellListDoc, clLoading, clError), [cellListDoc, clError, clLoading])
    
    // const { getShiftsByDay } = useCalcs()
    // const { shifts } = useShifts('OU8EPF2WPueWcgHMbNEqueuxX9j2', 1651895473720, 1652174714000)
    // useEffect(() => console.log(getShiftsByDay(shifts)), [shifts])

    // const { subError, subLoading, activeSubDoc } = useActiveSubDoc()
    const { activeSubDoc } = useActiveSubDoc()
    // useEffect(() => console.log(!subLoading, !activeSubDoc.id, activeSubDoc, subLoading, subError), [activeSubDoc, subError, subLoading])

    // const renderCells = () => {
    //     cellListDoc.all_cells.map((cell: any, key: any) => {
    //         const cellId = Object.keys(cell)[0]
    //         const username = cell[cellId]
    //         // const status = currentUser.subscriptions[0]?.active_users.includes(cellId)
    //         const status = cellListDoc.active_users.includes(cellId)
    //         return <ListCell 
    //                     name={username} 
    //                     isActive={status} 
    //                     cellId={cellId} 
    //                     setChangePassCell={setChangePassCell} 
    //                     // setError={setError} 
    //                     key={key}
    //                 />
    //     })
    // }

    // useEffect(() => {
    //     // if (userLoaded) {
    //     //     setCellCount(currentUser?.cells?.length)
    //     //     if (currentUser?.active_users) {
    //     //         setActiveCells(currentUser?.active_users.length)
    //     //     } else {
    //     //         setActiveCells(0)
    //     //     }
    //     // }
    //     if (cellListDoc.all_cells.length !== 0) {
    //         setCellCount(cellListDoc.all_cells.length)
    //     }
    //     // setActiveCells(cellListDoc.active_users.length)
    // }, [cellListDoc])

    return (
        <Switch>
            <Route path={`${path}/:cellId`} render={() => <CellDoc />} />
            <Route path={`${path}`} render={() =>
                <>
                    <Flex flex='1' overflow='auto' h='100vh' flexDir='column' bg='gray.100'>
                        <Stack
                            // divider={<StackDivider borderColor="gray.200" />}
                            spacing={12}
                            py={8}
                            mx={8}
                            maxW='2xl'
                            align='center'
                            alignSelf='center'
                        >
                            <Box w='full'>
                                <Heading size='lg' mx={12} textAlign='center' textTransform='uppercase' letterSpacing='wider'>
                                    Manage cells
                                </Heading>
                            </Box>
                            <Box
                                w='full'
                                boxShadow='base'
                                borderRadius="lg"
                                p={2}
                                bg='white'
                            >
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>
                                                Name
                                            </Th>
                                            <Th textAlign='center'>
                                                Status
                                            </Th>
                                            <Th/>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {cellListDoc.all_cells?.map((cell: CellIdName, idx: number) => {
                                            // const cellId = Object.keys(cell)[0]
                                            // const username = cell[cellId]
                                            const status = cellListDoc.active_users.includes(cell.id)
                                            return <ListCell
                                                name={cell.name}
                                                isActive={status}
                                                cellId={cell.id}
                                                // setError={setError} 
                                                key={idx}
                                            />
                                        })}
                                    </Tbody>
                                    <Tfoot>
                                        <Tr>
                                            <Th textTransform='lowercase'>
                                                <Link to='/admin-register'>
                                                    + add user
                                                </Link>
                                            </Th>
                                            <Th textAlign='center'>
                                                {`${cellListDoc.active_users.length} / ${activeSubDoc.quantity}`}
                                            </Th>
                                        </Tr>
                                    </Tfoot>
                                </Table>
                            {/* <table className="w-full mt-12 border rounded-md border-gray-200 shadow-lg divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        <th className="px-6 py-3 ">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                        <th className="px-6 py-3 ">
                                            <span className="sr-only">Delete</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {cellListDoc.all_cells?.map((cell: any, key: any) => {
                                        const cellId = Object.keys(cell)[0]
                                        const username = cell[cellId]
                                        // const status = currentUser.subscriptions[0]?.active_users.includes(cellId)
                                        const status = cellListDoc.active_users.includes(cellId)
                                        return <ListCell
                                            name={username}
                                            isActive={status}
                                            cellId={cellId}
                                            // setError={setError} 
                                            key={key}
                                        />
                                    })}
                                    <tr className="bg-gray-50">
                                        <td
                                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                                        // onClick={() => console.log('adding user')}
                                        >
                                            <Link to='/admin-register'>
                                                + add user
                                            </Link>
                                        </td>
                                        <td
                                            className="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider"
                                        >
                                            {`${cellListDoc.active_users.length} / ${activeSubDoc.quantity}`}
                                        </td>
                                        <td />
                                        <td />
                                    </tr>
                                </tbody>
                            </table> */}
                            </Box>
                        </Stack>
                    </Flex>
                    {/* <Box flex='1' >
                        <div className="flex flex-col items-center">
                            <div className="mt-12 text-center text-2xl font-bold text-gray-800 uppercase tracking-wider">
                                Manage cells
                            </div>
                            <div>
                                <table className="mx-auto mt-12 shadow-lg divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                            <th className="px-6 py-3 ">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                            <th className="px-6 py-3 ">
                                                <span className="sr-only">Delete</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {cellListDoc.all_cells?.map((cell: any, key: any) => {
                                            const cellId = Object.keys(cell)[0]
                                            const username = cell[cellId]
                                            // const status = currentUser.subscriptions[0]?.active_users.includes(cellId)
                                            const status = cellListDoc.active_users.includes(cellId)
                                            return <ListCell
                                                name={username}
                                                isActive={status}
                                                cellId={cellId}
                                                // setError={setError} 
                                                key={key}
                                            />
                                        })}
                                        <tr className="bg-gray-50">
                                            <td
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider cursor-pointer"
                                            // onClick={() => console.log('adding user')}
                                            >
                                                <Link to='/admin-register'>
                                                    + add user
                                                </Link>
                                            </td>
                                            <td
                                                className="px-6 py-3 text-center text-xs font-medium text-gray-500 tracking-wider"
                                            >
                                                {`${cellListDoc.active_users.length} / ${activeSubDoc.quantity}`}
                                            </td>
                                            <td />
                                            <td />
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Box> */}
                </>
            } />
        </Switch>
    )
}

export default ManageCells