import { Box, Heading, Button, Flex, VStack, Text, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { RootState } from "../../redux/store";
import { firestore } from "../../utils/firebase";

const Subscription = () => {
    //const [ products, setProducts ] = useState<object>([])
    const [quantity, setQuantity] = useState<number | undefined>(1)
    const user = useSelector((state: RootState) => state.user);
    const currentUser = user.currentUser
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // const dispatch = useDispatch()
    // let history = useHistory()

    // const [ isSub, setIsSub ] = useState<boolean>(false)

    // useEffect(() => {
    //     async function sub() {
    //     let res = await getIsSub(currentUser?.id ? currentUser.id : 'error')
    //     setIsSub(res)
    //     }
    //     sub()
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    // useEffect(() => {
    //     if (isSub) {
    //         history.push('/')
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isSub])

    // Fetches products and prices for dynamic naming and pricing
    // useEffect(() => {
    //     firestore.collection('products')
    //     .where('active', '==', true)
    //     .get()
    //     .then(snapshot => {
    //         console.log('products snapshot',snapshot)
    //         // fix typing
    //         const products: any = {};
    //         snapshot.forEach(async productDoc => {
    //             products[productDoc.id] = productDoc.data();
    //             const priceSnap = await productDoc.ref.collection("prices").get();
    //             console.log('rpiceSnap', priceSnap)
    //             priceSnap.forEach(priceDoc => {
    //                 products[productDoc.id].prices = {
    //                     priceId: priceDoc.id,
    //                     priceData: priceDoc.data()
    //                 }
    //             })
    //         })
    //         setProducts(products)
    //         console.log('products', products)
    //     })
    // }, [])

    // useEffect(() => {
    //     user.isLoading && <Loading />
    // }, [user.isLoading])


    if (isLoading) {
        return <Loading />
    }

    // priceId as parameter for dynamic
    const loadCheckout = async () => {
        if (quantity !== undefined && quantity > 0 && quantity <= 200) {
            const docRef = await firestore
                .collection('users')
                .doc(currentUser?.id)
                .collection('checkout_sessions')
                .add({
                    automatic_tax: true, // Automatically calculate tax based on the customer's address
                    tax_id_collection: true, // Collect the customer's tax ID (important for B2B transactions)
                    price: 'price_1KfensI7nQ8tpOMZSmvp1HLG',
                    quantity: quantity, // set number of cells
                    allow_promotion_codes: true,
                    success_url: window.location.origin,
                    cancel_url: window.location.origin,
                });
            // Wait for the CheckoutSession to get attached by the extension
            docRef.onSnapshot(async (snap) => {
                // const { error, sessionId } = snap.data();
                const error = snap.data()?.error
                // const sessionId = snap.data()?.sessionId
                const url = snap.data()?.url
                if (error) {
                    // Show an error to your customer and 
                    // inspect your Cloud Function logs in the Firebase console.
                    alert(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            });
        }
    }

    // limit quantity >= 1
    if (quantity !== undefined && Number(quantity) <= 0) {
        setQuantity(undefined) // quantity empty
    }

    return (
        // <div className='w-full'>
        // {/* For dynamic naming and pricing - Fix typing of productData */}
        // {/* {Object.entries(products).map(([productId, productData]: [string, any]) => {
        //     return (
        //         <div className="flex justify-between px-16" key={productId}>
        //             <div>
        //                 {productData.name} - {productData.description}
        //             </div>
        //             <button>subscribe</button>
        //         </div>
        //     )
        // })}    */}
        // {/* <div className="flex justify-center py-4">
        //     <button
        //         className="btn-blue"
        //         onClick={() => sendToCustomerPortal()}
        //     >
        //         Manage Subscription
        //     </button>
        // </div> */}
        // {/* <div className="mt-12 text-center text-2xl font-bold text-gray-800 uppercase tracking-wider">
        //         Subscribe to get started
        //     </div> */}
        <>
            <Flex flex='1' overflow='auto' h='100vh' flexDir='column' bg='gray.50'>
                <VStack
                    // divider={<StackDivider borderColor="gray.200" />}
                    spacing={12}
                    py={8}
                    mx={8}
                    maxW='2xl'
                    align='center'
                    alignSelf='center'
                >
                    <Box w='full'>
                        <Heading size='lg' mx={12} textAlign='center' textTransform='uppercase' letterSpacing='wider'>
                            Subscribe to get started
                        </Heading>
                    </Box>
                    <VStack bg='white' w='full' py={8} spacing={16} border='1px' borderColor='gray.200' borderRadius='md'>
                        <Heading>
                            Takt Timer
                        </Heading>
                        <Flex>
                            <Text fontSize='4xl' textColor='gray.800' fontWeight='bold' alignSelf='flex-end'>
                                $100
                            </Text>
                            <Text fontSize='lg' textColor='gray.500' alignSelf='flex-end' mb={2}>
                                /cell/month
                            </Text>
                        </Flex>
                        <Box textAlign='center'>
                            <Text fontSize='xl' textColor='gray.800' fontWeight='bold'>
                                Number of cells
                            </Text>
                            <Flex my={4} boxShadow='base' rounded='md'>
                                <Button
                                    borderRightRadius='none'
                                    border='1px'
                                    borderRight='none'
                                    borderColor='gray.200'
                                    onClick={() => quantity !== undefined ? setQuantity(quantity - 1) : null}
                                >-</Button>
                                <Input
                                    flex={1}
                                    onChange={e => e.currentTarget.validity.valid ? setQuantity(Number(e.currentTarget.value)) : null}
                                    type="number"
                                    pattern="[0-9]"
                                    max="200"
                                    value={quantity !== undefined ? String(quantity) : ""}
                                    borderRadius='none'
                                    textAlign='center'
                                    w='16'
                                    borderColor='gray.200'
                                />
                                <Button
                                    // {...inc} 
                                    borderLeftRadius='none'
                                    border='1px'
                                    borderLeft='none'
                                    borderColor='gray.200'
                                    onClick={() => quantity !== undefined ? setQuantity(quantity + 1) : setQuantity(1)}
                                >+</Button>
                            </Flex>
                            <Flex justify='center'>
                                <Text fontSize='xl' textColor='gray.800' fontWeight='bold' alignSelf='flex-end'>
                                    Total: ${quantity !== undefined ? quantity * 100 : 0}
                                </Text>
                                <Text fontSize='md' textColor='gray.500' alignSelf='flex-end' mb={1}>
                                    /month
                                </Text>
                            </Flex>
                        </Box>

                        <Button
                            colorScheme='teal'
                            onClick={async () => {
                                setIsLoading(true)
                                await loadCheckout()
                                // setIsLoading(false)
                            }}
                            disabled={quantity === undefined}
                            w='50%'
                        >
                            Subscribe
                        </Button>
                    </VStack>
                </VStack>
            </Flex>
        </>

    //                 {/* <div className="bg-white dark:bg-gray-800">
    //                     <div className="px-6 py-8">
    //                         <div className="flex flex-col items-center justify-center space-y-8 lg:-mx-4 lg:flex-row lg:items-stretch lg:space-y-0">
    //                             <div className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-800 dark:border-gray-700">
    //                                 <div className="flex-shrink-0">
    //                                     <h2 className="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700">
    //                                         Takt Timer
    //                                     </h2>
    //                                 </div>
    //                                 <div className="flex-shrink-0">
    //                                     <span
    //                                         className="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100"
    //                                     >
    //                                         $100
    //                                     </span>
    //                                     <span className="text-gray-500 dark:text-gray-400">
    //                                         /cell/month
    //                                     </span>
    //                                 </div>
    //                                 <ul className="flex-1 space-y-4">
    //                                     <li className="text-gray-500 dark:text-gray-400">
    //                                         Cycle time counter
    //                                     </li>
    //                                     <li className="text-gray-500 dark:text-gray-400">
    //                                         Displays planned vs actual
    //                                     </li>
    //                                     <li className="text-gray-500 dark:text-gray-400">
    //                                         Track down times & changeovers
    //                                     </li>
    //                                 </ul>
    //                                 <div className="flex flex-col justify-center">
    //                                     <span
    //                                         className="py-2 text-xl font-bold text-gray-700 dark:text-gray-200"
    //                                     >
    //                                         Number of cells
    //                                     </span>
    //                                     <div className="flex justify-center">
    //                                         <button
    //                                             className="text-center w-10 font-semibold text-md rounded-l cursor-pointer border border-gray-400"
    //                                             onClick={() => quantity !== undefined ? setQuantity(quantity - 1) : null}
    //                                         >
    //                                             -
    //                                         </button>
    //                                         <input
    //                                             onChange={e => e.currentTarget.validity.valid ? setQuantity(Number(e.currentTarget.value)) : null}
    //                                             type="number"
    //                                             pattern="[0-9]"
    //                                             max="200"
    //                                             className='no-spin border-t border-b border-gray-400 text-center w-16 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700'
    //                                             value={quantity !== undefined ? String(quantity) : ""}
    //                                         />
    //                                         <button
    //                                             className="text-center w-10 font-semibold text-md rounded-r cursor-pointer border border-gray-400"
    //                                             onClick={() => quantity !== undefined ? setQuantity(quantity + 1) : setQuantity(1)}
    //                                         >
    //                                             +
    //                                         </button>
    //                                     </div>
    //                                     <div className="py-4">
    //                                         <span
    //                                             className="text-xl font-bold text-gray-700 dark:text-gray-200"
    //                                         >
    //                                             Total: ${quantity !== undefined ? quantity * 100 : 0}
    //                                         </span>
    //                                         <span className="text-gray-500 dark:text-gray-400">
    //                                             /month
    //                                         </span>
    //                                     </div>
    //                                 </div>
    //                                 <Button
    //                                     colorScheme='teal'
    //                                     onClick={async () => {
    //                                         setIsLoading(true)
    //                                         await loadCheckout()
    //                                         // setIsLoading(false)
    //                                     }}
    //                                     disabled={quantity === undefined}
    //                                 >
    //                                     Subscribe
    //                                 </Button> */}
    // {/* <button
    //                             // className="inline-flex items-center justify-center px-4 py-2 font-semibold text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"
    //                             className="btn-blue mx-6"
    //                             onClick={async () => {
    //                                 setIsLoading(true)
    //                                 await loadCheckout()
    //                                 // setIsLoading(false)
    //                             }}
    //                             disabled={quantity === undefined}
    //                         >
    //                             Subscribe
    //                         </button> */}
    // {/* </div> */ }
    // {/* Second card for DB */ }
    // {/* <div className="flex flex-col w-full max-w-sm p-8 space-y-8 text-center bg-white border-2 border-gray-200 rounded-lg lg:mx-4 dark:bg-gray-800 dark:border-gray-700">
    //                         <div className="flex-shrink-0">
    //                             <h2
    //                                 className="inline-flex items-center justify-center px-2 font-semibold tracking-tight text-blue-400 uppercase rounded-lg bg-gray-50 dark:bg-gray-700"
    //                             >
    //                                 Timer + Database
    //                             </h2>
    //                         </div>
    //                         <div className="flex-shrink-0">
    //                             <span
    //                                 className="pt-2 text-4xl font-bold text-gray-800 uppercase dark:text-gray-100"
    //                             >
    //                                 $50
    //                             </span>
    //                             <span className="text-gray-500 dark:text-gray-400">
    //                                 /cell/month
    //                             </span>
    //                         </div>
    //                         <ul className="flex-1 space-y-4">
    //                             <li className="text-gray-500 dark:text-gray-400">
    //                                 Everything the Timer plan has
    //                             </li>
    //                             <li className="text-gray-500 dark:text-gray-400">
    //                                 Save timer data
    //                             </li>
    //                             <li className="text-gray-500 dark:text-gray-400">
    //                                 Data visualization
    //                             </li>
    //                             <li className="text-gray-500 dark:text-gray-400">
    //                                 Unlimited managers
    //                             </li>
    //                         </ul>

    //                         <button
    //                             className="inline-flex items-center justify-center px-4 py-2 font-semibold text-white uppercase transition-colors bg-blue-500 rounded-lg hover:bg-blue-700 focus:outline-none"
    //                         >
    //                             Coming Soon...
    //                         </button>
    //                     </div> */}

    // {/* </div>
    //                     </div>
    //                 </div> */}
    // {/* <div className="flex justify-center py-4">
    //             <button
    //                 className="btn-blue"
    //                 onClick={async () => {
    //                     const updateCellStatus = functions.httpsCallable('updateCellStatus');
    //                     const result = await updateCellStatus({
    //                         cellId:"bKVuzYUPGaNxu89zsN8xdsaqZ8t2", 
    //                         isActive: true
    //                     })
    //                     console.log(result)
    //                 }}
    //             >
    //                 Test deactivate
    //             </button>
    //         </div>
    //         <div className="flex justify-center justify-evenly py-4">
    //             <button
    //                 className="btn-blue"
    //                 // onClick={() => dispatch()}
    //             >
    //                 Add User
    //             </button>
    //             <button
    //                 className="btn-blue"
    //                 // onClick={() => dispatch()}
    //             >
    //                 Remove User
    //             </button>
    //         </div> */}
    // {/* <div className="flex justify-center justify-evenly py-4">
    //             <button
    //                 className="btn-blue"
    //                 onClick={() => dispatch(getSubUsersThunk({currentUser: currentUser, plan: PlanType.BASIC}))}
    //             >
    //                 Get Basic Sub Users
    //             </button>
    //             <button
    //                 className="btn-blue"
    //                 onClick={() => dispatch(getSubUsersThunk({currentUser: currentUser, plan: PlanType.PREMIUM}))}
    //             >
    //                 Get Premium Sub Users
    //             </button>
    //         </div> */}
    // {/* </div> */ }
    )
}

export default Subscription