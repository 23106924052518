import { Box, Heading, Button, Text, Flex } from "@chakra-ui/react"
import { Link } from "react-router-dom"

const NotFound = () => {
    return (
        <>
            <Flex w='full' h='92vh' align='center' textAlign="center" py={10} px={6}>
                <Box mx='auto'>
                    <Heading
                        display="inline-block"
                        as="h2"
                        size="2xl"
                        bgGradient="linear(to-r, teal.400, teal.600)"
                        backgroundClip="text"
                    >
                        404
                    </Heading>
                    <Text fontSize="18px" mt={3} mb={2}>
                        Page Not Found
                    </Text>
                    <Text color={'gray.500'} mb={6}>
                        The page you're looking for does not seem to exist
                    </Text>
                    <Link to='/'>
                        <Button
                            colorScheme="teal"
                            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
                            color="white"
                            variant="solid"
                            size='lg'
                        >
                            Go to Home
                        </Button>
                    </Link>
                </Box>
            </Flex>
        </>
        // <div className="w-full bg-blue-200">
        //     <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        //         <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
        //             <div className="border-t border-gray-200 text-center pt-8">
        //                 <h1 className="text-5xl font-bold text-blue-400">
        //                     404
        //                 </h1>
        //                 <h1 className="text-2xl font-medium py-8 px-12">
        //                     Oops! Page not found
        //                 </h1>
        //                 {/* <p className="text-xl pb-8 px-12 font-medium">
        //                     Oops! The page you are looking for does not exist. It might have been moved or deleted.
        //                 </p> */}
        //                 <Link to="/" className="bg-blue-500 text-white font-semibold px-6 py-3 rounded-md">
        //                     HOME
        //                 </Link>
        //                 {/* <button className="bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white font-semibold px-6 py-3 rounded-md">
        //                     Contact Us
        //                 </button> */}
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}

export default NotFound