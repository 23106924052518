import { Badge, IconButton, Skeleton, Td, Tr, useToast } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { functions } from "../../utils/firebase";

interface IListCell {
    name: string,
    isActive: boolean,
    cellId: string,
}

const ListCell = ({ name, isActive, cellId }: IListCell) => {
    // const dispatch = useDispatch()
    // const { isLoading } = useSelector((state: RootState) => state.user)
    const [loading, setLoading] = useState<boolean>(false)
    // const [ isChangePass, setIsChangePass ] = useState<boolean>(false)

    const toast = useToast()

    let activeColor = isActive ? 'green' : 'gray'

    const toggleActive = async () => {
        try {
            setLoading(true)
            const updateCellStatus = functions.httpsCallable('updateCellStatus');
            await updateCellStatus({
                cellId: cellId,
                isActive: isActive
            })
            setLoading(false)
            // re-fetch active subscribers
            //
            // if (currentUser) {
            //     dispatch(getActiveSubsThunk(currentUser.id))
            //     dispatch(getCellListThunk(currentUser.id))
            // }
        } catch (error: any) {
            setLoading(false)
            if (error.code === 'invalid-argument') {
                toast({
                    title: "You've reached your limit of active users. You can add more users in billing",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                })
            }
            console.log(error) // TODO: handle error
        }
    }

    // const deleteCell = async () => {
    //     try {
    //         setLoading(true)
    //         const deleteCell = functions.httpsCallable('deleteCell');
    //         await deleteCell({
    //             cellId: cellId, 
    //             cellName: name,
    //             adminUID: currentUser?.id
    //         })
    //         setLoading(false)
    //         // re-fetch active subscribers
    //         //
    //         // if (currentUser) {
    //         //     dispatch(getActiveSubsThunk(currentUser.id))
    //         //     dispatch(getCellListThunk(currentUser.id))
    //         // }
    //     } catch (err) {
    //         setLoading(false)
    //         console.log(err) 
    //     }
    // }

    if (loading) {
        return (
            <Tr>
                <Td>
                    <Skeleton height='32px' />
                </Td>
                <Td>
                    <Skeleton height='32px' />
                </Td>
                <Td>
                    <Skeleton height='32px' />
                </Td>
            </Tr>
        )
    }

    return (
        <Tr>
            <Td>
                {name}
            </Td>
            <Td textAlign='center' onClick={toggleActive}>
                {
                    // isActive ? 
                    <Badge
                        variant="subtle"
                        cursor='pointer'
                        borderRadius="full"
                        lineHeight='shorter'
                        px={2}
                        py={1}
                        colorScheme={activeColor}
                        // fontSize='xs'
                        fontWeight='semibold'
                        textTransform='capitalize'
                    >
                        {isActive ? 'Active' : 'Inactive'}
                    </Badge>
                    // : 
                    // <Badge variant="subtle" cursor='pointer'>
                    //     Inactive
                    // </Badge> 
                }
            </Td>
            <Td>
                <Link to={`/manage-cells/${cellId}`} >
                    <IconButton size="sm" ml={12} icon={<EditIcon />} aria-label='edit-cell-doc' />
                </Link>
            </Td>
        </Tr>
        // {/* <tr className='w-full'>
        //     <td className="px-8 py-2 text-left">{name}</td>
        //     <td className="px-8 py-2 text-center">
        //         <div 
        //             className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${activeIcon} cursor-pointer`}
        //             onClick={() => toggleActive()}
        //         >
        //             { isActive ? 'Active' : 'Inactive' }
        //         </div>
        //     </td>
        //     <td className=" py-4 whitespace-nowrap text-right text-sm font-medium">
        //         <Flex justify='flex-end' >
        //             <Link 
        //                 className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
        //                 to={`/manage-cells/${cellId}`}
        //             >
        //                 <IconButton size="sm" icon={<EditIcon />} aria-label='edit-cell-doc' />
        //             </Link>
        //         </Flex>
        //     </td>
        // </tr> */}
    )
}

export default ListCell