import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { BatchData, DownData, PartData, PeopleData, RejectData, StoppedData } from "../redux/timerSlice";
import { firestore } from "../utils/firebase"

interface Data {
    error: Error | null;
    loading: boolean;
    shifts: Array<ShiftData>;
}

export type UnitQuery = 'week' | 'month' | ''
export type RelativeTimeQuery = 'this' | 'last' | ''

export interface ShiftsQuery {
    cellId: string
    cellName: string
    relativeTime: RelativeTimeQuery
    unit: UnitQuery
    startTime: number
    endTime: number
}

export interface ShiftData {
    shiftId: string,
    shiftName: string,
    shiftNotes: string,
    shiftStartTime: number,
    shiftEndTime: number,
    plannedParts: number,
    actualParts: number,
    partRecords: Array<PartData>,
    peopleRecords: Array<PeopleData>,
    rejectRecords: Array<RejectData>,
    stoppedRecords: Array<StoppedData>,
    downRecords: Array<DownData>,
    batchRecords: Array<BatchData>,
    hourRecords: Array<HourData>,
}

export interface HourData {
    startTime: number,
    entTime: number,
    plan: number,
    actual: number,
    rejects: number,
    totalDown: number,
    totalChange: number,
    totalStopped: number
}

const initData: Data = {
    error: null,
    loading: true,
    shifts: []
}

const useShifts = (query: ShiftsQuery) => {
    const [data, setData] = useState<Data>(initData)
    const currentUser = useSelector((state: RootState) => state.user.currentUser)

    let adminId: string
    let cellId: string
    if (currentUser) {
        adminId = `${currentUser?.id}`
        cellId = query.cellId
    }

    const hasQueried = (): boolean => {
        return query.cellId !== '' && query.startTime !== 0 && query.endTime !== 0
    }

    useEffect(() => {
        if (currentUser && hasQueried()) {
            const unsubscribe = firestore
                .collection(`users/${adminId}/cells/${cellId}/shifts`)
                .where('shiftStartTime', '>=', query.startTime)
                .where('shiftStartTime', '<=', query.endTime)
                .onSnapshot(querySnapshot => {  // TODO: change to .get() rather than snapshot??
                    const newShifts: Array<ShiftData> = []
                    querySnapshot.forEach(doc => {
                        if (doc.exists) {
                            const { shiftId,
                                shiftName,
                                shiftNotes,
                                shiftStartTime,
                                shiftEndTime,
                                plannedParts,
                                actualParts,
                                partRecords,
                                peopleRecords,
                                rejectRecords,
                                stoppedRecords,
                                downRecords,
                                batchRecords,
                                hourRecords,
                            }: any = doc.data()

                            newShifts.push({
                                shiftId: shiftId || 0,
                                shiftName: shiftName || '',
                                shiftNotes: shiftNotes || '',
                                shiftStartTime: shiftStartTime || 0,
                                shiftEndTime: shiftEndTime || 0,
                                plannedParts: plannedParts || 0,
                                actualParts: actualParts || 0,
                                partRecords: partRecords || [],
                                peopleRecords: peopleRecords || [],
                                rejectRecords: rejectRecords || [],
                                stoppedRecords: stoppedRecords || [],
                                downRecords: downRecords || [],
                                batchRecords: batchRecords || [],
                                hourRecords: hourRecords || [],
                            })
                            // var source = doc.metadata.fromCache ? "local cache" : "server";
                            // console.log("Data came from " + source);
                        }
                    })
                    setData({
                        error: null,
                        loading: false,
                        shifts: newShifts
                    })
                },
                    (error) => {
                        setData({
                            error: error,
                            loading: false,
                            shifts: []
                        })
                    })

            return unsubscribe
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query])

    return data
}

export default useShifts