import { Flex, Text, useMediaQuery, Select, VStack } from '@chakra-ui/react';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { RejectData, DownData, setRejectCode, setRejectNote, setDownCode, setDownNote } from '../../redux/timerSlice';
import { formatSeconds } from '../../utils';
import NoteModal from './NoteModal';

interface IDRInfo {
    type: 'down' | 'reject',
    record: RejectData | DownData | 'dummy',  // dummy is for spacing, doesn't show
    index: number,
    selectCodes: Array<string>,
}

const DRInfo = ({ type, record, index, selectCodes }: IDRInfo) => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)

    const dispatch = useDispatch()

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let textSize: string = 'md'
    let selectSize: 'sm' | 'md' | 'lg' = 'sm'
    let editButtonSize: string = 'xs'

    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        textSize = 'md'
        selectSize = 'sm'
        editButtonSize = 'xs'
    }
    if (smH) {  // 480px
        textSize = 'xl'
        selectSize = 'sm'
        editButtonSize = 'sm'
    }
    if (mdH) {  // 768px
        textSize = '2xl'
        selectSize = 'md'
        editButtonSize = 'md'
    }
    if (lgH) {  // 992px
        textSize = '4xl'
        selectSize = 'lg'
        editButtonSize = 'lg'
    }
    if (xlH) {  // 1280px
        textSize = '4xl'
        selectSize = 'lg'
        editButtonSize = 'lg'
    }

    // const [selectedOption, setSelectedOption] = useState<SingleValue<SelectOptions>>()
    // const [options, setOptions] = useState<Array<SelectOptions>>()

    // const getOptions = () => {
    //     const codeOptions: Array<SelectOptions> = []
    //     selectCodes.forEach((code: string) => {
    //         codeOptions.push({
    //             value: code,
    //             label: code,
    //         })
    //     })
    //     setOptions(codeOptions)
    // }

    // useEffect(() => {
    //     getOptions()
    // }, [selectCodes])

    // Auto focus on Select Code - autoFocus messed up with key binding
    const selectRef = useRef<any>(false)
    useEffect(() => {
        if (record !== 'dummy') {
            selectRef.current.focus()
            selectRef.current.scrollIntoView()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index])

    const changeCode = (code: string) => {
        if (type === 'down') {
            dispatch(setDownCode({
                index: index,
                code: code
            }))
        } else if (type === 'reject') {
            dispatch(setRejectCode({
                index: index,
                code: code
            }))
        }
    }

    const changeNote = (note: string) => {
        if (type === 'down') {
            dispatch(setDownNote({
                index: index,
                note: note
            }))
        } else if (type === 'reject') {
            dispatch(setRejectNote({
                index: index,
                note: note
            }))
        }
    }

    if (record === 'dummy') {
        return (
            <VStack spacing={2} alignItems='flex-start' mx={4} opacity={0} cursor='default'>
                <Flex>
                    <Text textTransform='capitalize' fontSize={textSize} >
                        0
                    </Text>
                    <Flex flex='1' ml='2' alignSelf='center'>
                        <Select
                            key={index}
                            pointerEvents='none'
                            placeholder='Select code'
                            size={selectSize}
                        />
                    </Flex>
                </Flex>
                {type === 'down' &&
                    <Flex w='full' justifyContent='space-between'>
                        <Text color='red.700' fontSize={textSize}>
                            0
                        </Text>
                        <Text fontSize={textSize}>
                            0
                        </Text>
                    </Flex>
                }
            </VStack>
        )
    }

    return (
        <VStack spacing={2} alignItems='flex-start' mx={4}>
            <Flex>
                <Text textTransform='capitalize' fontSize={textSize} textColor={isDown ? 'black' : ''} >
                    {type[0]}{index + 1} -
                </Text>
                <Flex flex='1' ml='2' alignSelf='center'>
                        {/* <ReactSelect
                            // w='15vw' (maybe down to 12?) - also reduce arrow margin (and height for small screen)
                            name="code"
                            options={options}
                            ref={selectRef}
                            onKeyDown={e => {
                                if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                    e.preventDefault()
                                }
                            }}
                            // loadOptions={getOptions}
                            placeholder="Select code..."
                            closeMenuOnSelect={false}
                            size={selectSize}
                            isClearable={false}
                            backspaceRemovesValue={false}
                            // value={selectedOption}
                            onChange={e => e && changeCode(e.value)}
                            onMenuOpen={() => dispatch(setIsEditingRedux(true))}
                            onMenuClose={() => dispatch(setIsEditingRedux(false))}
                        /> */}
                    <Select
                        key={index}
                        // autoFocus
                        ref={selectRef}
                        // onFocus={() => dispatch(setIsEditingRedux(true))}
                        // FIXME: onFocus is'nt right, want on open
                        // onMenuOpen={() => dispatch(setIsEditingRedux(true))}
                        // onMenuClose={() => dispatch(setIsEditingRedux(false))}
                        // onBlur={() => dispatch(setIsEditingRedux(false))}
                        onKeyDown={e => {
                            if (['a', 'b', 'c', 'd', 'r'].includes(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        placeholder='Select code'
                        size={selectSize}
                        onChange={e => changeCode(e.currentTarget.value)}
                        value={record.code}
                        bg='white'
                        disabled={!!shiftEndTime}
                    >
                        {selectCodes.map((code, index) => {
                            return <option value={code} key={index}>{code}</option>
                        })}
                    </Select>
                </Flex>
                <Flex ml={2} alignSelf='center'>
                    <NoteModal
                        size={editButtonSize}
                        title={type === 'down' ?
                            `Down Note - ${index + 1}`
                            :
                            `Reject Note - ${index + 1}`
                        }
                        value={record.note}
                        update={changeNote}
                    />
                </Flex>
            </Flex>
            {
                // Only Down - number of parts & duration
            }
            {'numParts' in record &&
                <Flex w='full' justifyContent='space-between'>
                    <Text color='red.700' fontSize={textSize}>
                        {record.numParts < 0 ?
                            0
                            :
                            Math.round(record.numParts * 10) / 10
                        }
                    </Text>
                    <Text fontSize={textSize} textColor={isDown ? 'black' : ''}>
                        {record.duration === -1 ?
                            formatSeconds(0)
                            :
                            formatSeconds(record.duration)
                        }
                    </Text>
                </Flex>
            }
        </VStack>
    )
}

export default DRInfo;