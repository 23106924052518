import { useState } from 'react'
import {
    Flex,
    Text,
    IconButton,
    Divider,
    Avatar,
    Heading,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Image
} from '@chakra-ui/react'
import {
    FiMenu,
    FiHome,
    FiCreditCard,
    FiUserPlus,
    FiSettings,
    FiBarChart2
} from 'react-icons/fi'
import AdminNavItem from './AdminNavItem'
import { useSelector } from 'react-redux';
import useActiveSubDoc from '../../hooks/useActiveSubDoc';
import { RootState } from '../../redux/store';
import { auth, sendToCustomerPortal } from '../../utils/firebase';
import Loading from '../Loading';
import { IconType } from 'react-icons'
import { useHistory } from 'react-router';
import DarkLogo from "../../assets/Dark-logo.svg"
import DarkLogoOnly from "../../assets/Dark-logo-only.svg"

interface LinkItemProps {
    name: string
    icon: IconType
    to: string
    click?: () => void
}

export default function AdminNav() {
    const { currentUser } = useSelector((state: RootState) => state.user);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [navSize, changeNavSize] = useState("large")
    const { activeSubDoc } = useActiveSubDoc()

    let history = useHistory()

    const loadBillingPortal = async () => {
        setIsLoading(true)
        await sendToCustomerPortal()
        setIsLoading(false)
    }

    const signout = async () => {
        await auth.signOut()
        history.push('/signin')
    }

    const LinkItems: Array<LinkItemProps> = [
        { name: 'Home', icon: FiHome, to: "/manage-cells" },
        { name: 'Insights', icon: FiBarChart2, to: "/insights" },
        { name: 'Settings', icon: FiSettings, to: "/settings" },
        { name: 'Add User', icon: FiUserPlus, to: "/admin-register" },
        { name: 'Billing', icon: FiCreditCard, to: '', click: loadBillingPortal },
    ]

    if (isLoading) {
        return <Loading />
    }

    return (
        <Flex
            pos="sticky"
            // ml={5}
            // h="95vh"
            // marginTop="2.5vh"
            // borderRadius={navSize === "small" ? "15px" : "30px"}
            minHeight="100vh"
            boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
            w={navSize === "small" ? "75px" : "200px"}
            flexDir="column"
            justifyContent="space-between"
        >
            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize === "small" ? "center" : "flex-start"}
                as="nav"
            >
                <IconButton
                    background="none"
                    mt={5}
                    _hover={{ background: 'none' }}
                    icon={<FiMenu />}
                    onClick={() => {
                        if (navSize === "small")
                            changeNavSize("large")
                        else
                            changeNavSize("small")
                    }}
                    aria-label='menu'
                />

                {/* <Text mt={2} p={3} fontSize="xl" fontWeight="bold">
                    {navSize === "large" ? 'Lean Systems' : 'LS'}
                </Text> */}
                <Image
                    maxH='6vh'
                    mt={8}
                    src={navSize === "large" ? DarkLogo : DarkLogoOnly}
                    alt="Lean Systems"
                />
                {activeSubDoc.id ?
                    LinkItems.map((link) => (
                        <AdminNavItem
                            key={link.name}
                            navSize={navSize}
                            name={link.name}
                            icon={link.icon}
                            to={link.to}
                            click={link.click}
                        />
                    ))
                    : <></>
                }
            </Flex>

            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize === "small" ? "center" : "flex-start"}
                mb={4}
            >
                <Divider display={navSize === "small" ? "none" : "flex"} />
                <Menu placement='right'>
                    <MenuButton>
                        <Flex ml={navSize === "small" ? 0 : 4} mt={4} align="center">
                            <Avatar size="sm" name={currentUser?.displayName} />
                            <Flex flexDir="column" ml={4} display={navSize === "small" ? "none" : "flex"}>
                                <Heading as="h3" size="sm">
                                    {currentUser?.displayName}
                                </Heading>
                                <Text color="gray" textTransform='capitalize'>
                                    {currentUser?.role}
                                </Text>
                            </Flex>
                        </Flex>
                    </MenuButton>
                    <MenuList>
                        <MenuItem 
                            textAlign='center' 
                            _focus={{ textDecor: 'none', cursor: 'pointer' }}
                            onClick={signout}
                            zIndex={100}
                        >
                            Sign out
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    )
}