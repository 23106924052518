import { useDisclosure, Popover, PopoverTrigger, PopoverContent, PopoverArrow, Box } from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import _ from "lodash"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useConfigCell from "../../hooks/useConfigCell"
import { RootState } from "../../redux/store"
import { setIsEditingRedux, setPeople } from "../../redux/timerSlice"
import HeaderData from "./HeaderData"

interface SelectOptions {
    value: string
    label: string
    colorScheme: string
}

const PeoplePopup = () => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const firstFieldRef = useRef<any>(null)
    const currentPeople = useSelector((state: RootState) => state.timer.peopleRecords.slice(-1)[0].peopleList)
    const endTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const [newPeopleList, setNewPeopleList] = useState<Array<string>>(currentPeople)
    const dispatch = useDispatch()

    const { configCell } = useConfigCell('cell')

    const [selectedOptions, setSelectedOptions] = useState<Array<SelectOptions>>()
    const [peopleOptions, setPeopleOptions] = useState<Array<SelectOptions>>()

    const getOptions = () => {
        const options: Array<SelectOptions> = []
        configCell.operators.forEach(o => options.push({
            value: o.code,
            label: o.code,
            colorScheme: 'green'
        }))
        setPeopleOptions(options)
    }

    const getSelectedOptions = () => {
        const options: Array<SelectOptions> = []
        currentPeople.forEach(person => options.push({
            value: person,
            label: person,
            colorScheme: 'green'
        }))
        setSelectedOptions(options)
    }

    useEffect(() => {
        getOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configCell])

    useEffect(() => {
        getSelectedOptions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPeople, configCell])

    const handleOpen = () => {
        onOpen()
        dispatch(setIsEditingRedux(true))
    }

    const handleClose = () => {
        const newPeople = selectedOptions?.map(o => o.value)
        if (!_.isEqual(_.sortBy(currentPeople), _.sortBy(newPeople))) {
            dispatch(setPeople({ peopleList: newPeople }))
        }
        dispatch(setIsEditingRedux(false))
        onClose()
    }

    return (
        <>
            <Popover
                isOpen={isOpen}
                initialFocusRef={firstFieldRef}
                onOpen={handleOpen}
                onClose={handleClose}
                placement="bottom-start"
            // closeOnBlur={false}
            // closeOnEsc={true}
            >
                {!endTime ?
                    <PopoverTrigger>
                        <Box cursor='pointer' bg={currentPeople.length === 0 ? 'red.200' : ''} borderRadius='md'>
                            <HeaderData label='PPL' data={String(currentPeople.length)} />
                        </Box>
                    </PopoverTrigger>
                    :
                    <HeaderData label='PPL' data={String(currentPeople.length)} />
                }
                <PopoverContent>
                    <PopoverArrow />
                    <Select
                        isMulti={true}
                        name="people"
                        ref={firstFieldRef}
                        options={peopleOptions}
                        placeholder="Select people..."
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        size="lg"
                        isClearable={false}
                        backspaceRemovesValue={false}
                        value={selectedOptions}
                        onChange={e => setSelectedOptions(e.map(o => ({ value: o.value, label: o.label, colorScheme: 'green' })))}
                    />
                </PopoverContent>
            </Popover>
        </>
    )
}

export default PeoplePopup