import { combineReducers, configureStore } from "@reduxjs/toolkit";

import timerReducer from "./timerSlice"
import userReducer from "./userSlice"
import sessionsReducer from "./sessionsSlice"

const store = configureStore({
    reducer: combineReducers({
        timer: timerReducer,
        user: userReducer,
        sessions: sessionsReducer
        // otherReducer: otherReducer
    }),
    // middleware: getDefaultMiddleware(), // this is redundant and for demonstration only
    // devTools: true // this is redundant and for demonstration only
    //preloadedState: {your state object for initialization or rehydration}
})

export default store;
export type RootState = ReturnType<typeof store.getState>