import { GridItem, Flex } from "@chakra-ui/layout"
import { ButtonGroup, Button, useMediaQuery, theme } from "@chakra-ui/react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCalcs from "../../hooks/useCalcs";
import useDownBorder from "../../hooks/useDownBorder";
import { RootState } from "../../redux/store";
import { changeDisplayType } from "../../redux/timerSlice";
import DecActualAlert from "./DecActualAlert";
import DispData from "./DispData";
import PieProgress from "../charts/PieProgress";


const Takt = () => {
    const displayTime = useSelector((state: RootState) => state.timer.displayTime)
    const shiftPlan = useSelector((state: RootState) => state.timer.plannedParts)
    const shiftActual = useSelector((state: RootState) => state.timer.actualParts)

    // const currentBatch = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0])
    const batchPlan = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.plan)
    const batchActual = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.actual)
    const batchGoal = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0]?.goalParts)
    const hourPlan = useSelector((state: RootState) => state.timer.hourRecords.slice(-1)[0]?.plan)
    const hourActual = useSelector((state: RootState) => state.timer.hourRecords.slice(-1)[0]?.actual)
    const displayType = useSelector((state: RootState) => state.timer.displayType)
    const dispatch = useDispatch()
    // const isDown = useSelector((state: RootState) => state.timer.isDown)

    const borderColor = useRef<string>('gray.200')
    const border = useRef<string>('1px')
    const bg = useRef<string>('white')
    useDownBorder({ borderColor, border, bg })

    // const [displayTime2, setDisplayTime2] = useState<number>(0)
    // const currentTime = useCurrentTime().getTime()
    // useEffect(() => {
    //     currentBatch &&
    //     setDisplayTime2(currentBatch.cycleTime - (Math.floor((currentTime - currentBatch.startTime) / 1000) % currentBatch.cycleTime))
    //     console.log(Math.floor((currentTime - currentBatch?.startTime) / 1000))
    // }, [currentTime])
    // const { 
    //     shiftPlan: shiftPlan2, 
    //     batchPlan: batchPlan2, 
    //     hourPlan: hourPlan2, 
    //     displayTime: displayTime2 
    // } = useTakt()

    const partsDisplay = {
        shift: {
            plan: Math.floor(shiftPlan),
            actual: shiftActual
        },
        batch: {
            plan: Math.floor(batchPlan),
            actual: batchActual
        },
        hour: {
            plan: Math.floor(hourPlan),
            actual: hourActual
        }
    }

    const { getCurrentPerformancePercent } = useCalcs()
    // const partsDisplay2 = {
    //     shift: {
    //         plan: shiftPlan2,
    //         actual: shiftActual
    //     },
    //     batch: {
    //         plan: batchPlan2,
    //         actual: batchActual
    //     },
    //     hour: {
    //         plan: hourPlan2,
    //         actual: hourActual
    //     }
    // }

    // useEffect(() => {
    //     if (isDown) {
    //         borderColor.current = 'orange.500'
    //         border.current = '4px'
    //     } else {
    //         borderColor.current = 'gray.200'
    //         border.current = '1px'
    //     }
    // }, [isDown])
    // const percentYield = Math.round(actualParts / (actualParts + rejectedParts) * 1000) / 10
    // const percentPlan = Math.round(actualParts / plannedParts * 1000) / 10

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let buttonSize: string = 'xs'

    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        buttonSize = 'xs'
    }
    if (smH) {  // 480px
        buttonSize = 'sm'
    }
    if (mdH) {  // 768px
        buttonSize = 'md'
    }
    if (lgH) {  // 992px
        buttonSize = 'lg'
    }
    if (xlH) {  // 1280px
        buttonSize = 'lg'
    }

    return (
        <>
            <GridItem
                overflow='hidden'
                display='flex'
                flexDir='column'
                alignItems='center'
                rounded='3xl'
                boxShadow='base'
                border={border.current}
                borderColor={borderColor.current}
                bg={bg.current}
            >
                {
                    // Display options
                }
                <ButtonGroup
                    size={buttonSize}
                    isAttached
                    variant='outline'
                    mx='auto'
                    mt={1}
                    w={3 / 4}
                >
                    <Button mr='-px' w={1 / 3} bg='white'
                        isActive={displayType === 'shift'}
                        onClick={() => {
                            dispatch(changeDisplayType('shift'))
                        }}
                    >
                        Shift
                    </Button>
                    <Button mr='-px' w={1 / 3} bg='white'
                        isActive={displayType === 'batch'}
                        onClick={() => {
                            dispatch(changeDisplayType('batch'))
                        }}
                    >
                        Batch
                    </Button>
                    <Button w={1 / 3} bg='white'
                        isActive={displayType === 'hour'}
                        onClick={() => {
                            dispatch(changeDisplayType('hour'))
                        }}
                    >
                        Hour
                    </Button>
                </ButtonGroup>
                {
                    // Timer data
                }
                <Flex
                    h='full'
                    w='full'
                    flexDir='column'
                    align='center'
                    justifyContent='space-evenly'
                >
                    <DispData data={displayTime === -1 ? 0 : displayTime} label={'seconds'} />
                    {/* <Flex w='full' justifyContent='space-evenly' align='center'>
                        <PieProgress
                            name=''
                            percent={getCurrentPerformancePercent()}
                            color={theme.colors.green[500]}
                            size='14vh'
                        />
                        <DispData data={displayTime === -1 ? 0 : displayTime} label={'seconds'} />
                    </Flex> */}


                    <Flex w='full' justifyContent='space-evenly'>
                        <DispData
                            data={
                                partsDisplay[displayType].plan === -1 ?
                                    0
                                    :
                                    partsDisplay[displayType].plan
                            }
                            label={'plan'}
                        />
                        {/* <DispData
                            data={String(partsDisplay2[displayType].plan)}
                            label={'plan2'}
                        /> */}
                        {displayType === 'batch' ?
                            <DispData
                                data={partsDisplay[displayType].actual}
                                label={'actual'}
                                button={<DecActualAlert lgH={lgH} />}
                                total={batchGoal}
                            />
                            :
                            <DispData
                                data={partsDisplay[displayType].actual}
                                label={'actual'}
                                button={<DecActualAlert lgH={lgH} />}
                            />
                        }
                    </Flex>

                    <PieProgress
                        name=''
                        percent={getCurrentPerformancePercent()}
                        color={theme.colors.green[500]}
                        size='14vh'
                    />

                    {/* <Flex flexDir='column' alignItems='center'>
                        <Heading size='3xl'>
                            {displayTime}
                        </Heading>
                        <Text fontSize='xl' textColor='gray.600'>
                            seconds
                        </Text>
                    </Flex>

                    <Flex flexDir='column' alignItems='center'>
                        <Flex alignItems='flex-end'>
                            <Heading size='3xl'>
                                {actualParts}
                            </Heading>
                            <Heading ml={1} size='2xl' textColor='gray.600'>
                                / {plannedParts}
                            </Heading>
                        </Flex>
                        <Text mt={2} fontSize='xl' textColor='gray.600'>
                            parts
                        </Text>
                    </Flex> */}
                </Flex>
            </GridItem>
        </>
    )
}

export default Takt