
// Formats seconds into hours:minutes:seconds
export const formatSeconds = (seconds: number): string => {
  const getSeconds: string = `0${(seconds % 60)}`.slice(-2)
  const minutes: number = Math.floor(seconds / 60)
  const getMinutes: string = `0${minutes % 60}`.slice(-2)
  const getHours: string  = `0${Math.floor(seconds / 3600)}`.slice(-2)

  return Number(getHours) ? 
    `${getHours}:${getMinutes}:${getSeconds}` 
    : 
    `${getMinutes}:${getSeconds}`
}

// Formats seconds into minutes
export const formatSecondsToMinutes = (seconds: number): string => {
  return String(Math.round(seconds / 60))
}

// Converts Timestamp to Date
export const formatDate = (date: Date): string => {
  return date.toDateString()
}

// Converts Timestamp to Time
export const formatDateTime = (date: Date): string => {
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute:'2-digit',
    // second: '2-digit'
  })
}

export const pairwise = (list: Array<string>): Array<Array<string>> => {
  if (list.length < 2) return [[list[0], list[0]]]
  const first = list[0],
        rest  = list.slice(1),
        pairs = rest.map(x => [first, x])
        pairs.push([first, first])
  return pairs.concat(pairwise(rest))
}