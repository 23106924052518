import { Flex, Grid, useDisclosure, useToast } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loading from "../../components/Loading"
import LeftCol from "../../components/timer/LeftCol"
import MidCol from "../../components/timer/MidCol"
import RightCol from "../../components/timer/RightCol"
import SetupModal from "../../components/timer/SetupModal"
import TimerHeader from "../../components/timer/TimerHeader"
import useTimer from "../../hooks/useTimer"
import { RootState } from "../../redux/store"
import { incActual, incRejected, startStopDownTime, changeDisplayType } from "../../redux/timerSlice"
import { firestore } from "../../utils/firebase"

const TimerMain = () => {
    const { currentUser } = useSelector((state: RootState) => state.user)
    const timerData = useSelector((state: RootState) => state.timer)
    const shiftId = useSelector((state: RootState) => state.timer.shiftId)
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const isEditing = useSelector((state: RootState) => state.timer.isEditingRedux)
    // const shiftStartTime = useSelector((state: RootState) => state.timer.shiftStartTime)
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const hourRecords = useSelector((state: RootState) => state.timer.hourRecords)
    
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const isWriting = useRef<boolean>(false)
    // const currentHour = useSelector((state: RootState) => state.timer.hourRecords.slice(-1)[0])
    const dispatch = useDispatch()

    // const borderColor = useRef<string>('gray.200')
    // const border = useRef<string>('1px')
    // const bg = useRef<string>('white')
    // useDownBorder({ bg })

    const toast = useToast()

    const writeShift = async () => {
        const shiftRef = firestore.doc(`users/${currentUser?.adminUID}/cells/${currentUser?.id}/shifts/${shiftId}`)
        try {
            setIsLoading(true)
            isWriting.current = true
            await shiftRef.set({
                shiftId: timerData.shiftId,
                shiftName: timerData.shiftName,
                shiftStartTime: timerData.shiftStartTime,
                shiftEndTime: timerData.shiftEndTime,
                shiftNotes: timerData.shiftNotes,
                plannedParts: timerData.plannedParts,
                actualParts: timerData.actualParts,
                // rejectedParts: timerData.rejectedParts, // rejectRecords.length
                partRecords: timerData.partRecords,
                batchRecords: timerData.batchRecords,
                hourRecords: timerData.hourRecords.map(hour => {
                    return {
                        startTime: hour.startTime,
                        endTime: hour.endTime,
                        plan: hour.plan,
                        actual: hour.actual,
                        rejects: hour.rejects,
                        totalDown: hour.totalDown.reduce((acc, down) => {
                            acc.duration += down.duration
                            acc.numParts += down.numParts
                            return acc
                        }, { duration: 0, numParts: 0 }),
                        totalChange: hour.totalChange.reduce((acc, change) => {
                            acc.duration += change.duration
                            acc.numParts += change.numParts
                            return acc
                        }, { duration: 0, numParts: 0 }),
                        totalStopped: hour.totalStopped.reduce((acc, num) => acc + num, 0)
                    }
                }),
                stoppedRecords: timerData.stoppedRecords,
                downRecords: timerData.downRecords,
                rejectRecords: timerData.rejectRecords,
                peopleRecords: timerData.peopleRecords,
            }, { merge: true })
            isWriting.current = false
            setIsLoading(false)
            toast({
                title: "Success! This shift has been saved.",
                status: "success",
                duration: 5000,
                isClosable: true
            })
        } catch (error) {
            // console.log(error)
            toast({
                title: "Oops, something went wrong saving this shift.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            isWriting.current = false
            setIsLoading(false)
        }
    }

    // > 5 seconds to writing, say no internet
    useEffect(() => {
        isWriting.current && setTimeout(() => {
            // console.log(isWriting.current)
            if (isWriting.current) {
                isWriting.current = false
                setIsLoading(false)
                toast({
                    title: "No internet! This shift will be saved when you reconnect.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true
                })
            }
        }, 5000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if (shiftEndTime) {
            writeShift()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shiftEndTime])

    const { isOpen: isOpenSetup, onOpen: onOpenSetup, onClose: onCloseSetup } = useDisclosure()

    useEffect(() => {
        onOpenSetup()
        setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const currentTime = useCurrentTime().getTime()
    // // Hourly update
    // useEffect(() => {
    //     // if (currentTime - currentHour?.startTime > 30000) {  // hour == 60 / 60 / 1000
    //     if (!shiftEndTime && 
    //         // Math.floor((currentTime - shiftStartTime) / 30000) > hourRecords.length - 1) {  // hour == 60 / 60 / 1000
    //         Math.floor((currentTime - shiftStartTime) / 60 / 60 / 1000) > hourRecords.length - 1) {  // hour == 60 / 60 / 1000
    //         dispatch(changeHour())
    //     }
    //     // if (!shiftEndTime) console.log(Math.floor((currentTime - shiftStartTime) / 30000))
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [currentTime])

    // const start = useTimer()
    useTimer()

    // Key bindings (not including edit)
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (!isEditing && !event.repeat) {
                if (event.key === 'Escape') {
                    if (document.activeElement instanceof HTMLElement) {
                        document.activeElement.blur()
                    }
                }

                if (event.key === ' ' && !isChange) {
                    event.preventDefault()
                }

                // CycleTime
                if (!isStopped && !isChange && !isDown) {
                    if (event.key === 'a' || event.key === ' ') {
                        dispatch(incActual())
                    } else if (event.key === 'r') {
                        dispatch(incRejected())
                    } else if (event.key === 'S') {
                        dispatch(changeDisplayType('shift'))
                    } else if (event.key === 'B') {
                        dispatch(changeDisplayType('batch'))
                    } else if (event.key === 'H') {
                        dispatch(changeDisplayType('hour'))
                    }
                }
                // DownTime
                if (!isStopped && !isChange) {
                    if (event.key === 'd') {
                        dispatch(startStopDownTime())
                    }
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDown, isChange, isStopped, isEditing])

    if (isLoading) {
        return <Loading />
    }

    return (
        <>
            <Flex flex='1' overflow='hidden' h='100vh' flexDir='column' >
                <Flex h='10vh' justifyContent='space-evenly' alignItems='center'>
                    <TimerHeader />
                </Flex>
                <Grid h='90vh' templateColumns='repeat(10, 1fr)' gap={2} p={2}>
                    <LeftCol />
                    <MidCol />
                    <RightCol />
                </Grid>
            </Flex>
            <SetupModal isOpen={isOpenSetup} onClose={onCloseSetup} />
        </>
    )
}

export default TimerMain