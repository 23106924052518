import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import { forwardRef } from "react"

interface ITextInput {
    [key: string]: any
}

const TextInput = forwardRef((props: ITextInput, ref: any) => {
    return (
      <FormControl>
        <FormLabel htmlFor={props.id}>{props.label}</FormLabel>
        <Input 
            ref={ref} 
            // id={props.id} 
            {...props} 
        />
      </FormControl>
    )
  })

export default TextInput