import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalBody, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from "@chakra-ui/modal"
import { Button, ButtonGroup, Flex, Heading, useMediaQuery } from "@chakra-ui/react"
import _ from "lodash"
import { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import useCellDoc, { convertChecklistMapToList } from "../../hooks/useCellDoc"
import useCurrentTime from "../../hooks/useCurrentTime"
import { RootState } from "../../redux/store"
import { startCleanUp, endShift, setShiftNotes } from "../../redux/timerSlice"
import { formatDateTime } from "../../utils"
import Checklist from "./Checklist"
import LastNotesModal from "./LastNotesModal"
import NoteModal from "./NoteModal"


const CleanupModal = () => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const shiftNotes = useSelector((state: RootState) => state.timer.shiftNotes)
    
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure()
    const cancelRef = useRef<any>()

    
    // const cleanupChecklist = useSelector((state: RootState) => state.timer.cleanupChecklist)
    // const [newCleanupChecklist, setNewCleanupChecklist] = useState<ChecklistData>(cleanupChecklist)

    // const { loading, cellDoc, error } = useCellDoc('cell')
    const { cellDoc } = useCellDoc('cell')

    const currentTime = formatDateTime(useCurrentTime())

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])

    let timeSize: string = '3xl'
    let headingSize: string = 'xl'
    let hwProps: { h?: string, w?: string } = {}
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        timeSize = '3xl'
        headingSize = 'xl'
        hwProps = {}
    }
    if (smH) {  // 480px
        timeSize = '3xl'
        headingSize = '2xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (mdH) {  // 768px
        timeSize = '4xl'
        headingSize = '3xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (lgH) {  // 992px
        timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }
    if (xlH) {  // 1280px
        timeSize = '4xl'
        headingSize = '4xl'
        hwProps = {
            h: '10vh',
            w: '30vw'
        }
    }

    const changeShiftNotes = (note: string) => {
        dispatch(setShiftNotes({
            note: note
        }))
    }

    return (
        <>
            <Button
                colorScheme='teal'
                w='full'
                h='full'
                fontSize={['md', 'lg', 'xl', '2xl']}
                variant='outline'
                border='3px solid'
                onClick={onOpenAlert}
                isDisabled={isDown || isStopped}
            >
                End Shift
            </Button>

            {
                // ALERT
            }
            <AlertDialog
                isOpen={isOpenAlert}
                leastDestructiveRef={cancelRef}
                onClose={onCloseAlert}
                size='lg'
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Start Cleanup
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onCloseAlert} size='lg'>
                                Cancel
                            </Button>
                            <Button
                                colorScheme='red'
                                onClick={() => {
                                    onCloseAlert()
                                    onOpen()
                                    dispatch(startCleanUp())
                                }}
                                ml={3}
                                size='lg'
                            >
                                Start Cleanup
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {
                // MODAL
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                size='full'
            >
                <ModalOverlay />
                <ModalContent p={4} overflowY='auto'>
                    <ModalBody>
                        <ButtonGroup spacing={2} alignSelf='flex-start'>
                            <LastNotesModal />
                            <NoteModal size='lg' title={'Shift Notes'} value={shiftNotes} update={changeShiftNotes} />
                        </ButtonGroup>
                        <Flex flexDir='column' w={3 / 5} mx='auto' minH='75vh' justifyContent='space-around' alignItems='center'>
                            <Heading size={headingSize}>
                                Cleanup
                            </Heading>
                            <Heading size={timeSize} my={2}>
                                {currentTime}
                            </Heading>
                            {!_.isEmpty(cellDoc.cleanup_check_map) &&
                                <Flex flexDir='column' minW='15vw'>
                                    <Checklist checklist={convertChecklistMapToList(cellDoc.cleanup_check_map)} />
                                </Flex>
                            }
                            {/* {!_.isEmpty(newCleanupChecklist) &&
                                <Flex flexDir='column' minW='15vw'>
                                    <Checklist checklist={newCleanupChecklist} updateChecklist={setNewCleanupChecklist} />
                                </Flex>
                            } */}
                            <Button
                                colorScheme='teal'
                                onClick={() => {
                                    // dispatch(endShift({ newCleanupChecklist }))
                                    dispatch(endShift())
                                    onClose()
                                }}
                                mx='auto'
                                size='lg'
                                minH='10vh'
                                {...hwProps}
                            >
                                End Shift
                            </Button>
                            {/* <Box display='flex' flexDir='column' h='full' w={3 / 5} justifyContent='space-evenly' mx='auto'>
                            <Heading size={headingSize} alignSelf='center'>
                                Cleanup
                            </Heading>
                            <Button
                                colorScheme='teal'
                                onClick={() => {
                                    dispatch(endShift())
                                    onClose()
                                }}
                                mx='auto'
                                h='20vh'
                                w='full'
                                size='lg'
                            >
                                End Shift
                            </Button>
                        </Box> */}
                        </Flex>
                        {/* <VStack w={4 / 5} h='60vh' mx='auto' justifyContent='space-around'>
                            <Heading size={headingSize} alignSelf='center'>
                                Cleanup
                            </Heading>

                        </VStack> */}
                        {/* </Flex> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button
                            colorScheme='teal'
                            onClick={() => {
                                dispatch(endShift())
                                onClose()
                            }}
                            mx='auto'
                            size='lg'
                            {...hwProps}
                        >
                            End Shift
                        </Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

export default CleanupModal
