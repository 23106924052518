import { Grid, GridItem, Flex, Box, theme, Text, Select } from "@chakra-ui/react"
import dayjs from "dayjs"
import { useMemo, useState } from "react"
import useCalcs, { getShiftsByDay } from "../../hooks/useCalcs"
import { ShiftData, ShiftsQuery } from "../../hooks/useShifts"
import OlePplTable from "../charts/OlePplTable"
import PieProgress from "../charts/PieProgress"
import QPATable from "../charts/QPATable"
import ReasonsBar from "../charts/ReasonsBar"
import ThroughputBar from "../charts/ThroughputBar"
import InsightsRecords from "./InsightsRecords"

interface IInsightsCharts {
    query: ShiftsQuery
    shifts: Array<ShiftData>
}

const InsightsCharts = ({ shifts, query }: IInsightsCharts) => {
    const [records, setRecords] = useState<string>('batch')
    const [dayFilter, setDayFilter] = useState<string>('All Days')
    const [shiftNameFilter, setShiftNameFilter] = useState<string>('All Shifts')


    const filterByDay = (shifts: Array<ShiftData>, day: string): Array<ShiftData> => {
        return getShiftsByDay(shifts, 0)[day]
    }
    const filterByShiftName = (shifts: Array<ShiftData>, shiftName: string): Array<ShiftData> => {
        return shifts.filter(s => s.shiftName === shiftName)
    }
    const useFilteredShifts = (shifts: Array<ShiftData>, dayFilter: string, shiftNameFilter: string): Array<ShiftData> => {
        return useMemo(() => {
            const dayFiltered: Array<ShiftData> =
                dayFilter !== 'All Days' ? filterByDay(shifts, dayFilter) : shifts

            const shiftNameFiltered: Array<ShiftData> =
                shiftNameFilter !== 'All Shifts' ? filterByShiftName(dayFiltered, shiftNameFilter) : dayFiltered

            return shiftNameFiltered
            // if (dayFilter === 'All Days' && shiftNameFilter === 'All Shifts') {
            //     return shifts
            // } else if (dayFilter === 'All Days') {
            //     return filterByShiftName(shifts, shiftNameFilter)
            // } else if (shiftNameFilter === 'All Shifts') {
            //     return filterByDay(shifts, dayFilter)
            // } else {
            //     return filterByShiftName(filterByDay(shifts, dayFilter), shiftNameFilter)
            // }
        }, [shifts, dayFilter, shiftNameFilter])
    }

    const {
        shiftsOLE,
        shiftsQualityPercent,
        shiftsPerformancePercent,
        shiftsAvailabilityPercent,
        shiftsOleData,
        shiftsPeopleData,
        dailyThroughputData,
        shiftsDownsByReason,
        shiftsRejectsByReason,
        getRejectBarData,
        getDownBarData,
        shiftsQualData,
        shiftsPerformData,
        shiftsAvailData,
        // getChangeRecords,
        // getDownRecords,
        // getRejectRecords,
        // getBatchRecords,
    } = useCalcs()

    // const getChangeRecords = (shifts: Array<ShiftData>): Array<ChangeTableRecord> => {
    //     return shifts.flatMap(s => s.batchRecords.filter(b => b.totalChange).map(b => (
    //         {
    //             fromTo: `${b.prevPart}-${b.part}`,
    //             duration: b.totalChange,
    //             missingParts: Number((b.totalChange / b.cycleTime).toFixed(1)),
    //             startTime: b.startTime
    //         }
    //     )))
    // }

    // const getDownRecords = (shifts: Array<ShiftData>): Array<DownTableRecord> => {
    //     return shifts.flatMap(s => s.downRecords.map(d => (
    //         {
    //             startTime: d.startTime,
    //             code: d.code === '' ? '_' : d.code,
    //             note: d.note,
    //             duration: d.duration,
    //             partsMissed: d.numParts,
    //         }
    //     )))
    // }

    // const getRejectRecords = (shifts: Array<ShiftData>): Array<RejectTableRecord> => {
    //     const records: Array<RejectTableRecord> = []
    //     shifts.flatMap(s => s.partRecords.forEach(p => {
    //         if (p.rejectIdx !== null) {
    //             const totalDown = p.downIdxs.reduce((acc, idx) => acc + s.downRecords[idx].duration, 0)
    //             const totalStopped = p.stoppedIdxs.reduce((acc, idx) => acc + s.stoppedRecords[idx].duration, 0)
    //             const totalChange = p.totalChange.reduce((acc, sec) => acc + sec, 0)
    //             records.push({
    //                 // ...s.rejectRecords[p.rejectIdx],
    //                 part: s.rejectRecords[p.rejectIdx].part,
    //                 code: s.rejectRecords[p.rejectIdx].code === '' ? '_' : s.rejectRecords[p.rejectIdx].code,
    //                 note: s.rejectRecords[p.rejectIdx].note,
    //                 duration: Math.floor((p.endTime - p.startTime) / 1000) - totalDown - totalStopped - totalChange,
    //                 endTime: p.endTime
    //             })
    //         }
    //     }))
    //     return records
    // }

    // const getBatchRecords = (shifts: Array<ShiftData>): Array<BatchTableRecord> => {
    //     return shifts.flatMap(s => s.batchRecords.map(b => (
    //         {
    //             workOrder: b.workOrder,
    //             part: b.part,
    //             plan: b.plan,
    //             actual: b.actual,
    //             rejects: b.rejects,
    //             people: b.peopleIdxs.map(idx => s.peopleRecords[idx].peopleList),
    //             changeTime: b.totalChange,
    //             startTime: b.startTime
    //         }
    //     )))
    // }

    // const GetRecord = (shifts: Array<ShiftData>): JSX.Element => {
    //     const useChangeRecords: Array<ChangeTableRecord> = useMemo(() => getChangeRecords(shifts), [shifts])
    //     const useDownRecords: Array<DownTableRecord> = useMemo(() => getDownRecords(shifts), [shifts])
    //     const useRejectRecords: Array<RejectTableRecord> = useMemo(() => getRejectRecords(shifts), [shifts])
    //     const useBatchRecords: Array<BatchTableRecord> = useMemo(() => getBatchRecords(shifts), [shifts])
    //     const useStoppedRecords: Array<StoppedData> = useMemo(() => shifts.flatMap(s => s.stoppedRecords), [shifts])

    //     if (records === 'batch') {
    //         return <BatchesTable queryChange={shifts} batchRecords={useBatchRecords} />
    //     } else if (records === 'down') {
    //         return <DownsTable queryChange={shifts} downRecords={useDownRecords} />
    //     } else if (records === 'reject') {
    //         return <RejectsTable queryChange={shifts} rejectRecords={useRejectRecords} />
    //     } else if (records === 'stopped') {
    //         return <StopsTable queryChange={shifts} stoppedRecords={useStoppedRecords} />
    //     } else if (records === 'change') {
    //         return <ChangesTable queryChange={shifts} changeRecords={useChangeRecords} />
    //     } else {
    //         return <></>
    //     }
    // }

    return (
        <>
            <Box bg='white' p={4} mt={16} borderRadius='lg' boxShadow='base' display='inline-block' textAlign='center'>
                <Flex flex={1} justify='center' align='center'>
                    <Text ml={6} fontSize='lg' fontWeight='medium' textTransform='capitalize'>
                        {`${dayjs(query.startTime).format('MMM D')} - ${dayjs(query.endTime).format('MMM D')}`}
                    </Text>
                    <Box display='inline-block' mx={12} >
                        <Select
                            value={dayFilter}
                            onChange={e => { setDayFilter(e.currentTarget.value); setShiftNameFilter('All Shifts') }}
                        >
                            <option value='All Days'>All Days</option>
                            {Object.keys(getShiftsByDay(shifts, 0)).sort().map(timestamp => {
                                return <option key={timestamp} value={timestamp}>{dayjs(Number(timestamp)).format('MMM D')}</option>
                            })}
                        </Select>
                    </Box>
                    <Box display='inline-block'>
                        <Select
                            value={shiftNameFilter}
                            onChange={e => setShiftNameFilter(e.currentTarget.value)}
                        >
                            <option value='All Shifts'>All Shifts</option>
                            {Array.from(new Set(useFilteredShifts(shifts, dayFilter, 'All Shifts').map(s => s.shiftName))).sort()
                                .map(shiftName => {
                                    return <option key={shiftName} value={shiftName}>{shiftName}</option>
                                })
                            }
                        </Select>
                    </Box>
                </Flex>
            </Box>

            <Grid w='full' mt={4} mb={16} templateColumns='repeat(2, minmax(0, 1fr))' templateRows='repeat(10, 1fr)' gap={12}>
                <GridItem colSpan={2} rowSpan={3} bg='white' p={6} borderRadius='lg' boxShadow='base' w='full' overflowX='auto'>
                    <Grid w='full' templateColumns='repeat(3, 1fr)' gap={8} mb={12} mt={6} >
                        <GridItem colSpan={1} textAlign='center'>
                            <Flex justify='center' align='center' h='full'>
                                <Box>
                                    <OlePplTable data={shiftsOleData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} unit='parts' />
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <PieProgress
                                name='Overall Labor Effectiveness'
                                percent={shiftsOLE(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.teal[500]}
                                // size={160}
                                size='20vh'
                            />
                        </GridItem>
                        <GridItem colSpan={1} textAlign='center'>
                            <Flex justify='center' align='center' h='full'>
                                <Box>
                                    <OlePplTable data={shiftsPeopleData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} unit='min' />
                                </Box>
                            </Flex>
                        </GridItem>
                    </Grid>
                    {/* <Flex
                        w='full'
                        justify='space-evenly'
                        align='center'
                        flexGrow={1}
                        mt={6}
                        mb={12}
                    >
                        <Box alignSelf='center'>
                            <OlePplTable data={shiftsOleData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} unit='parts' />
                        </Box>
                        <PieProgress
                            name='Overall Labor Effectiveness'
                            percent={shiftsOLE(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                            color={theme.colors.teal[500]}
                            // size={160}
                            size='20vh'
                        />

                        <Box alignSelf='center'>
                            <OlePplTable data={shiftsPeopleData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} unit='min' />
                        </Box>
                    </Flex> */}
                    <Grid w='full' templateColumns='repeat(3, 1fr)' gap={8}>
                        <GridItem colSpan={1}>
                            <PieProgress
                                name='Quality'
                                percent={shiftsQualityPercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.red[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsQualData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} />}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <PieProgress
                                name='Performance'
                                percent={shiftsPerformancePercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.green[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsPerformData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} />}
                            />
                        </GridItem>
                        <GridItem colSpan={1}>
                            <PieProgress
                                name='Availability'
                                percent={shiftsAvailabilityPercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.orange[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsAvailData(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} />}
                            />
                        </GridItem>
                    </Grid>
                    {/* <Flex flexGrow={1} justify='space-around'>
                        <Flex flex={1} justify='center'>
                            <PieProgress
                                name='Quality'
                                percent={shiftsQualityPercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.red[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsQualData(shifts)} />}
                            />
                        </Flex>
                        <Flex flex={1} justify='center'>
                            <PieProgress
                                name='Performance'
                                percent={shiftsPerformancePercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.green[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsPerformData(shifts)} />}
                            />
                        </Flex>
                        <Flex flex={1} justify='center'>
                            <PieProgress
                                name='Availability'
                                percent={shiftsAvailabilityPercent(useFilteredShifts(shifts, dayFilter, shiftNameFilter))}
                                color={theme.colors.orange[500]}
                                size='15vh'
                                dataTable={<QPATable data={shiftsAvailData(shifts)} />}
                            />
                        </Flex>
                    </Flex> */}
                </GridItem>
                <GridItem colSpan={2} rowSpan={2} bg='white' pr={6} py={6} borderRadius='lg' boxShadow='base' w='full'>
                    <ThroughputBar
                        title='Daily Throughput'
                        h='40vh'
                        w='100%'
                        // data={dailyThroughputData(shifts, 'week')}
                        data={dailyThroughputData(useFilteredShifts(shifts, dayFilter, shiftNameFilter), query.startTime, query.endTime, 0)}
                    // dailyThroughputData(shifts, timeframe, dayjs(start).daysInMonth())
                    />
                </GridItem>
                <GridItem colSpan={1} rowSpan={2} bg='white' pr={6} py={6} borderRadius='lg' boxShadow='base'>
                    <ReasonsBar
                        title='Top Reject Reasons'
                        color={theme.colors.red[600]}
                        h='40vh'
                        w='100%'
                        data={getRejectBarData(shiftsRejectsByReason(useFilteredShifts(shifts, dayFilter, shiftNameFilter)))}
                    />
                </GridItem>
                <GridItem colSpan={1} rowSpan={2} bg='white' pl={4} pr={6} py={6} borderRadius='lg' boxShadow='base'>
                    <ReasonsBar
                        title='Top Down Reasons'
                        color={theme.colors.orange[400]}
                        h='40vh'
                        w='100%'
                        data={getDownBarData(shiftsDownsByReason(useFilteredShifts(shifts, dayFilter, shiftNameFilter)))}
                    />
                </GridItem>
                <GridItem colSpan={2} rowSpan={3} bg='white' pl={4} pr={6} py={6} borderRadius='lg' boxShadow='base' textAlign='center'>
                    <Box display='inline-block' mb={4}>
                        <Select
                            h='5vh'
                            fontSize='3vh'
                            size='lg'
                            // display='inline-block'
                            // fontWeight='semibold'
                            // placeholder='Select Records'
                            defaultValue={'batch'}
                            // value={records}
                            onChange={e => setRecords(e.currentTarget.value)}
                        >
                            <option value='batch'>Batch Records</option>
                            <option value='down'>Down Records</option>
                            <option value='reject'>Reject Records</option>
                            <option value='stopped'>Stopped Records</option>
                            <option value='change'>Change Records</option>
                        </Select>
                    </Box>
                    {/* {GetRecord(useFilteredShifts(shifts, dayFilter, shiftNameFilter))} */}
                    <InsightsRecords shifts={useFilteredShifts(shifts, dayFilter, shiftNameFilter)} records={records} />
                </GridItem>
            </Grid>
        </>
    )
}

export default InsightsCharts