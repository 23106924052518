import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { setStopped, setChange, setTakt, setDown, changeHour } from "../redux/timerSlice";
import useCurrentTime from "./useCurrentTime";

const useTimer = () => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const shiftStartTime = useSelector((state: RootState) => state.timer.shiftStartTime)
    const shiftEndTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const currentBatch = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0])
    const hourRecords = useSelector((state: RootState) => state.timer.hourRecords)
    const dispatch = useDispatch()

    const currentTime = useCurrentTime().getTime()

    // Run Timer
    useEffect(() => {
        // Timer
        if (shiftStartTime && !shiftEndTime) {
            // Hour change
            if (Math.floor((currentTime - shiftStartTime) / 60 / 60 / 1000) > hourRecords.length - 1) {  // hour == 60 / 60 / 1000
                dispatch(changeHour())
            }
            // Update Timer
            if (isStopped) {  // Break
                dispatch(setStopped(currentTime))
            } else if (isChange) {  // Change
                dispatch(setChange(currentTime))
            } else {  // CT Running
                dispatch(setTakt(currentTime))
                if (isDown) {
                    dispatch(setDown(currentTime))
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTime, shiftStartTime, shiftEndTime, isDown, isChange, isStopped])
}

export default useTimer