import { useEffect } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"

interface IUseDownBorder {
    borderColor?: React.MutableRefObject<string>,
    border?: React.MutableRefObject<string>,
    bg?: React.MutableRefObject<string>
}

const useDownBorder = ({ borderColor, border, bg }: IUseDownBorder) => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)

    // const borderColor = useRef<string>('gray.200')
    // const border = useRef<string>('1px')

    useEffect(() => {
        if (isDown) {
            // if (borderColor) borderColor.current = 'gray.100'
            // if (border) border.current = '2px'
            if (borderColor) borderColor.current = 'gray.200'
            if (border) border.current = '1px'
            if (bg) bg.current = 'orange.400'
        } else {
            if (borderColor) borderColor.current = 'gray.200'
            if (border) border.current = '1px'
            if (bg) bg.current = 'white'
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDown])

    // return { borderColor, border }
}

export default useDownBorder