import { Box, Heading, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel, InputGroup, InputLeftElement, Icon, Input, Button, useToast, ButtonGroup, useRadioGroup } from "@chakra-ui/react";
import { useState } from "react";
import { MdPersonOutline, MdLockOutline } from "react-icons/md";
import { useSelector } from "react-redux";
import RadioOptions from "../../components/RadioOptions";
import { RootState } from "../../redux/store";
import { functions } from "../../utils/firebase";

const AdminRegister = () => {
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    // const adminUID = currentUser.id;
    const [accountType, setAccountType] = useState<string>('cell')
    // const [ displayName, setDisplayName ] = useState<string>('');
    // const [ email, setEmail ] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    // const [ error, setError ] = useState<string>('');
    // const [ successUser, setSuccessUser ] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const toast = useToast()

    // const dispatch = useDispatch()

    // if (isLoading) {
    //     return <Loading />
    // }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (password.length < 6) {
            toast({
                title: "Password should be at least 6 characters.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        } else if (password !== confirmPassword) {
            toast({
                title: "Passwords don't match.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        }

        try {
            // const createUser = functions.httpsCallable('createUser');
            // const user = await createUser({ 
            //     email: email,
            //     password: password,
            //     displayName: displayName,
            //     role: role,
            //     adminUID: adminUID
            // })
            // console.log("Created: ", user)
            setIsLoading(true)
            const createUser = functions.httpsCallable('createUserNoEmail');
            // const user = await createUser({ 
            await createUser({
                username: username.toLowerCase(),
                password: password,
                role: accountType,
                adminUID: currentUser?.id
            })
            // console.log("Created: ", user)
            setIsLoading(false)
            toast({
                title: `Success! You've created ${accountType} ${username}`,
                status: "success",
                duration: 5000,
                isClosable: true
            })
            // setSuccessUser(username)

            // Clear form
            // setError('')
            setUsername('');
            // setEmail('');
            setPassword('');
            setConfirmPassword('');
            // if (currentUser) {
            //     dispatch(getCellListThunk(currentUser.id))
            // }

        } catch (error: any) {
            // console.log(error.code)
            setIsLoading(false)
            // console.log(error)
            toast({
                title: "Username unavailable, choose a different username.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            // setError('Username unavailable, choose a different username')
        }
    }

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'account type',
        defaultValue: 'cell',
        onChange: setAccountType,
    })

    const group = getRootProps()

    return (
        <>
            <Box
                w='full'
                // flex='1'
                h='100vh'
                // flexDir='column'
                overflow='auto'
                // align='center'
                // justify='center'
                bg='gray.100'
            >
                <VStack spacing={8} mx={'auto'} maxW='600px' py={12} px={6}>
                    <Heading fontSize={'4xl'}>REGISTER A USER</Heading>
                    <Box
                        rounded='lg'
                        bg='white'
                        boxShadow='lg'
                        p={8}
                        w='full'
                    >
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={8}>
                                <FormControl>
                                    <FormLabel>Select Account Type</FormLabel>
                                    <ButtonGroup {...group} w='100%' size='sm' isAttached variant='outline'>
                                        {/* {['cell', 'viewer', 'manager'].map((value) => { */}
                                        {['cell'].map((value) => {
                                            const radio = getRadioProps({ value })
                                            return (
                                                <RadioOptions key={value} {...radio} disabled={isLoading}>
                                                    {value}
                                                </RadioOptions>
                                            )
                                        })}
                                    </ButtonGroup>
                                    {/* <Text mt={1} ml={1}>
                                        {accountType === 'cell' ? 
                                            'Cells collect production data' 
                                            : (accountType === 'viewer' ? 
                                            'Viewers can view data collected by cells'
                                            : 
                                            'Managers are viewers that can also update cell settings')
                                        }
                                    </Text> */}
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Username</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdPersonOutline} color='gray.400' />}
                                        />
                                        <Input
                                            value={username}
                                            onKeyDown={e => {
                                                if ([';', ':', ' ', '"', '(', ')', ',', '<', '>', '@', '[', `\\`, ']'].includes(e.key)) {
                                                    e.preventDefault()
                                                }
                                            }}
                                            onChange={e => setUsername(e.currentTarget.value.replace(/\s/g, ""))}
                                            placeholder='user123'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdLockOutline} color='gray.400' />}
                                        />
                                        <Input
                                            type='password'
                                            value={password}
                                            onChange={e => setPassword(e.currentTarget.value)}
                                            placeholder='************'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdLockOutline} color='gray.400' />}
                                        />
                                        <Input
                                            type='password'
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.currentTarget.value)}
                                            placeholder='************'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    isLoading={isLoading}
                                    loadingText='Submitting'
                                    colorScheme='teal'
                                    w='50%'
                                    isDisabled={!username || !password || !confirmPassword}
                                    type='submit'
                                    size='lg'
                                >
                                    REGISTER
                                </Button>
                            </VStack>
                        </form>
                        {/* {error &&
                            <Text mt={2} textAlign='center' textColor='red.500'>
                                {error}
                            </Text>
                        } */}
                    </Box>
                </VStack>
            </Box>
        </>
        // <Box flex='1'>
        // <div className="min-w-screen min-h-screen bg-indigo-200 flex flex-col items-center justify-center px-5 py-5">
        //     {successUser &&
        //         <div className="mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
        //             <strong className="font-bold">Success!</strong>
        //             <span className="block sm:inline"> You've created user {successUser}</span>
        //             <button 
        //                 className="ml-4 bg-transparent text-xl font-semibold leading-none outline-none focus:outline-none"
        //                 onClick={() => setSuccessUser('')}
        //             >
        //                 <span>×</span>
        //             </button>
        //         </div>
        //     }
        //     {/* CARD */}
        //     <div className="px-5 bg-white text-gray-500 rounded-3xl shadow-xl max-w-md w-full overflow-hidden">
        //         <div className="flex w-full">
        //             <div className="w-full py-7 px-5 md:px-10">
        //                 {/* TITLE */}
        //                 <div className="text-center mb-7">
        //                     <h1 className="font-bold text-2xl text-gray-900">
        //                         REGISTER CELL
        //                     </h1>
        //                     {/* <p>Cells: use the timer and save data</p>
        //                     <p>Managers: view data from each cell</p> */}
        //                 </div>
        //                 {/* INPUTS */}
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Role
        //                             </label>
        //                             <div className="ml-2 mt-2 text-sm">
        //                                 <label className="inline-flex items-center">
        //                                     <input 
        //                                         type="radio" 
        //                                         className="form-radio" 
        //                                         name="role" 
        //                                         value="cell"
        //                                         onClick={() => setRole('cell')}
        //                                         defaultChecked
        //                                     />
        //                                     <span className="ml-2">Cell</span>
        //                                 </label>
        //                                 {/* <label className="inline-flex items-center ml-6">
        //                                     <input 
        //                                         type="radio" 
        //                                         className="form-radio" 
        //                                         name="role" 
        //                                         value="manager" 
        //                                         onClick={() => setRole('manager')}
        //                                     />
        //                                     <span className="ml-2">Manager</span>
        //                                 </label> */}
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Username
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     <i className="mdi mdi-account-outline text-gray-400 text-lg" />
        //                                 </div>
        //                                 <input 
        //                                     type="text" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="anode_cell" 
        //                                     value={username}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Email
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     <i className="mdi mdi-email-outline text-gray-400 text-lg" />
        //                                 </div>
        //                                 <input 
        //                                     type="email" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="johnsmith@example.com" 
        //                                     value={email}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div> */}
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-6">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Password
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     <i className="mdi mdi-lock-outline text-gray-400 text-lg" />
        //                                 </div>
        //                                 <input 
        //                                     type="password" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="************"
        //                                     value={password}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)} 
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-6">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Confirm Password
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     <i className="mdi mdi-lock-outline text-gray-400 text-lg" />
        //                                 </div>
        //                                 <input 
        //                                     type="password" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="************"
        //                                     value={confirmPassword}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setConfirmPassword(e.currentTarget.value)} 
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* ERROR */}
        //                     <div className="mb-2 text-xs text-center text-red-500">
        //                         {error}
        //                     </div>
        //                     {/* SUBMIT */}
        //                     <div className="mt-6 flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <button type='submit' className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
        //                                 REGISTER NOW
        //                             </button>
        //                         </div>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='p-5'/>
        // </div>
        // </Box>
    )
}

export default AdminRegister