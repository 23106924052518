import { ChevronDownIcon, ChevronRightIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons"
import { Box, Input, Table, Thead, Tr, Th, chakra, Tbody, Td, Text, Tooltip, Tfoot } from "@chakra-ui/react"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { useFlexLayout, useGlobalFilter, useSortBy, useExpanded, useTable } from "react-table"
import { formatSeconds } from "../../utils"

export interface RejectTableRecord {
    part: string,
    code: string,
    note: string,
    duration: number,
    endTime: number,
}

interface IRejectsTable {
    rejectRecords: Array<RejectTableRecord>
    queryChange: any
}

const RejectsTable = ({ rejectRecords, queryChange }: IRejectsTable) => {
    // Don't add RejectRecords - constant rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data: Array<RejectTableRecord> = useMemo(() => rejectRecords, [queryChange])

    // console.log('data', data)

    const columns: any = useMemo(
        () => [
            {
                id: 'expander', // Make sure it has an ID
                // Header: () => null,
                maxWidth: 10,
                Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => (
                    <span {...getToggleAllRowsExpandedProps()}>
                        {isAllRowsExpanded ?
                            <ChevronDownIcon aria-label='expanded' />
                            :
                            <ChevronRightIcon aria-label='collapsed' />
                        }
                    </span>
                ),
                Cell: ({ row }: any) =>
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    // row.canExpand ? (
                    data[row.index].note ? (
                        <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ?
                                <ChevronDownIcon aria-label='expanded' />
                                :
                                <ChevronRightIcon aria-label='collapsed' />
                            }
                        </span>
                    ) : null
            },
            {
                id: 'code',
                Header: 'Code',
                accessor: 'code',
                Cell: ({ row }: any) => row.values.code === '_' ? '' : row.values.code,
                Footer: ({ globalFilteredRows }: any) => {
                    const unique = new Set()
                    globalFilteredRows.forEach((row: any) => unique.add(row.values.code))
                    return (
                        <Tooltip label="Unique Codes" placement='bottom-start'>
                            <Text>{unique.size}</Text>
                        </Tooltip>
                    )
                }
            },
            {
                id: 'partNum',
                Header: 'Part #',
                accessor: 'part',
                Footer: ({ globalFilteredRows }: any) => {
                    const unique = new Set()
                    globalFilteredRows.forEach((row: any) => unique.add(row.values.partNum))
                    return (
                        <Tooltip label="Unique Part #'s" placement='bottom-start'>
                            <Text>{unique.size}</Text>
                        </Tooltip>
                    )
                }
                // Cell: ({ row }: any) =>
                //     formatSeconds(data[row.index].duration)
            },
            {
                id: 'duration',
                Header: 'Duration',
                accessor: 'duration',
                // maxWidth: 120,
                // canResize: false,
                Cell: ({ row }: any) => formatSeconds(data[row.index].duration),
                Footer: ({ globalFilteredRows }: any) => {
                    const total = React.useMemo(
                        () => globalFilteredRows.reduce((acc: number, row: any) => acc + row.values.duration, 0),
                        [globalFilteredRows]
                    )
                    return (
                        <Tooltip label='Total Duration' placement='bottom-start'>
                            <Text>{formatSeconds(total)}</Text>
                        </Tooltip>
                    )
                    // <>{formatSeconds(total)}</>
                }
                // Footer: ({ row }: any) => formatSeconds(data.reduce((acc, d) => acc + d.duration, 0))
            },
            {
                id: 'endTime',
                Header: 'End Time',
                accessor: 'endTime',
                Cell: ({ row }: any) =>
                    dayjs(data[row.index].endTime).format('MMM D [-] h:mma'),
                    // dayjs(data[row.index].startTime).format('MMM D')
                    // dayjs(data[row.index].startTime).format('M/D H:mm')
                Footer: ({ globalFilteredRows }: any) => (
                    <Tooltip label='Total Count' placement='bottom-start'>
                        <Text>{globalFilteredRows.length}</Text>
                    </Tooltip>
                )
                // globalFilteredRows.length
            },
            // {
            //     id: 'start time',
            //     Header: 'Start Time',
            //     accessor: 'startTime',
            //     Cell: ({ row }: any) =>
            //         dayjs(data[row.index].startTime).format('M/D [-] h:mma')
            //         // dayjs(data[row.index].startTime).format('MMM D')
            //         // dayjs(data[row.index].startTime).format('M/D H:mm')
            // },
            // {
            //     id: 'delete', // Make sure it has an ID
            //     Header: () => null,
            //     maxWidth: 20,
            //     Cell: ({ row }: any) =>
            //         <DeleteIcon
            //             size='sm'
            //             onClick={() => handleDeleteCode(row.index)}
            //             cursor='pointer'
            //         />
            // }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // @ts-ignore
        state: { globalFilter },
        // @ts-ignore
        setGlobalFilter,
        // @ts-ignore
    } = useTable({ columns, data, autoResetExpanded: false, defaultPageSize: 10 },
        useFlexLayout,
        useGlobalFilter,
        useSortBy,
        useExpanded,
    )

    return (
        <>
            {data.length > 0 &&
                <>
                    {/* <Text textAlign='center' mb={4} fontSize='3.5vh'>
                        Reject Records
                    </Text> */}
                    <Box px={2} >
                        <Input
                            placeholder={`Search Code ( _ for empty ) or Part #...`}
                            mb={6}
                            bg='white'
                            value={globalFilter || ''}
                            onChange={e => setGlobalFilter(e.currentTarget.value)}
                            size='sm'
                        />
                        {/* <Divider /> */}
                    </Box>
                    <Box maxH='60vh' overflow='scroll' border='1px' borderColor='gray.200'>
                        {/* <Table {...getTableProps()} size='sm' border='1px' borderBottom='0px' borderColor='gray.200'>
                        <Thead bg='gray.100'>
                            {headerGroups.map((headerGroup: any, idx: number) => (
                                <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                    {headerGroup.headers.map((column: any) => (
                                        <Th
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            key={column.id}
                                            flex={column.accessor === 'code' ? 1 : ''}
                                            px={4}
                                        >
                                            {column.render('Header')}
                                            <chakra.span pl='4' key={column.id + 'arrow'}>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <TriangleDownIcon aria-label='sorted descending' />
                                                    ) : (
                                                        <TriangleUpIcon aria-label='sorted ascending' />
                                                    )
                                                ) : null}
                                            </chakra.span>
                                        </Th>
                                    ))}
                                </Tr>
                            ))}
                        </Thead>
                    </Table> */}
                        {/* <Box maxH='55vh' overflowY='scroll' border='1px' borderColor='gray.200'> */}
                        <Table {...getTableProps()} size='sm' variant='striped'>
                            <Thead bg='gray.200' position='sticky' top='0'>
                                {headerGroups.map((headerGroup: any, idx: number) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                        {headerGroup.headers.map((column: any) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                key={column.id}
                                                flex={column.accessor === 'code' ? 1 : ''}
                                                px={4}
                                            >
                                                {column.render('Header')}
                                                <chakra.span pl='4' key={column.id + 'arrow'}>
                                                    {column.isSorted ? (
                                                        column.isSortedDesc ? (
                                                            <TriangleDownIcon aria-label='sorted descending' />
                                                        ) : (
                                                            <TriangleUpIcon aria-label='sorted ascending' />
                                                        )
                                                    ) : null}
                                                </chakra.span>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody {...getTableBodyProps()}>
                                {rows.map((row: any) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={row.index} >
                                            <Tr {...row.getRowProps()} key={row.index + 'data'}>
                                                {row.cells.map((cell: any) => (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        px={4}
                                                    >
                                                        {cell.render('Cell')}
                                                    </Td>
                                                ))}
                                            </Tr>
                                            {row.isExpanded &&
                                                <Tr key={row.index + 'note'}>
                                                    <Td pl={8}>
                                                        <Text textColor='gray.600'>
                                                            {data[row.index].note}
                                                        </Text>
                                                    </Td>
                                                </Tr>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </Tbody>
                            <Tfoot bg='gray.200' position='sticky' bottom='0'>
                                {headerGroups.map((headerGroup: any, idx: number) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                                        {headerGroup.headers.map((column: any) => (
                                            <Th
                                                {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                                                key={column.id}
                                                flex={column.accessor === 'code' ? 1 : ''}
                                                px={4}
                                            >
                                                {column.render('Footer')}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Tfoot>
                        </Table>
                    </Box>

                </>
            }
        </>
    )
}

export default RejectsTable