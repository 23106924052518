import { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { cellsDataListener } from "../../utils/firebase";
import { cellData } from "../../redux/sessionsSlice";
import SessionsList from "../../components/admin/SessionsList";



const Summary = () => {
    //const [showSessions, setShowSessions] = useState<boolean>(false)
    const [showSessions, setShowSessions] = useState<Array<number>>([]);

    const dispatch = useDispatch();
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    const cellsArray = useSelector((state: RootState) => state.sessions.cellsArray)
    // const sessionsData = useSelector((state: RootState) => state.sessions.sessionsData)



    useEffect(() => {
        if (currentUser) {
            dispatch(cellsDataListener(currentUser))
        }
    // eslint-disable-next-line
    }, [])

    const toggleShow = (cellIndex: number) => {
        if (showSessions.indexOf(cellIndex) === -1) {
            setShowSessions([...showSessions, cellIndex])
        } else {
            setShowSessions(showSessions.filter(cell => cell !== cellIndex))
        }
        // console.log(showSessions)
    }

    return (
        <div>
            <h1>SUMMARY PAGE</h1>
            {cellsArray.map((cell: cellData, index: number) => (
                <div key={index} className='py-2'>
                    <h3 
                        onClick={() => toggleShow(index)}
                        className='text-2xl font-bold cursor-pointer'
                    >
                        Cell {index + 1} - {cell.displayName}
                    </h3>
                    {(showSessions.indexOf(index) !== -1) ? 
                        // renderSessionsList(cell)
                        <SessionsList cell={cell} />
                        // <div>
                        //     {sessionsData.map((session: sessionData, index: number) => (
                        //         <div key={index} className='py-2'>
                        //             <h1 className='text-2xl font-bold'>Session - {index + 1} </h1>
                        //             <div>
                        //                 Id: {session.id}
                        //             </div>
                        //             <div>
                        //                 Date: {formatDate(new Date(session.timestamp))}
                        //             </div>
                        //             <div>
                        //                 Started At: {formatDateTime(new Date(session.timestamp))}
                        //             </div>
                        //             <div>
                        //                 Cycle Time: {session.cycleTime} sec
                        //             </div>
                        //             <div>
                        //                 Planned: {session.planned}
                        //             </div>
                        //             <div>
                        //                 Actual: {session.actual}
                        //             </div>
                        //             <div>
                        //                 Rejected: {session.rejected}
                        //             </div>
                        //             <div>
                        //                 Down Records: {session.downRecords.map((down: downData, index: number) => (
                        //                     <div key={index} className='pl-10'>
                        //                         <div>
                        //                             Started At: {formatDateTime(new Date(down.timestamp))}
                        //                         </div>
                        //                         <div>
                        //                             Duration: {formatTime(down.duration)}
                        //                         </div>
                        //                         <div>
                        //                             Code: {down.code}
                        //                         </div>
                        //                         <div>
                        //                             ---------------------
                        //                         </div>
                        //                     </div>
                        //                 ))}
                        //             </div>
                        //             <div>
                        //                 Break Records: {session.breakRecords.map((breakTime: number, index: number) => (
                        //                     <div key={index} className='pl-10'>
                        //                         <div>
                        //                             {index + 1}: {formatTime(breakTime)}
                        //                         </div>
                        //                     </div>
                        //                 ))}
                        //             </div>
                        //         </div>
                        //     ))}
                        // </div>
                    : 
                        <div/>
                    }
                </div>
            ))}
        </div>
    )
}

export default Summary;