import { Box, Flex, Text, Divider } from "@chakra-ui/react"
import { ResponsiveContainer, Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, LabelList } from "recharts"
import { DownReasonsData, RejectReasonsData } from "../../hooks/useCalcs"
import { formatSeconds } from "../../utils"

interface IReasonsBar {
    title: string,
    color: string,
    h: string,
    w: string,
    data: Array<RejectReasonsData | DownReasonsData>
}

const ReasonsBar = ({ title, color, h, w, data }: IReasonsBar) => {

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <Box bg='white' p={4} borderRadius='md' border='1px' borderColor='gray.300' zIndex={50}>
                    <Text fontWeight='bold' mb={1}>{label}</Text>
                    {/* <Text fontWeight='bold' mb={1}>{`${label} - ${(payload[0].payload.actual / payload[0].payload.plan * 100).toFixed(1)}%`}</Text> */}
                    <Divider my={1} />
                    {title === 'Top Reject Reasons' ?
                        <Text color='red.600'>{`Count : ${payload[0].payload.count}`}</Text>
                    : 
                        <>
                            <Text color='orange.600'>{`Duration : ${formatSeconds(payload[0].payload.duration)}`}</Text>
                            <Text>{`Missed Parts : ${Number((payload[0].payload.missingParts).toFixed(1))}`}</Text>
                        </>
                    }
                </Box>
            )
        }

        return null;
    }

    return (
        <>
            <Flex flexDir='column' align='center' justifySelf='center'>
                <Text mb={2} fontSize='3.5vh'>
                    {title}
                </Text>
                <Box w={w} h={h}>
                    <ResponsiveContainer>
                        <BarChart
                            data={data}
                            margin={{
                                top: 25,
                                right: 0,
                                left: 15,
                                bottom: 0
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis 
                                allowDecimals={false} 
                                tickFormatter={tick => title === 'Top Reject Reasons' ? tick : formatSeconds(tick)}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            {/* <Tooltip formatter={(value: number) => title === 'Top Reject Reasons' ? value : formatSeconds(value)} /> */}
                            <Bar 
                                dataKey={title === 'Top Reject Reasons' ? 'count' : 'duration'} 
                                fill={color} 
                                isAnimationActive={false}
                            >
                                <LabelList 
                                    dataKey={title === 'Top Reject Reasons' ? 'count' : 'duration'} 
                                    formatter={(value: number) => title === 'Top Reject Reasons' ? value : formatSeconds(value)}
                                    position='top'
                                />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Flex>
        </>
    )
}

export default ReasonsBar