import { Text, useMediaQuery } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { setShiftNotes } from "../../redux/timerSlice"
import HeaderData from "./HeaderData"
import NoteModal from "./NoteModal"
import PeoplePopup from "./PeoplePopup"
import SummaryModal from "./SummaryModal"

const TimerHeader = () => {
    const { currentUser } = useSelector((state: RootState) => state.user)
    const currentBatch = useSelector((state: RootState) => state.timer.batchRecords.slice(-1)[0])
    // const currentPeople = useSelector((state: RootState) => state.timer.peopleRecords.slice(-1)[0].peopleList)
    const shiftNotes = useSelector((state: RootState) => state.timer.shiftNotes)
    // const endTime = useSelector((state: RootState) => state.timer.shiftEndTime)
    // const [ numberPeople, setNumberPeople] = useState<number>(currentNumPeople)
    const dispatch = useDispatch()
    // let history = useHistory()

    // const { loading, cellDoc, error } = useCellDoc('cell')
    // const { cellDoc } = useCellDoc('cell')

    const [smH, mdH, lgH, xlH] = useMediaQuery([
        '(min-height: 30em)', // 480px
        '(min-height: 48em)', // 768px
        '(min-height: 62em)', // 992px
        '(min-height: 80em)', // 1280px
    ])
    // let tagSize: string = 'xs'
    let textSize: string = 'md'
    let btnSize: string = 'sm'
    if (!(smH && mdH && lgH && xlH)) {  // < 480px
        // tagSize ='xs'
        textSize = 'md'
        btnSize = 'sm'
    }
    if (smH) {
        // tagSize ='md'
        textSize = 'xl'
        btnSize = 'md'
    }
    if (mdH) {
        // tagSize ='lg'
        textSize = '2xl'
        btnSize = 'lg'
    }
    if (lgH) {
        // tagSize ='2xl'
        textSize = '4xl'
        btnSize = 'lg'
    }
    if (xlH) {
        // tagSize ='3xl'
        textSize = '5xl'
        btnSize = 'lg'
    }

    
    // const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    // const { getInputProps } = useNumberInput({
    //     step: 1,
    //     defaultValue: currentNumPeople,
    //     min: 1,
    // })

    // // const inc = getIncrementButtonProps()
    // // const dec = getDecrementButtonProps()
    // const input = getInputProps({ readOnly: true })

    const changeShiftNotes = (note: string) => {
        dispatch(setShiftNotes({
            note: note
        }))
    }
    
    // if (loading) {
    //     return <Loading />
    // } else if (error) {
    //     //
    // }

    return (
        <>
            <Text
                fontSize={textSize}
            >
                {currentUser?.displayName}
            </Text>
            {currentBatch &&
                <>
                    <HeaderData label={'Part'} data={currentBatch.part} />
                    <HeaderData label={'CT'} data={String(currentBatch.cycleTime)} />
                </>
            }
            <PeoplePopup />
            {/* <HStack spacing={0} boxShadow='base' rounded='md'>
                <Button
                    // {...dec} 
                    borderRightRadius='none'
                    border='1px'
                    borderRight='none'
                    borderColor='gray.200'
                    size={btnSize}
                    onClick={() => dispatch(setNumPeople({
                        numPeople: currentNumPeople - 1
                    }))}
                    isDisabled={currentNumPeople < 1 || !!endTime}
                >-</Button>
                <Input
                    {...input}
                    textAlign='center'
                    value={currentNumPeople}
                    borderRadius='none'
                    w='12'
                    borderColor='gray.200'
                    size={btnSize}
                />
                <Button
                    // {...inc} 
                    borderLeftRadius='none'
                    border='1px'
                    borderLeft='none'
                    borderColor='gray.200'
                    size={btnSize}
                    onClick={() => dispatch(setNumPeople({
                        numPeople: currentNumPeople + 1
                    }))}
                    isDisabled={!!endTime}
                >+</Button>
            </HStack> */}
            <SummaryModal btnSize={btnSize} />
            <NoteModal size={btnSize} title={'Shift Notes'} value={shiftNotes} update={changeShiftNotes}/>
        </>
    )
}

export default TimerHeader