import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { useRef, useState } from "react"
import Loading from "../Loading";
import { functions } from "../../utils/firebase";

interface IChangeCellPass {
    cellId: string,
    cellName: string
}

const ChangeCellPass = ({ cellId, cellName }: IChangeCellPass) => {
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    // const [error, setError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    // const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const initialRef = useRef<HTMLInputElement>(null)
    const toast = useToast()

    if (isLoading) {
        return <Loading />
    }

    const clearForm = () => {
        setPassword('')
        setConfirmPassword('')
        // setError('')
    }

    // const handleChangePass = async (event: any) => {
    const handleChangePass = async () => {
        // event.preventDefault();
        // setError('')

        // if (password.length < 6) {
        //     setError('Password should be at least 6 characters')
        //     return
        // } else if (password !== confirmPassword) {
        //     setError("Passwords don't match")
        //     return
        // }

        try {
            setIsLoading(true)

            const updateCellPassword = functions.httpsCallable('updateCellPassword')
            await updateCellPassword({
                cellId: cellId,
                password: password
            })

            setIsLoading(false)
            toast({
                title: "Success",
                description: "The password for this cell has been changed.",
                status: "success",
                duration: 5000,
                isClosable: true,
            })
            // setIsSuccess(true)
            onClose()

            // Clear form
            clearForm()
        } catch (err: any) {
            toast({
                title: "Oops, something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            setIsLoading(false)
            // setIsLoading(false)
            // setError(err.message)
        }
    }

    return (
        <>
            <Text fontWeight='bold'>
                Change cell account's password
            </Text>
            <Text size='sm' mt={1} mb={3} >
                Use this if the current password is forgotten
            </Text>
            {/* <ChangePassModal 
                {...{ cellName, password, setPassword, confirmPassword, setConfirmPassword }}
                // cellName={cellName} 
                // password={password} 
                // setPassword={setPassword} 
                // confirmPassword={confirmPassword} 
                // setConfirmPassword={setConfirmPassword} 
            /> */}

            {/* MODAL */}
            <Button colorScheme="blue" size='sm' onClick={onOpen}>
                Change Password
            </Button>

            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={() => { onClose(); clearForm() }}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{`Change password - ${cellName}`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl isRequired isInvalid={password.length < 6}>
                            <FormLabel>New Password</FormLabel>
                            <Input
                                ref={initialRef}
                                type='password'
                                placeholder="************"
                                value={password}
                                onChange={(e) => setPassword(e.currentTarget.value)}
                            />
                            <FormErrorMessage>
                                {'Password should be at least 6 characters'}
                            </FormErrorMessage>
                        </FormControl>

                        <FormControl mt={4} isRequired isInvalid={!!confirmPassword && password !== confirmPassword}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input
                                type='password'
                                placeholder="************"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                            />
                            <FormErrorMessage>
                                {"Passwords don't match"}
                            </FormErrorMessage>
                        </FormControl>
                        {/* <Text size='xs' m={2} textAlign='center' textColor='red'>
                            {error}
                        </Text> */}
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            isDisabled={password.length < 6 || password !== confirmPassword}
                            mr={3}
                            onClick={handleChangePass}
                        >
                            Save
                        </Button>
                        <Button onClick={() => { onClose(); clearForm() }}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChangeCellPass