import { useEffect, useState } from "react"
import useCellListDoc, { CellIdName } from "../../hooks/useCellList"
import { GroupBase, Select } from "chakra-react-select";
import { Box } from "@chakra-ui/react"

export interface SelectOptions {
    value: string
    label: string
    colorScheme?: string
}
interface ICellSingleSelect {
    updateSelectedCell: (selectedCell: string, cellName: string) => void,
}

const CellSingleSelect = ({ updateSelectedCell }: ICellSingleSelect) => {

    const { cellListDoc } = useCellListDoc()

    // const [selectedOption, setSelectedOption] = useState<SingleValue<SelectOptions>>()
    const [activeCellOptions, setActiveCellOptions] = useState<Array<SelectOptions>>([])
    const [inactiveCellOptions, setInactiveCellOptions] = useState<Array<SelectOptions>>([])

    const groupedOptions = [
        {
            label: "Active Cells",
            options: activeCellOptions
        },
        {
            label: "Inactive Cells",
            options: inactiveCellOptions
        },
    ]

    const getOptions = () => {
        const activeOptions: Array<SelectOptions> = []
        const inactiveOptions: Array<SelectOptions> = []
        cellListDoc.all_cells.forEach((cell: CellIdName) => {
            if (cellListDoc.active_users.includes(cell.id)) {  // user is active
                activeOptions.push({
                    value: cell.id,
                    label: cell.name,
                    colorScheme: 'green'
                })
            } else {  // user is inactive
                inactiveOptions.push({
                    value: cell.id,
                    label: cell.name
                })
            }
        })
        setActiveCellOptions(activeOptions)
        setInactiveCellOptions(inactiveOptions)
    }

    useEffect(() => {
        // getSelectedOptions()
        getOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cellListDoc])

    return (
        <>
            <Box w='full' bg='white'>
                <Select<SelectOptions, false, GroupBase<SelectOptions>>
                    name="cell"
                    options={groupedOptions}
                    placeholder="Select cell..."
                    // closeMenuOnSelect={false}
                    size="sm"
                    isClearable={false}
                    backspaceRemovesValue={false}
                    // value={selectedOption}
                    onChange={e => e && updateSelectedCell(e.value, e.label)}
                />
            </Box>
        </>
    )
}

export default CellSingleSelect