import { Button } from "@chakra-ui/button"
import { GridItem, Grid } from "@chakra-ui/layout"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { incActual } from "../../redux/timerSlice"
import Takt from "./Takt"

const MidCol = () => {
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isStopped = useSelector((state: RootState) => state.timer.isStopped)
    const isChange = useSelector((state: RootState) => state.timer.isChange)
    // const isRunning = useSelector((state: RootState) => state.timer.isRunning)
    const dispatch = useDispatch()

    return (
        <>
            <GridItem colSpan={4}>
                <Grid h='full' overflow='hidden' templateRows='auto 17%' gap={2}>
                {/* <Grid h='full' overflow='hidden' templateRows='13% auto 17%' gap={2}>
                    <ChangeModal /> */}
                    <Takt />
                    
                    <GridItem >
                        <Button
                            colorScheme='green'
                            w='full'
                            h='full'
                            fontSize={['md', 'lg', 'xl', '2xl']}
                            onClick={() => dispatch(incActual())}
                            disabled={isStopped || isChange || isDown}
                        >
                            Good
                        </Button>
                    </GridItem>
                </Grid>
            </GridItem>
        </>
    )
}

export default MidCol