import { useState } from "react";
import { Icon } from "@chakra-ui/icons";
import { MdOutlineEmail, MdLockOutline, MdPersonOutline, MdWorkOutline } from 'react-icons/md'
import { auth, functions } from "../../utils/firebase";
import { VStack, Heading, Box, FormControl, FormLabel, InputGroup, InputLeftElement, Input, Button, useToast } from "@chakra-ui/react";

const Register = () => {
    const [company, setCompany] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    // const [error, setError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const toast = useToast()

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (password.length < 6) {
            toast({
                title: "Password should be at least 6 characters.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        } else if (password !== confirmPassword) {
            toast({
                title: "Passwords don't match.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            return
        }

        setIsLoading(true)
        const signInMethods = await auth.fetchSignInMethodsForEmail(email)
        // console.log(signInMethods)
        if (signInMethods.length !== 0) {
            toast({
                title: "An account aleady exists for this email.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
            setIsLoading(false)
            return
        }

        try {
            // const { user } = await auth.createUserWithEmailAndPassword(
            //     email, 
            //     password
            // );

            // const role = 'admin'
            // createUserDocument(user, { company, displayName, role });

            // // Make user an admin
            // const addAdminRole = functions.httpsCallable('addAdminRole');
            // // addAdminRole({ email: email}).then(result => console.log(result));
            // const result = await addAdminRole({ email: email})
            // console.log(result)
            // Should refresh token so claims are applied immediately
            // didn't work
            // user?.getIdToken(true)

            setIsLoading(true)
            const createAdminUser = functions.httpsCallable('createAdminUser')
            await createAdminUser({
                company: company,
                displayName: displayName,
                email: email,
                password: password
            })

            await auth.signInWithEmailAndPassword(email, password)

            // Clear form
            setCompany('');
            setDisplayName('');
            setEmail('');
            setPassword('');
            setConfirmPassword('');

        } catch (error) {
            // console.error(error);
            // alert(error)
            setIsLoading(false)
            toast({
                title: "Failed to register user.",
                status: "error",
                duration: 5000,
                isClosable: true
            })
        }
    }

    return (
        <>
            <Box
                w='full'
                // flex='1'
                h='100vh'
                // flexDir='column'
                overflow='auto'
                pb={24}
                // align='center'
                // justify='center'
                bg='gray.50'
            >
                <VStack spacing={8} mx={'auto'} maxW='600px' py={12} px={6}>
                    <Heading fontSize={'4xl'}>REGISTER AN ADMIN</Heading>
                    <Box
                        rounded='lg'
                        bg='white'
                        boxShadow='lg'
                        p={8}
                        w='full'
                    >
                        <form onSubmit={handleSubmit}>
                            <VStack spacing={8}>
                                <FormControl isRequired>
                                    <FormLabel>Company</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdWorkOutline} color='gray.400' />}
                                        />
                                        <Input
                                            value={company}
                                            onChange={e => setCompany(e.currentTarget.value)}
                                            placeholder='ACME Corporation'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Display Name</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdPersonOutline} color='gray.400' />}
                                        />
                                        <Input
                                            value={displayName}
                                            onChange={e => setDisplayName(e.currentTarget.value)}
                                            placeholder='John Smith'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Email</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdOutlineEmail} color='gray.400' />}
                                        />
                                        <Input
                                            type="email"
                                            value={email}
                                            onChange={e => setEmail(e.currentTarget.value)}
                                            placeholder='johnsmith@email.com'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdLockOutline} color='gray.400' />}
                                        />
                                        <Input
                                            type='password'
                                            value={password}
                                            onChange={e => setPassword(e.currentTarget.value)}
                                            placeholder='************'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl isRequired>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<Icon as={MdLockOutline} color='gray.400' />}
                                        />
                                        <Input
                                            type='password'
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.currentTarget.value)}
                                            placeholder='************'
                                            disabled={isLoading}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Button
                                    isLoading={isLoading}
                                    loadingText='Submitting'
                                    colorScheme='teal'
                                    w='50%'
                                    isDisabled={!company || !displayName || !email || !password || !confirmPassword}
                                    type='submit'
                                    size='lg'
                                >
                                    REGISTER
                                </Button>
                            </VStack>
                        </form>
                        {/* {error &&
                            <Text mt={2} textAlign='center' textColor='red.500'>
                                {error}
                            </Text>
                        } */}
                    </Box>
                </VStack>
            </Box>
        </>
        // <div className='w-full overflow-auto h-screen'>
        // <div className="w-full min-w-screen min-h-screen bg-indigo-200 flex flex-col items-center justify-center px-5 py-5">
        //     CARD
        //     <div className="bg-white text-gray-500 rounded-3xl shadow-xl max-w-md w-full overflow-hidden">
        //         <div className="flex w-full">
        //             <div className="w-full py-7 px-5 md:px-10">
        //                 TITLE
        //                 <div className="text-center mb-7">
        //                     <h1 className="font-bold text-3xl text-gray-900">
        //                         REGISTER AN ADMIN
        //                     </h1>
        //                     <p>Admins create accounts for cells &amp; managers</p>
        //                 </div>
        //                 INPUTS
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Company
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-briefcase-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdWorkOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="text" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="Wonka Industries" 
        //                                     value={company}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setCompany(e.currentTarget.value)}
        //                                     required
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Display Name
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-account-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdPersonOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="text" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="Chocolate Factory" 
        //                                     value={displayName}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setDisplayName(e.currentTarget.value)}
        //                                     required
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Email
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-email-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdOutlineEmail} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="email" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="johnsmith@example.com" 
        //                                     value={email}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
        //                                     required
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-5">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Password
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-lock-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdLockOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="password" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="************"
        //                                     value={password}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
        //                                     // minLength={6}
        //                                     required 
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="flex -mx-3">
        //                         <div className="w-full px-3 mb-6">
        //                             <label htmlFor="" className="text-xs font-semibold px-1">
        //                                 Confirm Password
        //                             </label>
        //                             <div className="flex">
        //                                 <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
        //                                     {/* <i className="mdi mdi-lock-outline text-gray-400 text-lg" /> */}
        //                                     <Icon as={MdLockOutline} color='gray.400'/>
        //                                 </div>
        //                                 <input 
        //                                     type="password" 
        //                                     className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500" 
        //                                     placeholder="************"
        //                                     value={confirmPassword}
        //                                     onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setConfirmPassword(e.currentTarget.value)}
        //                                     // minLength={6}
        //                                     required 
        //                                 />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     ERROR
        //                     <div className="mb-2 text-xs text-center text-red-500">
        //                         {error}
        //                     </div>
        //                     SUBMIT
        //                     <div className="flex -mx-3">
        //                         <div className="mt-6 w-full px-3 mb-5">
        //                             <button type='submit' className="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold">
        //                                 REGISTER NOW
        //                             </button>
        //                         </div>
        //                     </div>
        //                 </form>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        // </div>
    )
}

export default Register;