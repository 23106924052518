import { useDisclosure } from "@chakra-ui/hooks"
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal"
import { Button, ModalHeader, ModalCloseButton, Flex, theme, Box, ButtonGroup } from "@chakra-ui/react"
import { useState } from "react"
import useCalcs from "../../hooks/useCalcs"
import ReasonsBar from "../charts/ReasonsBar"
import OlePplTable from "../charts/OlePplTable"
import PieProgress from "../charts/PieProgress"
import QPATable from "../charts/QPATable"
import ThroughputBar from "../charts/ThroughputBar"

interface ISummaryModal {
    btnSize: string
}

const SummaryModal = ({ btnSize }: ISummaryModal) => {
    const [view, setView] = useState<'OLE' | 'reasons' | 'hours'>('OLE')
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        // getEarnedTime,
        // getYield,
        // getDownPartsMissed,
        // getChangePartsMissed,

        // Totals
        // getTotalTime,
        // getTotalStopped,
        // getActualAvailable,
        // getTotalChange,
        // getTotalDown,
        // getTotalRun,
        // getTotalCountTime,

        // Percents
        getCurrentOLE,
        getCurrentAvailabilityPercent,
        getCurrentPerformancePercent,
        getCurrentQualityPercent,

        // People
        // getAvgPeople,
        // getManHours,
        // getPPH,

        // Losses
        // getAvailabilityLoss,
        // getQualityLoss,
        // getPerformanceLoss,

        // Reasons
        currentDownsByReason,
        currentRejectsByReason,
        getDownBarData,
        getRejectBarData,

        // Throughput
        currentHoursThroughputData,

        // Data
        // timeBarData,
        currentPeopleData,
        currentOleData,
        currentQualData,
        currentPerformData,
        currentAvailData,
    } = useCalcs()

    // console.log(currentHoursThroughputData())

    return (
        <>
            <Button
                mx={1}
                size={btnSize}
                border='1px'
                borderColor='gray.200'
                boxShadow='base'
                onClick={onOpen}
            >
                SUMMARY
            </Button>

            {
                // MODAL
            }
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size='full'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Flex>
                            Shift Summary
                            <ButtonGroup
                                size={'sm'}
                                isAttached
                                variant='outline'
                                mx='auto'
                                mt={1}
                                w={7 / 10}
                            >
                                <Button mr='-px' w={1 / 3} bg='white'
                                    isActive={view === 'OLE'}
                                    onClick={() => setView('OLE')}
                                >
                                    OLE
                                </Button>
                                <Button mr='-px' w={1 / 3} bg='white'
                                    isActive={view === 'reasons'}
                                    onClick={() => setView('reasons')}
                                >
                                    Reasons
                                </Button>
                                <Button w={1 / 3} bg='white'
                                    isActive={view === 'hours'}
                                    onClick={() => setView('hours')}
                                >
                                    Hours
                                </Button>
                            </ButtonGroup>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        display='flex'
                        flexDir='column'
                    // justify='space-evenly'
                    >
                        {view === 'OLE' ?
                            <>
                                <Flex
                                    w='full'
                                    justify='space-evenly'
                                    align='center'
                                    flexGrow={1}
                                >
                                    <Box alignSelf='center'>
                                        <OlePplTable data={currentOleData} unit='parts' />
                                    </Box>
                                    <PieProgress
                                        name='Overall Labor Effectiveness'
                                        percent={getCurrentOLE()}
                                        color={theme.colors.teal[500]}
                                        // size={160}
                                        size='25vh'
                                    />

                                    <Box alignSelf='center'>
                                        <OlePplTable data={currentPeopleData} unit='min' />
                                    </Box>
                                </Flex>
                                <Flex flexGrow={1} align='center'>
                                    <Flex w='100%' justify='space-evenly'>
                                        <PieProgress
                                            name='Quality'
                                            percent={getCurrentQualityPercent()}
                                            color={theme.colors.red[500]}
                                            // colors={[
                                            //     theme.colors.green[500], 
                                            //     theme.colors.red[500]
                                            // ]}
                                            // size={100}
                                            size='15vh'
                                            dataTable={<QPATable data={currentQualData} />}
                                        />
                                        <PieProgress
                                            name='Performance'
                                            percent={getCurrentPerformancePercent()}
                                            color={theme.colors.green[500]}
                                            // colors={[
                                            //     theme.colors.teal[700], 
                                            //     theme.colors.gray[300]
                                            // ]}
                                            // size={100}
                                            size='15vh'
                                            dataTable={<QPATable data={currentPerformData} />}
                                        />
                                        <PieProgress
                                            name='Availability'
                                            percent={getCurrentAvailabilityPercent()}
                                            color={theme.colors.orange[500]}
                                            // colors={[
                                            //     theme.colors.teal[700], 
                                            //     theme.colors.orange[500],
                                            //     theme.colors.teal[500]
                                            // ]}
                                            // size={100}
                                            size='15vh'
                                            dataTable={<QPATable data={currentAvailData} />}
                                        />
                                    </Flex>
                                </Flex>
                            </>
                            : 
                            <></>
                        }
                        {view === 'reasons' ? 
                            <>
                                <Flex
                                    w='full'
                                    justify='space-evenly'
                                    align='center'
                                    flexGrow={1}
                                >
                                    <ReasonsBar 
                                        title='Top Reject Reasons'
                                        color={theme.colors.red[600]}
                                        h='60vh'
                                        w='40vw'
                                        data={getRejectBarData(currentRejectsByReason())}
                                    />
                                    <ReasonsBar 
                                        title='Top Down Reasons'
                                        color={theme.colors.orange[400]}
                                        h='60vh'
                                        w='40vw'
                                        data={getDownBarData(currentDownsByReason())}
                                    />
                                </Flex>
                            </> 
                            : 
                            <></>
                        }
                        {view === 'hours' ? 
                            <>
                                <Flex
                                    w='full'
                                    justify='space-evenly'
                                    align='center'
                                    flexGrow={1}
                                >
                                    <ThroughputBar 
                                        title='Hourly Throughput'
                                        // color={theme.colors.green[500]}
                                        h='60vh'
                                        w='80vw'
                                        data={currentHoursThroughputData()}
                                    />
                                </Flex>
                            </> 
                            : 
                            <></>
                        }
                        {/* <Flex w='100%' justify='space-evenly'>
                                    <Flex w='30%'>
                                        <ResponsiveContainer width='99%' height={50} >
                                            <BarChart layout="vertical" data={qdatab} stackOffset='expand'>
                                                <XAxis hide type='number' />
                                                <YAxis hide type='category' dataKey='name' />
                                                <Bar dataKey='Good' fill={theme.colors.green[500]} stackId='a' />
                                                <Bar dataKey='Rejects' fill={theme.colors.red[500]} stackId='a' />
                                                <Tooltip />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Flex>
                                    <Flex w='30%'>
                                        <ResponsiveContainer width='99%' height={50} >
                                            <BarChart layout="vertical" data={pdatab} stackOffset='expand'>
                                                <XAxis hide type='number' />
                                                <YAxis hide type='category' dataKey='name' />
                                                <Bar dataKey='Made Time' fill={theme.colors.teal[700]} stackId='a' />
                                                <Bar dataKey='Performance Loss' fill={theme.colors.blue[500]} stackId='a' />
                                                <Tooltip />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Flex>
                                    <Flex w='30%'>
                                        <ResponsiveContainer width='99%' height={50} >
                                            <BarChart layout="vertical" data={adatab} stackOffset='expand'>
                                                <XAxis hide type='number' />
                                                <YAxis hide type='category' dataKey='name' />
                                                <Bar dataKey='Run Time' fill={theme.colors.teal[700]} stackId='a' />
                                                <Bar dataKey='Down Time' fill={theme.colors.orange[500]} stackId='a' />
                                                <Bar dataKey='Change Time' fill={theme.colors.purple[500]} stackId='a' />
                                                <Tooltip />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Flex>
                                </Flex> */}




                        {/* <Flex w='75%'>
                                    <ResponsiveContainer width='99%' height={50} >
                                        <BarChart layout="vertical" data={timeBarData} stackOffset='expand'>
                                            <XAxis hide type='number' />
                                            <YAxis hide type='category' dataKey='name' />
                                            <Bar dataKey='earned' fill={theme.colors.green[500]} stackId='a' />
                                            <Bar dataKey='quality' fill={theme.colors.red[500]} stackId='a' />
                                            <Bar dataKey='performance' fill={theme.colors.gray[400]} stackId='a' />
                                            <Bar dataKey='available' fill={theme.colors.orange[500]} stackId='a' />
                                            <Bar dataKey='stopped' fill={theme.colors.teal[500]} stackId='a' />
                                            <Tooltip />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Flex> */}
                    </ModalBody>

                    {/* <ModalFooter>
                        <Button colorScheme='teal' onClick={onClose}>Close</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}

export default SummaryModal