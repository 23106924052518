import { GridItem, Flex } from "@chakra-ui/react"
import { useRef } from "react";
import { useSelector } from "react-redux";
import useConfigCell from "../../hooks/useConfigCell";
import useDownBorder from "../../hooks/useDownBorder";
import { RootState } from "../../redux/store";
import { formatSeconds } from "../../utils";
import DispData from "./DispData";
import DRInfo from "./DRInfo";
import DRListModal from "./DRListModal";

const Down = () => {
    // const downTime = useSelector((state: RootState) => state.timer.downTime)
    // const isDown = useSelector((state: RootState) => state.timer.isDown)
    const isDown = useSelector((state: RootState) => state.timer.isDown)
    const downRecords = useSelector((state: RootState) => state.timer.downRecords)
    const lastDown = downRecords.slice(-1)[0]
    const downTime = lastDown && isDown ? lastDown.duration : 0
    // const [isEditing, setIsEditing] = useState<boolean>(false)

    const borderColor = useRef<string>('gray.200')
    const border = useRef<string>('1px')
    const bg = useRef<string>('white')
    useDownBorder({ borderColor, border, bg })

    // const { downTime: downTime2, partsMissed } = useDown()

    // const inputRef = useRef<any>(false)
    // useEffect(() => {
    //     if (isEditing) {
    //         inputRef.current.focus()
    //     }
    // }, [isEditing])

    // const { loading, cellDoc, error } = useCellDoc('cell')
    // const { cellDoc } = useCellDoc('cell')
    const { configCell } = useConfigCell('cell')

    // useEffect(() => {
    //     if (isDown) {
    //         borderColor.current = 'orange.500'
    //         border.current = '4px'
    //     } else {
    //         borderColor.current = 'gray.200'
    //         border.current = '1px'
    //     }
    // }, [isDown])

    return (
        <>
            <GridItem
                // minW={0} 
                overflow='hidden'
                display='flex'
                justifyContent='center'
                rounded='3xl'
                boxShadow='base'
                border={border.current}
                borderColor={borderColor.current}
                bg={bg.current}
            >
                <Flex
                    h='full'
                    flexDir='column'
                    align='center'
                    justifyContent='space-evenly'
                >
                    {/* <Flex flexDir='column' alignItems='center'>
                        <Heading size='3xl'>
                            {formatSeconds(downTime)}
                        </Heading>
                        <Text fontSize='xl' textColor='gray.600'>
                            down time
                        </Text>
                    </Flex> */}
                    {/* <DRListModal type='down' records={downRecords} codes={cellDoc.down_codes} /> */}
                    <DispData 
                        data={
                            downTime === -1 ?
                            formatSeconds(0)
                            :
                            formatSeconds(downTime)
                        } 
                        label={'down time'} 
                        button={
                            <DRListModal 
                                type='down' 
                                records={downRecords} 
                                codes={configCell.down_codes.map(d => d.code) || []} 
                            />
                        }
                    />
                    {/* <DispData 
                        data={formatSeconds(downTime2)} 
                        label={`down time2 - ${partsMissed}`} 
                    /> */}
                    {lastDown ? 
                        <DRInfo 
                            type='down'
                            record={lastDown} 
                            index={downRecords.length - 1} 
                            selectCodes={configCell.down_codes.map(d => d.code) || []}
                        />
                        // <DTInfo 
                        //     record={lastDown} 
                        //     index={downRecords.length - 1} 
                        //     downCodes={cellDoc.down_codes} 
                        // />
                        :
                        // <DRInfo 
                        //     type='down'
                        //     record='dummy' 
                        //     index={0} 
                        //     selectCodes={[]}
                        // />
                        <></>
                    }
                    {/* {lastDown ?
                        <VStack spacing={2} alignItems='flex-start'>
                            <Flex>
                                <Text>
                                    D{downRecords.length} -
                                </Text>
                                <Flex flex='1' ml='2'>
                                    <Select
                                        placeholder='Select code'
                                        size='xs'
                                    // onChange={e => dispatch(setDownCode({
                                    //     index: index,
                                    //     code: e.currentTarget.value
                                    // }))}
                                    >
                                        {cellDoc.down_codes.map((code, index) => {
                                            return <option value={code} key={index}>{code}</option>
                                        })}
                                    </Select>
                                </Flex>
                            </Flex>
                            <DTNote />
                            <Flex w='full' justifyContent='space-between'>
                                <Text color='red.700'>{Math.round(lastDown.numParts * 10) / 10}</Text>
                                {formatSeconds(lastDown.duration)}
                            </Flex>
                        </VStack>
                        :
                        <></>
                    } */}
                </Flex>
            </GridItem>
            {/* {isEditing ? 
                <span>
                    <input  
                        // onChange={e => dispatch(setDownNote({
                        //         index: index,
                        //         note: e.currentTarget.value
                        //     }))
                        // }
                        onBlur={() => {
                            setIsEditing(false)
                            // dispatch(setIsEditingRedux(false))
                        }}
                        onKeyDown={handleEnter}
                        className='rounded text-black text-xs pl-1' 
                        // value={record.note} 
                        value={'example note'}
                        ref={inputRef}
                    />
                </span>
                : 
                <div className='inline'>
                    {record.note ? 
                        `${record.note}` 
                        : 
                        <div className='inline uppercase text-gray-500 font-extralight'>insert note</div>
                    }
                    <button 
                        onClick={()=> {
                            setIsEditing(true)
                            // dispatch(setIsEditingRedux(true))
                        }}
                        className='text-xs bg-gray-200 px-1 mx-2 rounded'
                    >
                        edit
                    </button>
                </div>
            }
            <div className="flex justify-between">
                <div className="text-red-700 font-medium">-{Math.round(record.numParts * 10) / 10}</div>
                <div>{formatTime(record.duration)}</div>
            </div> */}
        </>
    )
}

export default Down